import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import Modal from 'react-modal';
import Common from '../Components/Common/common.js';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
import { ToastProvider } from 'react-toast-notifications';
import BookingListTab from '../Components/Backyard/BookingListTab';
import Booking from '../Components/Backyard/Booking';
import SystemTab from '../Components/Master/SystemTab';
import UserEdit from '../Components/Master/UserEdit';
import BookingConditionSlice from '../Slices/BookingCondition.js';
import BookingQrRead from '../Components/Backyard/BookingQrRead';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )
};

const BackApp = (props) => {
  const initSettings = async (siteId) => {
    const reg_params = {
      "operator": "and",
      "where": [{ "site_id": siteId }]
    }
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${siteId}/`, reg_params)).data;
    setSettings(data);
    const temp = {
      link: {
        rel: {
          icon: `/${siteId}/favicon.ico`,
          "apple-touch-icon": `/${siteId}/logo192.png`,
          manifest: `/${siteId}/manifest.json`,
        },
      },
      title: data.TITLE_NAME ? data.TITLE_NAME : '',
      description: data.TITLE_NAME ? data.TITLE_NAME : '',
    };
    setMeta(temp);
  };
  const [settings, setSettings] = useState(null);
  const [meta, setMeta] = useState({});
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);
  const conditionActions = useActions(BookingConditionSlice.actions);
  const condition = useSelector(state => state.BookingCondition);

  useEffect(() => {
    initSettings(props.match.params.siteId);
  }, [props.match.params.siteId]);

  const _userProps = { user, siteId: parseInt(props.match.params.siteId), userActions, settings, conditionActions, condition, ...props };
  return (
    <div className='backyard-top'>
      <DocumentMeta {...meta} />
      <Modal isOpen={Common.checkContractPeriod(settings) === false}>
        <div className="text-center section-white m-0 py-2">
          <h4>サービス契約期間外です</h4>
        </div>
      </Modal>
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider placement="bottom-center">
            <Route render={() =>
              <UserOnly {..._userProps} >
                <Route exact path={`${props.match.path}`} >
                  <BookingListTab {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}qrread`} >
                  <BookingQrRead {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}bookinglist`} >
                  <BookingListTab {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}booking`} >
                  <Booking {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}booking/:book_id`} >
                  <Booking {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}system`} >
                  <SystemTab {..._userProps} />
                </Route>
                <Route exact path={`${props.match.path}user/edit`} >
                  <UserEdit {..._userProps} />
                </Route>
                <Route path={`${props.match.path}user/edit/:id`} >
                  <UserEdit {..._userProps} />
                </Route>
              </UserOnly>
            } />
            <Route render={() =>
              <GuestOnly {..._userProps} >
                <Route path={`${props.match.path}login`} render={() =>
                  <Login {..._userProps} />
                } />
              </GuestOnly>
            } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

export default BackApp;