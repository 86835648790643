import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import UseConfirmModal from '../Common/UseConfirmModal';

const BackyardConfirmationPerson = (props) => {
  const { user, settings } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  const [searchData, setSearchData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});

  const refreshConfirmation = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
    }
    const confirmationPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/confirmation/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const confirmation = (await confirmationPromise).data;
    setSearchData(confirmation);
  }, [props.match.params.siteId]);

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.customer_id === null}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleConfirmationDeleteClick(data)}>
        削除
      </button>
    )
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  // 削除ボタン：処理
  const handleConfirmationDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    setConfirmModalMessage(`到着確認者[${data.customer_id}:${data.name}]を削除します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      const params = {
        site_id: props.match.params.siteId,
        customer_id: data.customer_id,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/confirmation/delete/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('削除しました。', { appearance: 'success', autoDismiss: true });
          await refreshConfirmation();
        }
        setLockData(false);
      }
    });
  };

  useEffect(() => {
    async function fetchData() {
      await refreshConfirmation();
    }
    return fetchData();
  }, [refreshConfirmation]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {searchData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {searchData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center">
                    お客様ID
                  </td>
                  <td className="text-center">
                    氏名コード
                  </td>
                  <td className="text-center">
                    氏名
                  </td>
                  <td className="text-center">
                    LineID
                  </td>
                  <td className="text-center">
                    登録日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {searchData.map((data, idx) => (
                  <tr key={idx}>
                    {/* お客様ID */}
                    <td className="text-center align-middle">
                      {data.customer_id}
                    </td>
                    {/* 氏名コード */}
                    <td className="text-center align-middle">
                      {data.name_code}
                    </td>
                    {/* 氏名 */}
                    <td className="text-center align-middle">
                      {data.name}
                    </td>
                    {/* LineID */}
                    <td className="text-center align-middle">
                      {data.line_id}
                    </td>
                    {/* 登録日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:SS') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {data.customer_id && renderDeleteButton(data)}
                      <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal} />
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardConfirmationPerson