import React from 'react';
import queryString from 'query-string';

const Common = {};

Common.getSettingValue = function (settings, setting_type, default_value = null) {
  return settings && settings[setting_type] ? settings[setting_type] : default_value;
}

// 契約期間内かどうか
Common.checkContractPeriod = function (settings) {
  const start = this.getSettingValue(settings, "CONTRACT_PERIOD_START", "00010101");
  const end = this.getSettingValue(settings, "CONTRACT_PERIOD_END", "99991231");
  const today = new Date();
  const date = String(today.getFullYear()) + (('00' + String(today.getMonth() + 1)).slice(-2)) + (('00' + String(today.getDate())).slice(-2));
  if (start <= date && date <= end) {
    return true;
  }
  return false;
}

Common.getExternalUrl = function (url_string) {
  const url = new URL(url_string);
  url.searchParams.set('openExternalBrowser', '1');
  return url.toString();
}

Common.getMonthString = function () {
  const date = new Date();
  return String(date.getFullYear()) + (('00' + String(date.getMonth() + 1)).slice(-2));
}

Common.getBrString = function (str) {
  try {
    const texts = str.split(/(\n)/).map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  } catch(e) {
    console.log(e);
    return <div>{str}</div>
  }
}

Common.getLinkedString = function (str) {
  // <#表記文字列$リンクURL#> を変換する
  const check = /<#[^$]+\$https?:\/\/[\w/:%#$&?()~.=+-]+#>/g; // <# $を含まない文字列 $ URLのパターン #>
  var matches = str.matchAll(check);
  var ret = [];
  var idx = 0; // 文字列の切り出し位置
  var key = 0; // reactエラー対応用
  for (const match of matches) {
    // 一致する前までを戻り値に書き足す
    ret.push(
      <React.Fragment key={key}>
        {str.substr(idx, match.index - idx)}
      </React.Fragment>
    );
    key += 1;

    // リンク付き文字列を書き足す
    const val = match[0].substr(2, match[0].length - 4); // <# #>を除いた文字列を取得
    const ary = val.split('$'); // $で分割 ary[0]:表記文字列 ary[1]:URL
    ret.push(
      <React.Fragment key={key}>
        <a href={this.getExternalUrl(ary[1])} target="_blank" rel="noreferrer" className="text-choiyoka">
          <u>{ary[0]}</u>
        </a>
      </React.Fragment>
    );
    key += 1;

    // 次の切り出し位置を更新
    idx = match.index + match[0].length;
  }
  // 切り出し位置から文字列の最後までを書き足す
  if (idx < str.length) {
    ret.push(
      <React.Fragment key={key}>
        {str.substr(idx, str.length - idx)}
      </React.Fragment>
    );
  }
  return ret;
}

Common.getCdFromQrString = function (str) {
  const qrstring = str.split('?');
  const qs = queryString.parse(qrstring.length > 1 ? qrstring[1] : qrstring[0]);
  if (!("cd" in qs)) {
    return null;
  } else {
    return qs.cd;
  }
}
/* 
Common.disableBounceScroll = function (pageRef) {
  let touchY = 0;

  document.body.addEventListener('touchstart', (e) => {
    touchY = e.touches[0].screenY;
  });

  document.body.addEventListener('touchmove', (e) => {
    // let el = e.target;
    let el = pageRef.current;// e.targetが機種によって変動するため、pageに固定
    const moveY = e.touches[0].screenY;
    let noScroll = true;

    while (el !== null) {
      if (el.offsetHeight < el.scrollHeight) {
        if (touchY < moveY && el.scrollTop === 0) {
          break;
        }

        if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
          break;
        }

        noScroll = false;
        break;
      }
      // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
      el = el.parentElement;
    }
    // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
    if (noScroll) {
      if (e.cancelable) {
        e.preventDefault();
      }
    }

    touchY = moveY;
  }, { passive: false });
}
Common.scrollPageTop = function (pageRef) {
  setTimeout(() => {
    pageRef.current.scrollTo(0, 0);
    // window.scrollTo(0, 0);
  }, 1);
}
Common.scrollWindowTop = function () {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 1);
} */
export default Common;