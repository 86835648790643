import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import UseConfirmModal from '../Common/UseConfirmModal';
import UseAlertModal from '../Common/UseAlertModal';  

const BackyardUserEdit = (props) => {
  const { id } = useParams();
  const { user } = props;
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const [userData, setUserData] = useState({ user_id: '', user_name: '', user_class: '', logi_id: '', password: '' });
  const [searchLogiData, setSearchLogiData] = useState(null);
  const { addToast } = useToasts();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState(''); 

  const refreshUser = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    setSearchLogiData(logi);
    if(id !== undefined){
      const getUser = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      setUserData({ ...getUser, password: '' });
    } else {
      setUserData({ user_id: '', user_name: '', user_class: '', logi_id: '', password: '' });
    }
  },[id, props.match.params.siteId])

  // 単一レコード　val値change
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "select" ? target.selected : target.value;
    const name = target.name;
    setUserData({ ...userData, [name]: value });
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  //更新ボタン・新規登録ボタン押下時
  const handleUpdateRegisterClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (!data.user_id) {
      setAlertModalMessage('ユーザーIDを入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (data.user_id.length > 20) {
      setAlertModalMessage('ユーザーIDは20字以内で入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (!data.user_name) {
      setAlertModalMessage('ユーザー名を入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (data.user_name.length > 100) {
      setAlertModalMessage('ユーザー名は100字以内で入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (id && !data.password) {
      data.password = undefined; // 既存のパスワードを保持するためにundefinedに設定
    } else {
      if (!data.password) {
        setAlertModalMessage('パスワードを入力してください。')
        setIsAlertModalOpen(true);
        return;
      }
      if (data.password.length < 8 || data.password.length > 16) {
        setAlertModalMessage('パスワードは8文字以上16字以内で入力してください。')
        setIsAlertModalOpen(true);
        return;
      }
      if (data.password.match(/\s+/g)) {
        setAlertModalMessage('パスワードに空白文字は使用できません。')
        setIsAlertModalOpen(true);
        return;
      }
      if (data.password.match(/[^a-zA-Z0-9\-/:;()\\&@".,?!'[\]{}#%^*+=_|~<>$`€£•]+/g)) {
        setAlertModalMessage('パスワードは半角英数記号で入力してください')
        setIsAlertModalOpen(true);
        return;
      }
      if (!data.password.match(/[a-zA-Z]/) || !data.password.match(/[0-9]/) || !data.password.match(/[-/:;()&@".,?!'[\]{}#%^*+=_|~<>$`€£•]/)) {
        setAlertModalMessage('パスワードは英字、数字、記号を含む必要があります。')
        setIsAlertModalOpen(true);
        return;
      }
    }
    // 確認メッセージ
    if (id) {
      setConfirmModalMessage(`ユーザマスタ[${data.user_id}:${data.user_name}]を更新します。よろしいですか？`);
      setIsConfirmModalOpen(true);

    } else {
      setConfirmModalMessage(`ユーザ名[${data.user_name}]を登録します。よろしいですか？`);
      setIsConfirmModalOpen(true);
    }
    setOnConfirm(() => async () => {
      closeConfirmModal();
      const params = {
        user_id: data.user_id,
        user_class: constClass.CLASS.ADMIN,
        user_name: data.user_name,
        password: data.password,
        site_id: props.match.params.siteId,
        logi_id: data.logi_id ? data.logi_id : null,
        upd_name: data.upd_name,
        upd_date: data.upd_date,
      };

      let res;
      try {
        setLockData(true);
        if (id) {
          res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/user/update/${data.user_id}`, params, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            }
          });
        } else {
          res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/insert`, params, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            }
          });
        }
      } catch (err) {
        console.log(err);
        if (err.response.data !== null) {
          addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        console.log(res);
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });

        } else if (id) {
          addToast('更新しました。', { appearance: 'success', autoDismiss: true });
          await refreshUser();
        } else {
          addToast('登録しました。', { appearance: 'success', autoDismiss: true });
          history.replace(`${generatePath(`${props.match.path}system`, { siteId: props.match.params.siteId })}`);
        }
        setLockData(false);
      }
    });
  }

  //削除ボタン押下時
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    setConfirmModalMessage(`ユーザマスタ[${data.user_id}:${data.user_name}]を削除します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      const params = {
        site_id: props.match.params.siteId,
        user_id: data.user_id,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        console.log("adw");
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/delete`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        console.log("oo");
        
      } catch (err) {
        console.log(err);
        if (err.response.data !== null) {
          console.log("aaaa");
          
          addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
        } else {
          console.log("dfe");
          
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        console.log(res);
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('削除しました。', { appearance: 'success', autoDismiss: true });
          history.replace(`${generatePath(`${props.match.path}user`, { siteId: props.match.params.siteId })}`);
          return;
        }
        setLockData(false);
      }
    });
  };
  
  //更新ボタン・新規登録ボタン判別
  const UpdateRegisterButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-5`}
        onClick={() => handleUpdateRegisterClick(data)}>
        {id ? "更新" : "登録" }
      </button>
    )
  }

  //削除ボタン
  const deleteButton = (data) => {
    if(id){
        return (
          <button type="button"
            disabled={lockData || data.user_id === null}
            className={`btn btn-danger mx-5`}
            onClick={() => handleDeleteClick(data)}>
            削除
          </button>
        )
      }
    }


  //戻るボタン
  const backButton = () => {
    return (
      <button type="button"
        className={`btn btn-secondary mx-5`}
        onClick={() => history.goBack()}>
        戻る
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshUser();
    }
    return fetchData();
  }, [refreshUser]);

  return (

    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {userData !== null && (

        <div className="row mb-3 col-10">

        {/* ユーザーID */}
         <label htmlFor="user_name" className="col-sm-3 col-form-label my-sm-3">ユーザID</label>
          <div className="col-sm-9 my-sm-3">
           <input type="text" className="form-control" id="user_id" name="user_id" value={userData.user_id} disabled={lockData || userData.site_id} onChange={handleChange}></input>
         </div>

          {/* ユーザー名 */}
          <label htmlFor="user_name" className="col-sm-3 col-form-label my-sm-3">ユーザ名</label>
          <div className="col-sm-9 my-sm-3">
           <input type="text" className="form-control" id="user_name" name="user_name" value={userData.user_name} onChange={handleChange}></input>
         </div>

          {/* ロジ */}
          <label htmlFor="user_id" className="col-sm-3 col-form-label my-sm-3">施設</label>
          <div className="col-sm-9 a my-sm-3 text-left">
            <select type="select" className="custom-select" name="logi_id" value={userData.logi_id || ''} onChange={handleChange}>
              <option value="">選択してください</option>
              {searchLogiData && searchLogiData.map((item) =>
                <option key={item.logi_id} value={item.logi_id}>{item.logi_name}</option>
              )}
            </select>
          </div>
          
          {/* ユーザ区分
          <label htmlFor="user_id" className="col-sm-3 col-form-label my-sm-3">ユーザ区分</label>
          <div className="col-sm-9 a my-sm-3 text-left">
            <select type = "select" className="custom-select" name="user_class" value={userData.user_class} onChange={handleChange}>
              <option value="">選択してください</option>
              <option value={constClass.CLASS.ADMIN}>{constClass.CLASS_NAME.ADMIN}</option>
              <option value={constClass.CLASS.SHOP}>{constClass.CLASS_NAME.SHOP}</option>
            </select>
          </div> */}

          {/* パスワード */}
          <label htmlFor="password" className="col-sm-3 col-form-label my-sm-3">パスワード</label>
          <div className="col-sm-9 my-sm-3">
            <input type="text" className="form-control mb-3" id="password" name="password" value={userData.password} onChange={handleChange}></input>
            <span className="text-danger">半角英数字で入力してください。<br /></span>
            <span className="text-danger">８文字以上１６文字以内で入力してください。<br /></span>
            <span className="text-danger">英字と数字と記号を組み合わせて入力してください。</span>
          </div>

          {/* 更新ボタン・新規登録ボタン　/削除ボタン */}
          <div className="col-sm-9 my-sm-3 text-right">
            {UpdateRegisterButton(userData)}
            {deleteButton(userData)}
            {backButton()}
            <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal}/>
            <UseAlertModal isOpen={isAlertModalOpen} message={alertModalMessage} onClose={closeAlertModal}/>
          </div>
        </div>

      )}
    </div>

  )
}
export default BackyardUserEdit