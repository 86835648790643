import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import util from 'util';
import moment from 'moment';
import 'moment/locale/ja';
import Common from '../Common/common';
import ScrollToTop from '../Scroll/ScrollToTop';
import ja from 'date-fns/locale/ja';

import Payment from '../Payment/Payment';
import BookingQR from './BookingQR';
import BookingDetailView from './BookingDetailView';
import DatePicker, { registerLocale } from "react-datepicker"
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";


import QrImg from '../Images/qr-code.svg';

registerLocale('ja', ja);

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // names: [''], // 初期状態では1つの入力欄を持つ
      selectSlot: null, // 選択された時間帯を保持する状態
      selectedSlot: null,
      back: false,
      timeCheck: false,
      sleeping: false,
      ready: false,
      disabled: false,
      confirmationdisabled: true,
      siteId: isFinite(this.props.siteId) ? this.props.siteId : 0,
      book_id: null,
      book_set_id: null,
      card_number: '',
      card_nothing: false,
      categoryMaster: null,
      logiList: null,
      place_visitList: null,
      cardList: null,
      bookingList: null,
      confirmationList: null,
      reservation: null,
      bookingListtoday: null,
      card_logi_id: null,
      // set_booking: {
      //   book_id: null,
      //   bookingdate: null, //日付
      //   start_time: null,
      //   end_time: null,
      //   book_date: null,
      //   logi: null,
      //   placevisit: null,
      //   total: 0,
      //   customer_id: null,
      //   customer_name: '',
      //   customer_kana: '',

      //   remarks:'',
      //   license: '',
      //   company_name: '',
      //   phone_number: '',
      //   person_charge: '',
      //   requirement: '',
      //   vehicle_number: '',


      //   customer_address: '',
      //   customer_gender: '',
      //   customer_age_type: '',
      //   privacy_policy: constClass.FLAG.OFF,
      // },
      set_booking: {
        book_id: null,
        bookingdate: null, //日付
        customer_id: null,
        start_time: null,
        end_time: null,
        logi_id: null,
        logi_name: null,
        place_visit_id: null,
        place_visit_name: null,
        names: [''],
        identity_document: [''],
        others_identity_document: [''],
        company_name: '',
        phone_number: '',
        person_charge: '',
        requirement: '',
        others_requirement: '',
        vehicle_number: '',
        remarks: '',
      },
      confirmationperson: {
        name: '',
        name_code: '',
        authentication_code: ''
      },

      // site_id: this.state.siteId,
      // book_id: this.state.booking.book_id ? this.state.booking.book_id : null,
      // customer_id: this.state.booking.customer_id,
      // names: this.state.names,
      // bookingdate: new Date(this.state.booking.bookingdate),
      // start_time: this.state.start_time,
      // end_time: this.state.end_time,
      // logi_name: this.state.logi_name,
      // place_visit_name: this.state.place_visit_name,
      // company_name: this.state.company_name,
      // phone_number: this.state.phone_number,
      // names: this.state.names,
      // identity_document: this.state.identity_document,
      // person_charge: this.state.person_charge,
      // requirement: this.state.requirement,
      // vehicle_number: this.state.vehicle_number,
      // parking_number: this.state.parking_number,
      // remarks: this.state.remarks,
      // status: constClass.STATUS.BFE, //初期ステータスは入構前
      // line_id: this.props.liff_access_token,



      set_booking_detail: [],
      set_bookCalList: null,
      set_bookCalListMessage: null,
      my_customer: {
        customer_id: null,
        customer_name: '',
        customer_kana: '',
        customer_address: '',
        customer_gender: '',
        customer_age_type: '',
      },

      bookCalList: null,
      bookCalListMessage: null,
      recept: null,
      change: false,
      user_modal_flag: false,
      user_modal_read: false,
      bookingDetailList: null,
      cancel_modal_flag: false,
      payment_data: {},
    }

    this.setReady = this.setReady.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.refreshBookData = this.refreshBookData.bind(this);
    this.submitCurrentbooking = this.submitCurrentbooking.bind(this);
    this.pageRef = React.createRef();
    this.calendarRef = React.createRef();
  }
  setReady() {
    if (this.state.logiList !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }
  async refreshData() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalMaster) {
        this.intervalMaster = setInterval(() => {
          this.refreshData();
        }, 500);
      }
    } else {
      clearInterval(this.intervalMaster);
      try {
        const params = {
          site_id: this.state.siteId,
          operator: "and",
          where: [
            { site_id: this.state.siteId },
            { line_id: this.props.liff_access_token }
          ]
        }

        const params1 = {
            site_id: this.state.siteId,
            line_id: this.props.liff_access_token 
        }
        if ((("CONTRACT_PERIOD_STARTTIME" in this.props.settings) && parseInt(this.props.settings.CONTRACT_PERIOD_STARTTIME) > parseInt(moment().format('YYYYMMDDkkmm')))) {
          this.props.history.replace(`/${this.state.siteId}/`);
        }
        const logiPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/logi/listlogi/${this.state.siteId}`);
        const customerPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search/`, params);
        const confirmationPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/confirmation/listperson/${this.state.siteId}`, params1);

        const logiList = (await logiPromise).data;
        const customer = (await customerPromise).data;
        const confirmationList = (await confirmationPromise).data

        const confirmationItem = confirmationList.find(c => c.line_id === this.props.liff_user_id);
        if (confirmationItem) {
          var confirmationperson = { ...this.state.confirmationperson, name: confirmationItem.name, name_code: confirmationItem.name_code };
          this.setState({
            confirmationperson
          });
        }
        this.setState({
          logiList,
          confirmationList,
          my_customer: customer.length > 0 ? customer.find(c => c.line_id === this.props.liff_user_id) : this.state.my_customer,
        });
        if (this.props.page !== constClass.CONFIRMATION){
          await this.refreshBookData();
        }        
        if (this.props.page === constClass.CONFIRMATION){
          await this.refreshConfirmationBookData();
        }
        //決済入力画面の場合は、submitPaymentを実行
        if (this.props.page === constClass.PAYMENT && isFinite(this.props.current_bookId)) {
          this.submitPayment(this.props.current_bookId);
        }
      } catch (error) {
        console.log(error);
        this.setState({ sleeping: true });
      }
    }
    this.setReady();
  }

  async refreshBookData() {
    const book_id = isFinite(this.props.current_bookId) ? this.props.current_bookId : null;
    const params = {
      site_id: this.state.siteId,
      customer_id: this.state.my_customer.customer_id,
    }
    const bookingPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/search/`, params);
    const bookingList = (await bookingPromise).data;
    var my_customer = this.state.my_customer;
    var booking = null;
    var callist = this.state.bookCalList;
    // var recept = await this.getReceptdata(bookingDetailList);
    if (book_id) {
      var detail = await bookingList.filter(x => (x.book_id === book_id));
      if (detail.length > 0) {
        const params1 = {
          site_id: this.state.siteId,
          book_id: book_id
        }
        const namesPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/names/search/`, params1);
        const namesList = (await namesPromise).data;
        var names = [];
        var identity_document = [];
        var others_identity_document = [];
        await Promise.all(namesList.map(async (item, idx) => {
          names.push([item.name, item.name_id]);
          if (item.identity_document !== '社員証' && constClass.NAME_LICENCE[item.identity_document]) {
            identity_document.push(constClass.NAME_LICENCE[item.identity_document]);
            others_identity_document.push('');
          }
          else {
            identity_document.push('4')
            const match = item.identity_document.match(/その他\((.*?)\)/);
            const staff_card = item.identity_document.match(/社員証/);
            if (match) {
              others_identity_document.push(match[1]);
            } else if (staff_card) {
              others_identity_document.push(staff_card[0]);
            }
          }
        }));
        booking = {
          bookingdate: detail[0].bookingdate,
          start_time: detail[0].start_time,
          end_time: detail[0].end_time,
          logi_id: detail[0].logi_id,
          logi_name: detail[0].logi_name,
          place_visit_id: detail[0].place_visit_id,
          place_visit_name: detail[0].place_visit_name,
          company_name: detail[0].company_name,
          phone_number: detail[0].phone_number,
          person_charge: detail[0].person_charge,
          requirement: constClass.NAME_REQUIREMENT[detail[0].requirement] ? constClass.NAME_REQUIREMENT[detail[0].requirement] : '4',
          others_requirement: constClass.NAME_REQUIREMENT[detail[0].requirement] ? '' : detail[0].requirement.match(/その他\((.*?)\)/)[1],
          vehicle_number: detail[0].vehicle_number,
          remarks: detail[0].remarks,
          status: detail[0].status,
          names: names,
          identity_document: identity_document,
          others_identity_document: others_identity_document
        };
      }
      else {
        booking = this.blankBooking();
      }
    }
    else {
      booking = this.blankBooking();
    }
    this.setState({
      book_id: book_id,
      set_booking: { ...booking },
      set_bookCalList: callist,
      my_customer,
      booking: booking,
      bookCalList: callist,
      //recept: recept,
      bookingList: bookingList,
      change: false,
    });
  }

  async refreshConfirmationBookData() {
    const book_id = isFinite(this.props.current_bookId) ? this.props.current_bookId : null;
    const params = {
      site_id: this.state.siteId
    }
    const bookingPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/confirmationsearch/`, params);
    const bookingList = (await bookingPromise).data;
    var my_customer = this.state.my_customer;
    var booking = null;
    var callist = this.state.bookCalList;
    // var recept = await this.getReceptdata(bookingDetailList);
    if (book_id) {
      var detail = await bookingList.filter(x => (x.book_id === book_id));
      if (detail.length > 0) {
        const params1 = {
          site_id: this.state.siteId,
          book_id: book_id
        }
        const namesPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/names/search/`, params1);
        const namesList = (await namesPromise).data;
        var names = [];
        var identity_document = [];
        var others_identity_document = [];
        await Promise.all(namesList.map(async (item, idx) => {
          names.push([item.name, item.name_id]);
          if (item.identity_document !== '社員証' && constClass.NAME_LICENCE[item.identity_document]) {
            identity_document.push(constClass.NAME_LICENCE[item.identity_document]);
            others_identity_document.push('');
          }
          else {
            identity_document.push('4')
            const match = item.identity_document.match(/その他\((.*?)\)/);
            const staff_card = item.identity_document.match(/社員証/);
            if (match) {
              others_identity_document.push(match[1]);
            } else if (staff_card) {
              others_identity_document.push(staff_card[0]);
            }
          }
        }));
        booking = {
          bookingdate: detail[0].bookingdate,
          start_time: detail[0].start_time,
          end_time: detail[0].end_time,
          logi_id: detail[0].logi_id,
          logi_name: detail[0].logi_name,
          place_visit_id: detail[0].place_visit_id,
          place_visit_name: detail[0].place_visit_name,
          company_name: detail[0].company_name,
          phone_number: detail[0].phone_number,
          person_charge: detail[0].person_charge,
          requirement: constClass.NAME_REQUIREMENT[detail[0].requirement] ? constClass.NAME_REQUIREMENT[detail[0].requirement] : '4',
          others_requirement: constClass.NAME_REQUIREMENT[detail[0].requirement] ? '' : detail[0].requirement.match(/その他\((.*?)\)/)[1],
          vehicle_number: detail[0].vehicle_number,
          remarks: detail[0].remarks,
          status: detail[0].status,
          names: names,
          identity_document: identity_document,
          others_identity_document: others_identity_document
        };
      }
      else {
        booking = this.blankBooking();
      }
    }
    else {
      booking = this.blankBooking();
    }
    this.setState({
      book_id: book_id,
      set_booking: { ...booking },
      set_bookCalList: callist,
      my_customer,
      booking: booking,
      bookCalList: callist,
      //recept: recept,
      bookingList: bookingList,
      change: false,
    });
  }

  blankBooking() {
    return {
      book_id: null,
      bookingdate: null,
      customer_id: this.state.my_customer.customer_id ? this.state.my_customer.customer_id : '',
      start_time: null,
      end_time: null,
      logi_id: null,
      logi_name: null,
      place_visit_id: null,
      place_visit_name: null,
      names: [''],
      identity_document: [''],
      others_identity_document: [''],
      company_name: '',
      phone_number: '',
      person_charge: '',
      requirement: '',
      others_requirement: '',
      vehicle_number: '',
      remarks: '',
      // status: null,
    }
  }

  //変更対象予約情報をセット
  async submitCurrentbooking(book_id, page) {
    await this.props.setCurrentBook(book_id);
    const url = `/${this.state.siteId}/?${page ? `page=${page}` : ""}${book_id ? `&id=${book_id}` : ""}`;
    this.props.history.push(url);
    await this.refreshBookData();
    if (page === constClass.CONFIRMATION) {
      await this.refreshConfirmationBookData();
    }
  }

  componentDidMount() {
    this.refreshData();
    this.disableBounceScroll();
  }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = this.pageRef.current;// e.targetが機種によって変動するため、pageに固定
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }
          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }
          noScroll = false;
          break;
        }
        el = el.parentElement;
      }
      if (noScroll) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }

      touchY = moveY;
    }, { passive: false });
  }

  scrollPageTop() {
    setTimeout(() => {
      let el = this.pageRef.current;// e.targetが機種によって変動するため、pageに固定
      if (el) el.scrollTo(0, 0);
      else window.scrollTo(0, 0);
    }, 1);
  }

  scrollWindowTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }

  changeCustomer(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    var booking = { ...this.state.booking, [name]: value };
    if (name === "customer_kana") booking = { ...booking, customer_name: value };
    this.setState({
      booking
    });
  }

  changeConfirmation(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    if (name === 'name' || name === 'name_code') {
      this.setState({
        confirmationdisabled: false
      });
    }
    var confirmationperson = { ...this.state.confirmationperson, [name]: value };
    this.setState({
      confirmationperson, confirmationdisabled: false
    });
  }

  changeCardNumber(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    })
  }

  handleChange = (index, event, name_id) => {
    const names = [...this.state.booking.names];
    if (name_id) {
      names[index] = ([event.target.value, name_id]);
    }
    else {
      names[index] = event.target.value;
    }
    const booking = { ...this.state.booking, names: names };
    this.setState({
      booking
    });
  };

  handleChange1 = (index, event) => {
    const identity_document = [...this.state.booking.identity_document];
    identity_document[index] = event.target.value;
    const booking = { ...this.state.booking, identity_document: identity_document };
    this.setState({
      booking
    });
  };

  handleChange2 = (index, event) => {
    const others_identity_document = [...this.state.booking.others_identity_document];
    others_identity_document[index] = event.target.value;
    const booking = { ...this.state.booking, others_identity_document: others_identity_document };
    this.setState({
      booking
    });
  };
  
  addNameInput = () => {
    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        names: [...prevState.booking.names, ''],
        identity_document: [...prevState.booking.identity_document, ''],
        others_identity_document: [...prevState.booking.others_identity_document, '']
      }
    }));
  };
  
  removeNameInput = index => {
    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        names: prevState.booking.names.filter((_, i) => i !== index),
        identity_document: prevState.booking.identity_document.filter((_, i) => i !== index),
        others_identity_document: prevState.booking.others_identity_document.filter((_, i) => i !== index)
      }
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.calendarRef.current) {
      const calendarApi = this.calendarRef.current.getApi();
      if (this.state.back === true && this.state.selectedSlot) {
        this.setState({ back: false });
        calendarApi.select(this.state.selectedSlot);
      }
      else if (this.state.back === true && !this.state.selectedSlot) {
        calendarApi.select({
          "start": `${this.state.booking.bookingdate}T${this.state.booking.start_time}:00+09:00`,
          "end": `${this.state.booking.bookingdate}T${this.state.booking.end_time}:00+09:00`,
          "startStr": `${this.state.booking.bookingdate}T${this.state.booking.start_time}:00+09:00`,
          "endStr": `${this.state.booking.bookingdate}T${this.state.booking.end_time}:00+09:00`,
          "allDay": false,
          "jsEvent": {
            "isTrusted": true
          }
        });
        this.setState({ back: false });
      }
    }
  }

  //値を入れる
  async setChangeBinDetail(book) {
    // var callist = this.state.bookCalList;
    // var calmsg = this.state.bookCalListMessage;
    // var total = 0;
    // await Promise.all(book_detail.map(async (item, idx) => {
    //   if (this.state.category_id) {
    //     // 指定した日付・カテゴリのカレンダー(在庫数)を取得する
    //     var calendar = await this.getCalendar(book, book_detail[idx], book_detail);
    //     //予約可件数を計算
    //     calendar.forEach((item2, idx) => {
    //       var count = 0;
    //       if (this.state.booked && this.state.booked.booking_detail && this.state.booking.book_date === this.state.booked.book_date 
    //         && this.state.booked.booking_detail.length >= 0) {
    //           const booked_detail = this.state.booked.booking_detail[0];
    //         //予約可件数に自身の予約済件数を加算
    //         count = (item2.start_time === booked_detail.start_time && item2.end_time === booked_detail.end_time) ? booked_detail.count : 0;
    //       }
    //       calendar[idx] = { ...item2, bookok_count: item2.bookok_count + count }
    //     });

    //     // 指定した開始時間～終了時間のカレンダー(在庫)が無い
    //     var errflag = false;
    //     if (book_detail[idx].start_time && book_detail[idx].end_time) {
    //       var cal_idx = calendar.findIndex(x => x.start_time === book_detail[idx].start_time && x.end_time === book_detail[idx].end_time);
    //       if (cal_idx >= 0 && calendar[cal_idx].bookok_count < book_detail[idx].count) {
    //         errflag = true;
    //       }
    //     }
    //     const errmsg = errflag ? "予約できない時間帯が選択されています。再選択してください。" : null;

    //     book_detail[idx] = {
    //       ...book_detail[idx],
    //       total: book_detail[idx].category_id ? CommonBooking.calcTotal(book_detail[idx].price, book_detail[idx].count) : 0,
    //       errcheck: errmsg,
    //     };
    //     total += book_detail[idx].total;
    //     callist = calendar;
    //     calmsg = calendar.length <= 0 ? "予約できる時間帯がありません" : null;
    //   }
    // }));
    this.setState({
      booking: { ...book },
      // booking_detail: book_detail,
      // bookCalList: callist,
      // bookCalListMessage: calmsg,
    });
  }

  /* ロジ変更 */
  async changeLogi(val) {
    if (val) {
      //最初に持ってきてfilterかけたほうがいい？
      const place_visitPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listplaces_visit/${this.state.siteId}/${val}`);
      const place_visitList = (await place_visitPromise).data;
      this.setState({
        place_visitList
      });
      const book = { ...this.state.booking, logi_name: this.state.logiList.find(x => x.logi_id === Number(val)).logi_name, logi_id: val, place_visit_name: null };
      await this.setChangeBinDetail(book);
    }
  }

  /* ロジ変更 */
  async changeCardLogi(val) {
    this.setState({
      card_logi_id: val
    });
  }

  /* 開始時間変更 */
  async changeStart(val) {
    if(this.state.booking.end_time){
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.select({
        "start": `${this.state.booking.bookingdate}T${val}:00+09:00`,
        "end": `${this.state.booking.bookingdate}T${this.state.booking.end_time}:00+09:00`,
        "startStr": `${this.state.booking.bookingdate}T${val}:00+09:00`,
        "endStr": `${this.state.booking.bookingdate}T${this.state.booking.end_time}:00+09:00`,
        "allDay": false,
        "jsEvent": {
          "isTrusted": true
        }
      });
    }
    if(!val){
      val = null;
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.unselect();
    }
    const book = { ...this.state.booking, start_time: val };
    await this.setChangeBinDetail(book);
  }

  /* 終了時間変更 */
  async changeEnd(val) {
    if(this.state.booking.start_time){
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.select({
        "start": `${this.state.booking.bookingdate}T${this.state.booking.start_time}:00+09:00`,
        "end": `${this.state.booking.bookingdate}T${val}:00+09:00`,
        "startStr": `${this.state.booking.bookingdate}T${this.state.booking.start_time}:00+09:00`,
        "endStr": `${this.state.booking.bookingdate}T${val}:00+09:00`,
        "allDay": false,
        "jsEvent": {
          "isTrusted": true
        },
      });
    }
    if(!val){
      val = null;
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.unselect();
    }
    const book = { ...this.state.booking, end_time: val };
    await this.setChangeBinDetail(book);
  }

  handleSelectClick = async (clickInfo) => {
    const currentDate = new Date();
    if (clickInfo.start < currentDate) {
      alert(`現在時刻以降を選択してください`);
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.unselect(); // 選択をキャンセル
      return;
    }
    this.setState({ selectSlot: clickInfo });
    const startTime = moment(clickInfo.startStr).format('HH:mm');
    const endTime = moment(clickInfo.endStr).format('HH:mm');
    const book = { ...this.state.booking, start_time: startTime, end_time: endTime };
    await this.setChangeBinDetail(book);
  }

  handleUnselectClick = async (clickInfo) => {
    const book = { ...this.state.booking, start_time: null, end_time: null };
    await this.setChangeBinDetail(book);
  }

  changeDate = async (value) => {
    var bookingListtoday = await this.state.bookingList.filter(x => (x.bookingdate === value && x.status !== constClass.STATUS.CCL));
    if (this.calendarRef.current) {
      var calendar = this.calendarRef.current.getApi();
      calendar.gotoDate(value)
    }
    this.setState({
      booking: {
        ...this.state.booking,
        bookingdate: value,
        start_time: null,
        end_time: null,
      },
      bookingListtoday: bookingListtoday 
    });
  }

  /* 訪問先変更 */
  async changePlaceVisit(val) {
    const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${this.state.siteId}/${this.state.booking.logi_id}/${Number(val)}`);
    const reservation = (await reservationPromise).data;
    this.setState({
      reservation
    });
    const book = { ...this.state.booking, place_visit_name: this.state.place_visitList.find(x => x.place_visit_id === Number(val)).place_visit_name, place_visit_id: val };
    await this.setChangeBinDetail(book);
  }

  // カテゴリ変更
  async changeCategory(val, text) {
    var booking_detail = this.state.booking_detail;
    const idx = 0;
    booking_detail[idx] = {
      ...booking_detail[idx],
      category_id: null,
      book_date: null,
      start_time: null,
      end_time: null,
      price_before_tax: null,
      price: null,
      tax_rate: null,
      count: 1,
      errcheck: null,
      total: 0,

      getoff_time: null,
      fare: null,
    };

    this.setState({
      category_id: Number(val),
      category_name: text,
      booking_detail: booking_detail,
      bookCalList: null,
      bookCalListMessage: null,
    });
  }

  async searchBin() {
    var booking_detail = this.state.booking_detail;
    const idx = 0;
    booking_detail[idx] = {
      ...booking_detail[idx],
      category_id: null,
      book_date: null,
      start_time: null,
      end_time: null,
      price_before_tax: null,
      price: null,
      tax_rate: null,
      count: 1,
      errcheck: null,
      total: 0,
    };
    if (this.state.category_id) {
      await this.setChangeBinDetail(this.state.booking);
    } else {
    }
  }

  // 時間帯変更
  changeBookSetId(val) {
    const value = val;
    var detail = this.state.booking_detail;
    const idx = 0;
    if (value) {
      if (value.bookok_count < detail[idx].count) {
        return
      }
    }

    detail[idx] = {
      ...detail[idx],
      category_id: value && value.category_id ? value.category_id : null,
      category_name: this.state.category_name,
      book_date: value && value.book_date ? value.book_date : null,
      book_set_id: value && value.book_set_id ? value.book_set_id : null,
      start_time: value && value.start_time ? value.start_time : '',
      end_time: value && value.end_time ? value.end_time : null,
      price_before_tax: value && value.price_before_tax ? value.price_before_tax : null,
      price: value && value.price ? value.price : null,
      tax_rate: value && value.tax_rate ? value.tax_rate : null,
      count: value && value.count ? value.count : 1,
      remarks: value && value.remarks ? value.remarks : null,
      errcheck: null,
    };
    this.setChangeBinDetail(this.state.booking);
  }

  async changeClick(state, page) {
    if (page === constClass.INPUT) {
      const place_visitPromise = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listplaces_visit/${this.state.siteId}/${this.state.booking.logi_id}`);
      const place_visitList = place_visitPromise.data;
      this.setState({
        place_visitList,
      });
    }
    if (page === constClass.INPUTBIN1) {
      const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${this.state.siteId}/${this.state.booking.logi_id}/${this.state.booking.place_visit_id}`);
      const reservation = (await reservationPromise).data;
      const params = {
        site_id: this.state.siteId,
        customer_id: this.state.my_customer.customer_id
      }
      const bookingPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/search/`, params);
      const bookingList = (await bookingPromise).data;
      var bookingListtoday = null;
      if (isFinite(this.props.current_bookId)) {
        bookingListtoday = await bookingList.filter(x => (x.bookingdate === this.state.booking.bookingdate && x.book_id !== this.props.current_bookId && x.status !== constClass.STATUS.CCL));
      }
      else {
        bookingListtoday = await bookingList.filter(x => (x.bookingdate === this.state.booking.bookingdate && x.status !== constClass.STATUS.CCL));
      }
      if (this.calendarRef.current) {
        var calendar = this.calendarRef.current.getApi();
        calendar.gotoDate(this.state.booking.bookingdate)
      }
      this.setState({
        reservation: reservation,
        bookingListtoday: bookingListtoday
      });
    }
    this.setState(state);
    this.props.history.push(`/${this.state.siteId}/?page=${page}`);
  }

  async changeAgainBookClick(state, page, book_id) {
    this.props.history.push(`/${this.state.siteId}/?page=${page}`);
    var my_customer = this.state.my_customer;
    var booking = null;
    var place_visitList = null;
    var reservation = null;
    if (book_id) {
      var detail = await this.state.bookingList.filter(x => (x.book_id === book_id));
      const params1 = {
        site_id: this.state.siteId,
        book_id: book_id
      }
      const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${this.state.siteId}/${detail[0].logi_id}/${detail[0].place_visit_id}`);
      reservation = (await reservationPromise).data;
      const place_visitPromise = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listplaces_visit/${this.state.siteId}/${detail[0].logi_id}`);
      place_visitList = place_visitPromise.data;
      const namesPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/names/search/`, params1);
      const namesList = (await namesPromise).data;
      var names = [];
      var identity_document = [];
      var others_identity_document = [];
      await Promise.all(namesList.map(async (item, idx) => {
        names.push(item.name);
        if(item.identity_document !== '社員証' && constClass.NAME_LICENCE[item.identity_document]){
          identity_document.push(constClass.NAME_LICENCE[item.identity_document]);
          others_identity_document.push('');
        }
        else {
          identity_document.push('4')
          const match = item.identity_document.match(/その他\((.*?)\)/);
          const staff_card = item.identity_document.match(/社員証/);
          if (match) {
            others_identity_document.push(match[1]);
          } else if (staff_card) {
            others_identity_document.push(staff_card[0]);
          }
        }
      }));
      booking = {
        bookingdate: null,
        start_time: null,
        end_time: null,
        logi_id: detail[0].logi_id,
        logi_name: detail[0].logi_name,
        place_visit_id: detail[0].place_visit_id,
        place_visit_name: detail[0].place_visit_name,
        company_name: detail[0].company_name,
        phone_number: detail[0].phone_number,
        person_charge: detail[0].person_charge,
        requirement: constClass.NAME_REQUIREMENT[detail[0].requirement] ? constClass.NAME_REQUIREMENT[detail[0].requirement]: '4',
        others_requirement: constClass.NAME_REQUIREMENT[detail[0].requirement] ? '' : detail[0].requirement.match(/その他\((.*?)\)/)[1],
        vehicle_number: detail[0].vehicle_number,
        remarks: detail[0].remarks,
        status: null,
        names: names,
        identity_document: identity_document,
        others_identity_document: others_identity_document
      };
      //booked = { ...booking, booking_detail: booking_detail.concat() };

    }
    this.setState({
      book_id: null,
      set_booking: { ...booking },
      my_customer,
      booking: booking,
      change: false,
      place_visitList,
      reservation
    });
    if (page === constClass.INPUT) {
      // const place_visitPromise = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listplaces_visit/${this.state.siteId}/${this.state.booking.logi_id}`);
      // const place_visitList = place_visitPromise.data;
      // this.setState({
      //   place_visitList,
      // });
    }
    if (page === constClass.INPUTBIN1) {
      // const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${this.state.siteId}/${this.state.booking.logi_id}`);
      // const reservation = (await reservationPromise).data;
      // var reservationtoday = await reservation.filter(x => (x.bookingdate === this.state.booking.bookingdate));
      // const params = {
      //   site_id: this.state.siteId,
      //   customer_id: this.state.my_customer.customer_id
      // }
      // const bookingPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/search/`, params);
      // const bookingList = (await bookingPromise).data;
      // var bookingListtoday = null;
      // if (isFinite(this.props.current_bookId)) {
      //   bookingListtoday = await bookingList.filter(x => (x.bookingdate === this.state.booking.bookingdate && x.book_id !== this.props.current_bookId));
      // }
      // else {
      //   bookingListtoday = await bookingList.filter(x => (x.bookingdate === this.state.booking.bookingdate));
      // }
      // if (this.calendarRef.current) {
      //   var calendar = this.calendarRef.current.getApi();
      //   calendar.gotoDate(this.state.booking.bookingdate)
      // }
      // this.setState({
      //   reservationtoday: reservationtoday,
      //   bookingListtoday: bookingListtoday
      // });
    }
    this.setState(state);
  }

  async checkserach() {
    const params1 = {
        site_id: this.state.siteId,
        line_id: this.props.liff_access_token 
    }
    const cardPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/listcard/${this.state.siteId}`, params1);
    const cardList = (await cardPromise).data;
    this.setState({ cardList });
    const card = cardList.find(data => Number(data.logi_id) === Number(this.state.card_logi_id) && data.t_booking_names.find(x => x.card_number === this.state.card_number));
    if (card) {
      this.submitCurrentbooking(card.book_id, constClass.CONFIRMATION);
    }
    else {
      this.setState({ card_nothing: true })
    }
  }

  async confirmationclick() {
    try {
      var result = null;
      var data = {
        site_id: this.state.siteId,
        customer_id: this.state.my_customer.customer_id,
        line_id: this.props.liff_access_token,
        name_code: this.state.confirmationperson.name_code,
        name: this.state.confirmationperson.name,
        authentication_code: this.state.confirmationperson.authentication_code
      }
      this.setState({ disabled: true });
      const confirmationItem = this.state.confirmationList.find(c => c.line_id === this.props.liff_user_id);
      if (confirmationItem) {
        result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/confirmation/update`, data));
      }
      else {
        result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/confirmation/`, data));
      }
      if (!result.data.correct) {
        alert('認証コードが間違っています。');
        this.setState({ disabled: false });
        return;
      }
      this.setState({ disabled: false, confirmationdisabled: true });
      if (result.data && result.data.result) {
        alert('登録しました。')
      } else {
        //err
        console.log(util.inspect(result))
        alert('登録に失敗しました。1');
      }
    } catch (e) {
      var errMsg = "";
      //例外エラーがおきたら、コンソールにログを出力する
      console.error("エラー：", errMsg);
      alert('登録に失敗しました。2');
    }
  }

  checkInvalid() {
    const c = this.state.booking;
    var inputCheck = false;
    switch (this.props.page) {
      case constClass.USER://来訪者情報入力画面
        inputCheck = (!c.company_name || !c.phone_number || c.names.some(item => item === '') || c.identity_document.some(item => item === "") || !c.person_charge || !c.requirement || (c.requirement === '4' && !c.others_requirement))
        for (var i = 0; i < c.names.length; i++) {
          if (c.identity_document[i] === "4" && !c.others_identity_document[i]) {
            inputCheck = true;
          }
        }
        break;
      case constClass.INPUTALERT:
        inputCheck = (c.privacy_policy !== constClass.FLAG.ON)
        break;
      case constClass.INPUT:
        //ロジ・訪問先選択画面
        inputCheck = !c.logi_name || !c.place_visit_name
        break;
      case constClass.INPUTBIN1:
        //日時選択画面　※時間帯はまだ選択してない
        var startTimeArray = ""
        var endTimeArray = ""
        var startHour = ""
        var startMinute = ""
        var endHour = ""
        var endMinute = ""
        // 時間を数値に変換して比較
        if (c.start_time && c.end_time) {
          startTimeArray = c.start_time.split(":");
          endTimeArray = c.end_time.split(":");

          startHour = parseInt(startTimeArray[0], 10);
          startMinute = parseInt(startTimeArray[1], 10);

          endHour = parseInt(endTimeArray[0], 10);
          if (endHour === 0) {
            endHour = 24;
          }
          endMinute = parseInt(endTimeArray[1], 10);
        }
        inputCheck = !c.bookingdate || !c.start_time || !c.end_time || c.start_time === c.end_time || startHour > endHour || (startHour === endHour && startMinute > endMinute)
        break;
      default:
        break;
    }
    return inputCheck;
  }

  // 予約便の確認 true:有効
  checkBookingValid(booking, booking_detail, bookCalList, settings = null) {
    var errchk = [];
    booking_detail.forEach(detail => {
      if (detail.category_id && detail.start_time) {
        const bookcal = bookCalList.find(x => x.category_id === detail.category_id && x.start_time === detail.start_time);
        const count = bookcal ? bookcal.bookok_count : 0;
        if (detail.count > count) errchk.push(false);
      }
    });
    if (errchk.length > 0) return false;
    return true;
  }

  //時間帯が重なる予約件数制限
  checkBookingDateCount() {
    var res = 0;
    const book_date = this.state.booking.book_date;
    const list = this.state.bookingDetailList.filter(x => !(constClass.STATUS_CCL.includes(x.booking_status)) && (!this.state.booking.book_id || x.book_id !== this.state.booking.book_id) && x.book_date === book_date);
    this.state.booking_detail.forEach(detail => {
      if (list.filter(x => x.start_time < detail.end_time && x.end_time > detail.start_time).length >= parseInt(this.props.settings.MAX_SAME_TIME)) res = 1;
    });
    return res
  }

  resetBooking() {
    const book = this.blankBooking();
    this.setState({
      booking: book,
      bookCalList: null,
      bookCalListMessage: null,
      set_booking: { ...book },
      set_bookCalList: null,
      change: false,
    })
  }
  async submit() {
    // API実行
    try {
      var data = {
        site_id: this.state.siteId,
        book_id: this.state.book_id ? this.state.book_id : null,
        customer_id: this.state.my_customer.customer_id,
        bookingdate: this.state.booking.bookingdate, //予約日
        start_time: this.state.booking.start_time,
        end_time: this.state.booking.end_time,
        logi_name: this.state.booking.logi_name,
        place_visit_name: this.state.booking.place_visit_name, //訪問先
        company_name: this.state.booking.company_name,
        phone_number: this.state.booking.phone_number,
        names: this.state.booking.names, //配列でくる
        identity_document: this.state.booking.identity_document, //本人確認書類ここも配列であるべき
        others_identity_document: this.state.booking.others_identity_document,
        person_charge: this.state.booking.person_charge, //訪問先担当者
        requirement: this.state.booking.requirement, //用件
        others_requirement: this.state.booking.others_requirement,
        vehicle_number: this.state.booking.vehicle_number, //車両番号
        remarks: this.state.booking.remarks, //備考
        status: constClass.STATUS.BFE, //初期ステータスは入構前
        line_id: this.props.liff_access_token,
        place_visit_id: this.state.booking.place_visit_id,
        logi_id: this.state.booking.logi_id
      }

      this.setState({ disabled: true });
      var result = null;
      if (this.state.book_id) {
        result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/booking/${data.book_id}`, data));
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/noticeinterval/correctionnoticesend`, data);
      } else {
        result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/`, data));
        data.book_id = result.data.book.book_id;
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/noticeinterval/noticesend`, data);
      }
      this.setState({ disabled: false });

      if (result.data && result.data.result) {
        if (this.state.book_id) {
          this.submitCurrentbooking(null, constClass.CORRECTION);
          await this.refreshBookData();
        } else {
          this.submitCurrentbooking(null, constClass.FINISH);
          await this.refreshData();
        }
      } else {
        //err
        console.log(util.inspect(result))
        alert('登録に失敗しました。1');
      }
    } catch (e) {
      var errMsg = "";
      if (e.fileName && e.lineNumber) {
        // ファイル名と行番号が取得できたらメッセージとしてログに出力する
        errMsg = "ファイル名：" + e.fileName + "、 行番号：" + e.lineNumber;
      } else {
        errMsg = e.message;
      }
      //例外エラーがおきたら、コンソールにログを出力する
      console.error("エラー：", errMsg);
      alert('登録に失敗しました。2');
    }
    return false;
  }

  async submitCancel(book_id) {
    // API実行
    try {
      if (!window.confirm('キャンセルしますか？')) {
        return;
      }
      this.setState({ disabled: true });
      var result = null;
      const params = {
        site_id: this.props.siteId,
        book_id: book_id,
        status: constClass.STATUS.CCL
      }
      var data = {
        site_id: this.state.siteId,
        book_id: book_id,
        customer_id: this.state.my_customer.customer_id,
        bookingdate: this.state.booking.bookingdate, //予約日
        start_time: this.state.booking.start_time,
        end_time: this.state.booking.end_time,
        logi_name: this.state.booking.logi_name,
        place_visit_name: this.state.booking.place_visit_name, //訪問先
        company_name: this.state.booking.company_name,
        phone_number: this.state.booking.phone_number,
        names: this.state.booking.names, //配列でくる
        identity_document: this.state.booking.identity_document, //本人確認書類ここも配列であるべき
        others_identity_document: this.state.booking.others_identity_document,
        person_charge: this.state.booking.person_charge, //訪問先担当者
        requirement: this.state.booking.requirement, //用件
        others_requirement: this.state.booking.others_requirement,
        vehicle_number: this.state.booking.vehicle_number, //車両番号
        remarks: this.state.booking.remarks, //備考
        status: constClass.STATUS.CCL, //初期ステータスは入構前
        line_id: this.props.liff_access_token,
        place_visit_id: this.state.booking.place_visit_id,
        logi_id: this.state.booking.logi_id
      } 
      result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/booking/status/${book_id}`, params));
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/noticeinterval/cancelnoticesend`, data);
      this.setState({ disabled: false });

      if (result.data) {
        this.props.setCurrentBook(null);
        this.submitCurrentbooking(null, constClass.CANCEL);
        await this.refreshBookData();
      } else {
        //err
        console.log("'キャンセル失敗", util.inspect(result))
        alert('キャンセルに失敗しました。');
      }
    } catch (error) {
      console.log(util.inspect(error))
      alert('キャンセルに失敗しました。');
    }
    this.setState({ disabled: false });
  }

  renderHeader() {
    const titlename = () => {
      switch (this.props.page) {
        case constClass.USER: return `来訪者情報`;
        case constClass.INPUTALERT: return `注意事項`;
        case constClass.INPUT: return `施設・訪問先選択`;
        case constClass.INPUTBIN1: return `日時選択`;
        case constClass.CONFIRM: return `予約内容確認`;
        case constClass.PAYMENT: return `決済`;
        case constClass.FINISH: return `予約完了`;
        case constClass.CORRECTION: return `予約修正完了`;
        case constClass.CANCEL: return `予約キャンセル完了`;
        case constClass.CHANGECONFIRM: return `予約内容確認`;
        case constClass.CONFIRMATION: return `到着確認`;
        case constClass.HISTORY: return `ご予約履歴・変更`;
        case constClass.RECEPT: return `受付`;
        case constClass.RECEPTQR: return `予約QR表示`;
        case constClass.CARDSEARCH: return `カードNo.検索`;
        case constClass.CONFIRMATIONPERSON: return `到着確認者登録`;
        default: return `メニュー`;
      }
    }
    return (
      <header className="header">
        <div className="row mx-0">
          <div className={`col section text-center`}>
            {titlename(this)}
          </div>
        </div>
      </header>
    );
  }

  async getReceptdata(bookingDetailList, category_id = null) {
    const nowDate = moment().format('YYYYMMDD');
    const nowTime = moment().format('HHmmss');
    var recept = null;
    var list = [];
    await Promise.all(bookingDetailList.map(async item => {
      if (moment(item.book_date).format('YYYYMMDD') === nowDate && nowTime <= item.end_time && (!recept || recept.start_time > item.start_time) &&
          constClass.STATUS.FIN === item.booking_status) {
            recept = item;
      } else {
        if (constClass.STATUS_RECEPTOK.includes(item.booking_status) && moment(item.book_date).format('YYYYMMDD') === nowDate && nowTime <= item.end_time) {
          list.push(item);
        }
      }
    }));

    if (recept !== null) return recept;
    if (list.length > 0) {
      if (category_id && list.findIndex(x => x.category_id === category_id) >= 0) {
        return list.find(x => x.category_id === category_id);
      }
      return list[0];
    } else {
      return null;
    }
  }

  renderReceptButton() {
    return (
      <div className="row mx-0 mt-3 mb-3 text-center">
        <div className="col px-0">
          <button
            onClick={async (e) => this.submitCurrentbooking(null, constClass.RECEPTQR)}
            className={`btn btn-primary w-100 h-100 py-3 px-2`}>
            <div className='row m-0 p-0'>
              <div className='col-2 m-0 p-0 pl-2 text-left align-self-center'><img src={QrImg} style={{ width: "40px" }} alt={"受付"} /></div>
              <div className='col-8 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">予約QR表示</span></div>
            </div>
          </button>

        </div>
      </div>
    )
  }

  renderMenu() {
    const confirmationItem = this.state.confirmationList.find(c => c.line_id === this.props.liff_user_id);

    if (confirmationItem) {
      return (
        <div className="row mx-0">
          <div className="col px-0">
            {(("CONTRACT_PERIOD_STARTTIME" in this.props.settings) && parseInt(this.props.settings.CONTRACT_PERIOD_STARTTIME) > parseInt(moment().format('YYYYMMDDkkmm')))
              ?
              <div className="mx-3 px-3-env bg-green text-white h5 text-center">{this.props.settings.CONTRACT_PERIOD_BEFORE_MESSAGE}</div>
              :
              <div>
                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className="col py-1 px-1 align-self-center">
                    <div className="row mx-0 mt-3 mb-3 text-center">
                      <div className="col px-0">
                        <Link to={`/${this.state.siteId}/?page=${constClass.INPUT}`}>
                          <button
                            onClick={e => this.resetBooking()}
                            className={`btn btn-primary btn-booking w-100 h-100 py-3 px-2`}>
                            <div className='row m-0 p-0'>
                              <div className='col-1 m-0 p-0 pl-2 align-self-center'><img src={`/${this.props.siteId}/icon_booking.png`} style={{ width: "2rem" }} alt="ご予約" /></div>
                              <div className='col-10 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">新規予約</span></div>
                              <div className='col-1 m-0 align-self-center'></div>
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                    {
                      this.renderReceptButton()
                    }
                    <div className="row mx-0 mt-3 mb-3 text-center">
                      <div className="col px-0">
                        <Link to={`/${this.state.siteId}/?page=${constClass.HISTORY}`}>
                          <button
                            onClick={e => this.resetBooking()}
                            className={`btn btn-primary btn-booking w-100 h-100 py-3 px-2`}>
                            <div className='row m-0 p-0'>
                              <div className='col-1 m-0 p-0 pl-2 align-self-center'><img src={`/${this.props.siteId}/icon_list.png`} style={{ width: "2rem" }} alt="ご予約" /></div>
                              <div className='col-10 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">予約一覧</span></div>
                              <div className='col-1 m-0 align-self-center'></div>
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="row mx-0 mt-3 mb-3 text-center">
                      <div className="col px-0">
                        <Link to={`/${this.state.siteId}/?page=${constClass.CARDSEARCH}`}>
                          <button
                            onClick={e => this.resetBooking()}
                            className={`btn btn-primary btn-booking w-100 h-100 py-3 px-2`}>
                            <div className='row m-0 p-0'>
                              <div className='col-1 m-0 p-0 pl-2 align-self-center'><img src={`/${this.props.siteId}/search.svg`} style={{ width: "2rem" }} alt="カードNo.検索" /></div>
                              <div className='col-10 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">カードNo.検索</span></div>
                              <div className='col-1 m-0 align-self-center'></div>
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      );
    } else {
      return (
        <div className="row mx-0">
          <div className="col px-0">
            {(("CONTRACT_PERIOD_STARTTIME" in this.props.settings) && parseInt(this.props.settings.CONTRACT_PERIOD_STARTTIME) > parseInt(moment().format('YYYYMMDDkkmm')))
              ?
              <div className="mx-3 px-3-env bg-green text-white h5 text-center">{this.props.settings.CONTRACT_PERIOD_BEFORE_MESSAGE}</div>
              :
              <div>
                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className="col py-1 px-1 align-self-center">
                    <div className="row mx-0 mt-3 mb-3 text-center">
                      <div className="col px-0">
                        <Link to={`/${this.state.siteId}/?page=${constClass.INPUT}`}>
                          <button
                            onClick={e => this.resetBooking()}
                            className={`btn btn-primary btn-booking w-100 h-100 py-3 px-2`}>
                            <div className='row m-0 p-0'>
                              <div className='col-1 m-0 p-0 pl-2 align-self-center'><img src={`/${this.props.siteId}/icon_booking.png`} style={{ width: "2rem" }} alt="ご予約" /></div>
                              <div className='col-10 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">新規予約</span></div>
                              <div className='col-1 m-0 align-self-center'></div>
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                    {
                      this.renderReceptButton()
                    }
                    <div className="row mx-0 mt-3 mb-3 text-center">
                      <div className="col px-0">
                        <Link to={`/${this.state.siteId}/?page=${constClass.HISTORY}`}>
                          <button
                            onClick={e => this.resetBooking()}
                            className={`btn btn-primary btn-booking w-100 h-100 py-3 px-2`}>
                            <div className='row m-0 p-0'>
                              <div className='col-1 m-0 p-0 pl-2 align-self-center'><img src={`/${this.props.siteId}/icon_list.png`} style={{ width: "2rem" }} alt="ご予約" /></div>
                              <div className='col-10 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">予約一覧</span></div>
                              <div className='col-1 m-0 align-self-center'></div>
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      );
    }
  }

  renderUser() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col bg-white px-0 mb-3">
          <div id="user">
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>来訪者情報をご入力ください</div>
            </div>
            <div className="px-3-env py-2"><span className="text-red">※</span>は必須項目です。</div>
            <div className="row mx-0 px-3-env pt-1">
              <div className="col px-0 text-green font-weight-bold">
                <span className="text-red">※</span>会社名
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="company_name" name="company_name" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.company_name} />
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2">
              <div className="col px-0 text-green font-weight-bold">
                <span className="text-red">※</span>電話番号
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="phone_number" name="phone_number" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.phone_number} />
              </div>
            </div>
            <div>
              <div className="row mx-0 px-3-env pt-2">
                <div className="col px-0 text-green font-weight-bold">
                  <span className="text-red">※</span>氏名<button className="btn btn-primary py-2 mb-2 ml-2" onClick={this.addNameInput}>追加</button>
                </div>
              </div>
              {this.state.booking.names.map((name, index) => (
                <div key={index} className="bg-logigreen pt-2">
                  <div className="row mx-0 px-3-env text-left">
                    <div className="col px-0">
                      <input
                        type="text"
                        className="form-control"
                        value={Array.isArray(name) ? name[0] : name} // 名前の部分だけ表示
                        onChange={e => this.handleChange(index, e, Array.isArray(name) ? name[1] : "")}
                        placeholder={`氏名 ${index + 1}`}
                      />
                    </div>
                    {index !== 0 &&
                      <div className="col-auto">
                        <button
                          className="btn btn-danger"
                          onClick={() => this.removeNameInput(index)}
                        >
                          削除
                        </button>
                      </div>
                    }
                  </div>
                  <span className='small mx-2'>本人確認書類を選択してください。</span>
                  <div className="row mx-1 mb-3">
                    {constClass.LICENSE.map((identity_document, idx) => (
                      identity_document !== '2' && (
                        <div key={idx} className={`col mx-1 px-3-env align-self-center form-control custom-radio mb-2 ${this.state.booking.identity_document && identity_document === this.state.booking.identity_document[index] ? "border-green bg-lightyellow" : "border-gray"}`}>
                          <input type="radio" className="custom-control-input" id={`identity_document_${identity_document}_${index}`} onChange={e => this.handleChange1(index, e)} value={identity_document} onBlur={this.scrollWindowTop} checked={this.state.booking.identity_document && identity_document === this.state.booking.identity_document[index]} />
                          <label className="custom-control-label text-nowrap" htmlFor={`identity_document_${identity_document}_${index}`}>{constClass.LICENSE_NAME[identity_document]}</label>
                        </div>
                      )
                    ))}
                    {this.state.booking.identity_document && this.state.booking.identity_document[index] === '4' &&
                      <textarea className="form-control mb-1" rows="1" placeholder="本人確認書類を記載してください" value={this.state.booking.others_identity_document[index]} onChange={e => this.handleChange2(index, e)}></textarea>
                    }
                  </div>
                </div>
              ))}
            </div>
            <div className="row mx-0 px-3-env pt-2">
              <div className="col px-0 text-green font-weight-bold">
                <span className="text-red">※</span>訪問先担当者名
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="person_charge" name="person_charge" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.person_charge} />
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2">
              <div className="col px-0 text-green font-weight-bold">
                <span className="text-red">※</span>用件
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              {constClass.REQUIREMENT.map((requirement, idx) => (
                <div key={idx} className={`col mx-1 px-3-env align-self-center form-control custom-radio mb-2 ${requirement === this.state.booking.requirement ? "border-green bg-lightyellow" : "border-gray"}`}>
                  <input type="radio" className="custom-control-input" id={`requirement_${requirement}`} name="requirement" onChange={e => this.changeCustomer(e)} value={requirement} onBlur={this.scrollWindowTop} checked={this.state.booking.requirement === requirement} />
                  <label className="custom-control-label text-nowrap" htmlFor={`requirement_${requirement}`}>{constClass.REQUIREMENT_NAME[requirement]}</label>
                </div>
              ))}
              {this.state.booking.requirement === '4' &&
                <textarea className="form-control" rows="5" placeholder="内容を記載してください" name="others_requirement" value={this.state.booking.others_requirement} onChange={e => this.changeCustomer(e)}></textarea>
              }
            </div>
            {/* 
              {this.state.booking.customer_gender === '3' &&
                <div>
                  <textarea className="form-control" rows="5"></textarea>
                </div>
              } */}
            <div className="row mx-0 px-3-env">
              <div className="col px-0 text-green font-weight-bold">
                車両番号
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="vehicle_number" name="vehicle_number" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.vehicle_number} placeholder="例）福岡300あ1234" />
                <span className='small'>お車でお越しの場合はご入力ください。</span>
              </div>
            </div>
            <div className="row mx-0 px-3-env">
              <div className="col px-0 text-green font-weight-bold">
                備考
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="vehicle_number" name="remarks" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.remarks} />
              </div>
            </div>
          </div>
          {/* <div className="row mx-0 px-3-env pt-2">
            <div className="col px-0 text-green font-weight-bold">
              年代
            </div>
          </div>
          <div className="row mx-0 px-3-env text-left form-group">
            <select className={`form-control ${this.state.booking.customer_age_type ? "bg-lightyellow" : ""}`} id="customer_age_type" name="customer_age_type" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.customer_age_type || ''}>
              <option value="">-- 選択 --</option>
              {constClass.CUSTOMER_AGE_TYPE.map((customer_age_type) => (
                <option value={customer_age_type} key={customer_age_type}>{constClass.CUSTOMER_AGE_TYPE_NAME[customer_age_type]}</option>
              ))}
            </select>
          </div> */}
        </div>
      </div >
    );
  }

  renderInputAlert() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0 pb-3">
          <div className="row m-0 px-0-env">
            <div className="col bg-white px-0 mb-3">
              <div id="inputalert">
                <div className="row mx-0 px-3-env">
                  <div className="col ml-0 pl-0">
                    <div className='pt-3 ml-0 pl-0 font-weight-bold'>１．ルール</div>
                    <ul className='pl-4'>
                      <li>同日の予約は2件までとなります。</li>
                      <li>予約時間前にキャンセルまたは日時を変更する際は、お客さまご自身でお願いいたします。</li>
                      <li>ご予約は1カ月前から希望日時の30分前まで受付いたします。<br />
                        （例：5月14日の予約は4月15日からとなります。）</li>
                    </ul>

                    <div className='pt-3 font-weight-bold'>２．その他</div>
                    <ul className='pl-4'>
                      <li>二次元コードが上手く読み取れなかった場合等は、受付にお申し出ください。</li>
                      {("SPECIFIED_COMMERCIAL_TRANSACTIONS_URL" in this.props.settings) && <li>特定商取引法については<a href={Common.getExternalUrl(this.props.settings.SPECIFIED_COMMERCIAL_TRANSACTIONS_URL)} target="_blank" rel="noreferrer"><u>こちら</u></a></li>}
                      {("TERMS_OF_SERVICE_URL" in this.props.settings) && <li>利用規約については<a href={Common.getExternalUrl(this.props.settings.TERMS_OF_SERVICE_URL)} target="_blank" rel="noreferrer"><u>こちら</u></a></li>}
                      {("PRIVACYPOLICY_URL" in this.props.settings) && <li>個人情報の取り扱いについては<a href={Common.getExternalUrl(this.props.settings.PRIVACYPOLICY_URL)} target="_blank" rel="noreferrer"><u>こちら</u></a></li>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 px-3-env pt-2 pb-1">
            <div className='col'>
              <div className="row mx-0 px-3-env pt-2 pb-1">
                <div className='col-12 text-center bg-white py-2'>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="privacy_policy" name="privacy_policy" value="true" checked={this.state.booking.privacy_policy === constClass.FLAG.ON} onChange={e => this.changeCustomer(e)} />
                    <label className="custom-control-label m-1" htmlFor="privacy_policy">注意事項に同意する</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderInputCount() {
    return (
      <div>
        <div className="row mx-3 my-3 px-0-env">
          <div className="col bg-white px-0 mb-3">
            <div id="book">
              <div className="row mx-0 px-3-env py-2 bg-green text-white">
                <div className='col font-weight-bold text-center'>施設を選択してください</div>
              </div>
              <div className="row mx-0 px-3-env pt-2 pb-1">
                <div className="col-auto text-green font-weight-bold p-1">
                  施設
                </div>
                <div className="col">
                  <select className="custom-select w-100 text-center" aria-label="施設"
                    value={this.state.booking.logi_id || ''}
                    onChange={(e) => this.changeLogi(e.target.value)}
                    onBlur={this.scrollWindowTop}>
                    <option value="" >- 選択 -</option>
                    {this.state.logiList.map((item, idx) =>
                      <option key={idx} value={item.logi_id}>{item.logi_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="row mx-3 my-3 px-0-env">
          <div className="col bg-white px-0 mb-3">
            <div id="book">
              <div className="row mx-0 px-3-env py-2 bg-green text-white">
                <div className='col font-weight-bold text-center'>訪問先を選択してください</div>
              </div>
              <div className="row mx-0 px-3-env pt-2 pb-1">
                <div className="col-auto text-green font-weight-bold p-1">
                  訪問先
                </div>
                <div className="col">
                  <select className="custom-select w-100 text-center" aria-label="訪問先"
                    value={this.state.booking.place_visit_id || ''}
                    onChange={(e) => this.changePlaceVisit(e.target.value)}
                    onBlur={this.scrollWindowTop}>
                    <option value="" >- 選択 -</option>                  
                    {this.state.booking.logi_id && this.state.place_visitList.map((item, idx) =>
                      <option key={idx} value={item.place_visit_id}>{item.place_visit_name}</option>
                    )}                  
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
    );
  }

  renderInputBin() {
    return (
      <div>
        <div className="nextbutton">
          <div className="row mx-3 my-3 px-0-env nextbutton">
            <div className="col bg-white px-0 mb-3">
              <div id="book">
                <div className="row mx-0 px-3-env py-2 bg-green text-white">
                  <div className='col font-weight-bold text-center'>日付を選択してください。</div>
                </div>
                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className="col-3 mx-0 p-0 align-self-center text-green font-weight-bold">
                    日付
                  </div>
                  <div className="col">
                    <DatePicker className={`form-control`} aria_label="受取日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="bookingDate" name="bookingDate"
                      selected={(!this.state.booking.bookingdate || this.state.booking.bookingdate === '') ? null : moment(this.state.booking.bookingdate, 'YYYY-MM-DD').toDate()}
                      minDate={new Date()}
                      onChange={date => this.changeDate(date ? moment(date).format('YYYY-MM-DD') : null)}
                      placeholderText="- 選択 -"
                      disabledKeyboardNavigation
                    />
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div>
        {/* <div className="row mx-3 my-3 px-0-env">
            <div className="col bg-white px-0 mb-3"> */}
        {this.state.booking.bookingdate &&
          <div className="row mx-3 my-3 px-0-env">
            <div className="col bg-white px-0 mb-3">
              <div id="book">
                <div className="row mx-0 px-3-env py-2 bg-green text-white nextbutton">
                  <div className='col font-weight-bold text-center'>時間帯を選択してください</div>
                </div>
                <div className="row mx-0 px-3-env pt-2 pb-1 nextbutton">
                  <div className="col-auto text-green font-weight-bold p-1">
                    開始時間
                  </div>
                  <div className="col">
                    <select
                      className="custom-select w-100 text-center"
                      aria-label="開始時間"
                      value={this.state.booking.start_time || ''}
                      onChange={(e) => this.changeStart(e.target.value)}
                      onBlur={this.scrollWindowTop}
                    >
                      {/* Number(reservation.end_time.substring(0, 2)) <= hour */}
                      <option value="">- 選択 -</option>
                      {[...Array(25).keys()].map((hour) => {
                        const sysdate = new Date();
                        if (this.state.booking.bookingdate === moment(sysdate).format("YYYY-MM-DD")) {
                          return (
                            <React.Fragment key={hour}>
                              {sysdate.getHours() === hour && sysdate.getMinutes() <= 30 && (
                                <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                  {`${hour.toString().padStart(2, '0')}時30分`}
                                </option>
                              )}
                              {sysdate.getHours() < hour &&  hour !== 24 && (
                                <>
                                  <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                    {`${hour.toString().padStart(2, '0')}時00分`}
                                  </option>
                                  {hour !== 24 &&
                                    <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                      {`${hour.toString().padStart(2, '0')}時30分`}
                                    </option>
                                  }
                                </>
                              )}
                              {hour === 24 &&
                                <option value={`00:00`}>
                                  {`00時00分`}
                                </option>
                              }
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={hour}>
                              {hour !== 24 &&
                                <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                  {`${hour.toString().padStart(2, '0')}時00分`}
                                </option>
                              }
                              {hour !== 24 &&
                                <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                  {`${hour.toString().padStart(2, '0')}時30分`}
                                </option>
                              }
                              {hour === 24 &&
                                <option value={`00:00`}>
                                  {`00時00分`}
                                </option>
                              }
                            </React.Fragment>
                          );
                        }
                      })}
                    </select>
                  </div>
                </div>
                <div className="row mx-0 px-3-env pt-2 pb-1 nextbutton">
                  <div className="col-auto text-green font-weight-bold p-1">
                    終了時間
                  </div>
                  <div className="col">
                    <select
                      className="custom-select w-100 text-center"
                      aria-label="終了時間"
                      value={this.state.booking.end_time || ''}
                      onChange={(e) => this.changeEnd(e.target.value)}
                      onBlur={this.scrollWindowTop}
                    >
                      <option value="">- 選択 -</option>
                      {[...Array(25).keys()].map((hour) => {
                        const sysdate = new Date();
                        if (this.state.booking.start_time && Number(this.state.booking.start_time.substring(0, 2)) <= hour) {
                          if (Number(this.state.booking.start_time.substring(0, 2)) === hour && Number(this.state.booking.start_time.substring(3, 5)) === 30) {
                            return null;
                          }
                          else {
                            return (
                              <React.Fragment key={hour}>
                                {Number(this.state.booking.start_time.substring(0, 2)) !== hour && hour !== 24 &&
                                  <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                    {`${hour.toString().padStart(2, '0')}時00分`}
                                  </option>
                                }
                                {hour !== 24 &&
                                  <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                    {`${hour.toString().padStart(2, '0')}時30分`}
                                  </option>
                                }
                                {hour === 24 &&
                                  <option value={`00:00`}>
                                    {`00時00分`}
                                  </option>
                                }
                              </React.Fragment>
                            );
                          }
                        } else if (!this.state.booking.start_time) {
                          if (this.state.booking.bookingdate === moment(sysdate).format("YYYY-MM-DD")) {
                            return (
                              <React.Fragment key={hour}>
                                {sysdate.getHours() === hour && sysdate.getMinutes() <= 30 && (
                                <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                  {`${hour.toString().padStart(2, '0')}時30分`}
                                </option>
                                )}
                                {sysdate.getHours() < hour && (
                                  <>
                                    <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                      {`${hour.toString().padStart(2, '0')}時00分`}
                                    </option>
                                    {hour !== 24 &&
                                      <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                        {`${hour.toString().padStart(2, '0')}時30分`}
                                      </option>
                                    }
                                    {hour === 24 &&
                                      <option value={`00:00`}>
                                        {`00時00分`}
                                      </option>
                                    }
                                  </>
                                )}
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={hour}>
                                <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                  {`${hour.toString().padStart(2, '0')}時00分`}
                                </option>
                                {hour !== 24 &&
                                  <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                    {`${hour.toString().padStart(2, '0')}時30分`}
                                  </option>
                                }
                                {hour === 24 &&
                                  <option value={`00:00`}>
                                    {`00時00分`}
                                  </option>
                                }
                              </React.Fragment>
                            );
                          }
                        } else {
                          return null;
                        }
                        //}
                      })}
                    </select>
                  </div>
                </div>

                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className="col">
                    <FullCalendar
                      ref={this.calendarRef}
                      plugins={[interactionPlugin, timeGridPlugin]}
                      locale="ja"
                      allDaySlot={false}
                      slotEventOverlap={false}
                      initialView="timeGridFourDay"
                      dayHeaders={false}
                      initialDate={this.state.booking.bookingdate} //初期値のみ変更は日付選択時に制御
                      views={{
                        timeGridFourDay: {
                          type: 'timeGrid',
                          duration: { days: 1 }
                        }
                      }}
                      contentHeight={'auto'}
                      selectable={true}
                      selectMirror={true}
                      headerToolbar={{
                        left: '',
                        center: '',
                        right: ''
                      }}
                      select={this.handleSelectClick}
                      unselect={this.handleUnselectClick}
                      events={
                        [
                          ...(this.state.reservation ? this.state.reservation.map(r => ({
                            title: r.title,
                            start: new Date(r.start_time),
                            end: new Date(r.end_time),
                            color: "#808080",
                          })) : []),
                          ...(this.state.bookingListtoday ? this.state.bookingListtoday.map(book => ({
                            title: "受付済み",
                            start: new Date(`${book.bookingdate}T${book.start_time}`),
                            end: new Date(`${book.bookingdate}T${book.end_time}`),
                            color: "#3cb36f"
                          })) : [])
                        ]
                      }
                      selectOverlap={false}
                      unselectAuto={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div >
        }
      </div>
    );

  }

  renderConfirmation() {
    const confirmationItem = this.state.confirmationList.find(c => c.line_id === this.props.liff_user_id);

    if (confirmationItem && this.state.booking.status === constClass.STATUS.ARE) {
      return (
        <div className="row mx-3 my-3 px-0-env">
          <div className="col px-0">
            <div id="bookinputcheck">
              <BookingDetailView
                state={this.state}
                cancel_view={true}
                settings={this.props.settings}
                onChangeClick={(state, page) => this.changeClick(state, page)}
                page={this.props.page}
                person={confirmationItem.name}
              />
            </div>
          </div>
        </div>
      );
    } else if (confirmationItem && this.state.booking.status !== constClass.STATUS.ARE){
      return (<div className="row mx-3 my-3 px-0-env">
        <div className='col text-center'>
          入館済みのQRを読み込んでください。
        </div>
      </div >)
    } else if (!confirmationItem){
      return (<div className="row mx-3 my-3 px-0-env">
        <div className='col text-center'>
          QRを読み込めるのは到着確認者です。
        </div>
      </div >)
    } else {
      return (<div className="row mx-3 my-3 px-0-env">
        <div className='col text-center'>
          エラーが発生しました。
        </div>
      </div >)
    }
  }
  

  renderCardSearch() {
    const confirmationItem = this.state.confirmationList.find(c => c.line_id === this.props.liff_user_id);

    if (confirmationItem) {
      return (
        <div>
          <div className="row mx-3 my-3 px-0-env">
            <div className="col bg-white px-0 mb-3">
              <div id="book">
                <div className="row mx-0 px-3-env py-2 bg-green text-white">
                  <div className='col font-weight-bold text-center'>施設を選択してください</div>
                </div>
                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className="col-auto text-green font-weight-bold p-1">
                    施設
                  </div>
                  <div className="col">
                    <select className="custom-select w-100 text-center" aria-label="施設"
                      value={this.state.card_logi_id || ''}
                      onChange={(e) => this.changeCardLogi(e.target.value)}
                      onBlur={this.scrollWindowTop}>
                      <option value="" >- 選択 -</option>
                      {this.state.logiList.map((item, idx) =>
                        <option key={idx} value={item.logi_id}>{item.logi_name}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div >
          <div className="row mx-3 my-3 px-0-env">
            <div className="col bg-white px-0 mb-3">
              <div id="book">
                <div className="row mx-0 px-3-env py-2 bg-green text-white">
                  <div className='col font-weight-bold text-center'>カードNo.を選択してください</div>
                </div>
                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className="col-auto text-green font-weight-bold p-1">
                    カードNo.
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="card_number" name="card_number" onChange={e => this.changeCardNumber(e)} onBlur={this.scrollWindowTop} value={this.state.card_number} />
                  </div>
                </div>
              </div>
            </div>
          </div >
          {this.state.card_nothing &&
            <div className="row mx-3 my-3 px-0-env">
              <div className='col text-center'>
                一致するカードがありませんでした。
              </div>
            </div >
          }
          <div className="col-12 text-center">
            <div className='p-4 p-env-bottom m-4'>
              <button
                disabled={!this.state.card_number || !this.state.card_logi_id || !confirmationItem}
                className={`btn btn-active w-100`}
                onClick={() => this.checkserach()}
              >
                検索
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderConfirmationPerson() {
    return (
      <div>
        <div className="row mx-3 my-3 px-0-env">
          <div className="col bg-white px-0 mb-3">
            <div id="book">
              <div className="row mx-0 px-3-env py-2 bg-green text-white">
                <div className='col font-weight-bold text-center'>お客様情報をご入力ください</div>
              </div>
              <div className="row mx-0 px-3-env pt-2">
                <div className="col px-0 text-green font-weight-bold">
                  氏名
                </div>
              </div>
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col px-0">
                  <input type="text" className="form-control" id="name" name="name" onChange={e => this.changeConfirmation(e)} onBlur={this.scrollWindowTop} value={this.state.confirmationperson.name} />
                </div>
              </div>
              <div className="row mx-0 px-3-env pt-2">
                <div className="col px-0 text-green font-weight-bold">
                  氏名コード
                </div>
              </div>
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col px-0">
                  <input type="text" className="form-control" id="name_code" name="name_code" onChange={e => this.changeConfirmation(e)} onBlur={this.scrollWindowTop} value={this.state.confirmationperson.name_code} />
                </div>
              </div>
              <div className="row mx-0 px-3-env pt-2">
                <div className="col px-0 text-green font-weight-bold">
                  認証コード
                </div>
              </div>
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col px-0">
                  <input type="text" className="form-control" id="authentication_code" name="authentication_code" onChange={e => this.changeConfirmation(e)} onBlur={this.scrollWindowTop} value={this.state.confirmationperson.authentication_code} />
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="col-12 text-center">
          <div className='p-4 p-env-bottom m-4'>
            <button
              disabled={!this.state.confirmationperson.name || !this.state.confirmationperson.name_code || !this.state.confirmationperson.authentication_code || this.state.disabled || this.state.confirmationdisabled}
              className={`btn btn-active w-100`}
              onClick={() => this.confirmationclick()}
            >
              到着確認者登録
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderConfirm() {
    // total = CommonBooking.getTotal(this.state.booking_detail, (this.state.booking.refund_total ? this.state.booking.refund_total : 0));
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0 pb-2">
          <div id="bookinputcheck">
            {/* {!this.state.change && <React.Fragment>
              {[constClass.STATUS.REG, constClass.STATUS.PRE].includes(this.state.booking.status) &&
                <h4 className='text-center px-3-env py-2'>
                  予約済
                </h4>
              }
              {this.state.booking.status === constClass.STATUS.FIN &&
                <h4 className='text-center px-3-env py-2'>
                  来店済
                </h4>
              }
              {this.state.booking.status === constClass.STATUS.CCL &&
                <h4 className='text-center px-3-env py-2'>
                  キャンセル済
                </h4>
              }
              {this.state.booking.status === constClass.STATUS.CUPAY &&
                <h5 className='text-center px-3-env py-2 bg-white'>
                  決済期限切れです。<br />
                  お手数ですが、再度やり直してください。
                </h5>
              }
            </React.Fragment>}
            {(!this.state.change && this.state.booking.status === constClass.STATUS.UPAY
              && this.state.booking_detail.findIndex(x => (CommonBooking.checkChangeTime(x.book_date, x.start_time, this.props.settings.CANCEL_LIMIT))) >= 0) &&
              <h4 className='text-center px-3-env py-2 bg-white'>
                決済が完了していません。
                <button className="btn btn-danger" onClick={e => this.submitPayment(this.state.booking.book_id)}>決済へ進む</button>
              </h4>
            } */}
            {constClass.STATUS_CCL.includes(this.state.booking.status) &&
              <BookingDetailView
                state={this.state}
                cancel_view={true}
                settings={this.props.settings}
                onChangeClick={(state, page) => { this.changeClick(state, page); }}
              />
            }
            {!constClass.STATUS_CCL.includes(this.state.booking.status) &&
              <BookingDetailView
                state={this.state}
                cancel_view={false}
                settings={this.props.settings}
                onChangeClick={(state, page) => { this.changeClick(state, page); }}
              />
            }
            {/* <div className="row mx-0 mt-3 py-2 pb-0 bg-white">
              <div className="col py-2 font-weight-bold text-green text-right">
                合計
                {(this.state.booking.book_id && this.state.booking.status === constClass.STATUS.UPAY) && "※未決済"}
              </div>
              <div className="col-auto py-2 font-weight-bold text-green text-right">
                {total.toLocaleString()}円<br />
              </div>
            </div> */}
          </div>
          {(!this.state.change && this.state.book_id && !constClass.STATUS_CCL.includes(this.state.booking.status)) &&
            <React.Fragment>
              <div className="row mx-0 pt-2 pb-3">
                <div className="col font-weight-bold text-center">
                  <button
                    className={`btn btn-primary py-2 px-1 w-75`}
                    onClick={() => this.submitCancel(this.state.book_id)}>
                    キャンセル
                  </button>
                </div>
              </div>
            </React.Fragment>}
          {/* {("PAY_CANCEL_DETAIL_MESSAGE" in this.props.settings) &&
            <div className='row mx-0 pt-2 pb-0'>
              <div className='col mx-2 text-red'><a href={Common.getExternalUrl(this.props.settings[`PAY_CANCEL_DETAIL_URL`])} target="_blank" rel="noreferrer"><u>※{this.props.settings.PAY_CANCEL_DETAIL_MESSAGE}</u></a></div>
            </div>
          } */}
        </div>
      </div>
    )
  }

  // renderFullCalendar() {
  //   return (
  //     <FullCalendar
  //       plugins={[timeGridPlugin]}
  //       locale="ja"
  //       allDaySlot={false}
  //       slotEventOverlap={false}
  //       initialView="timeGridFourDay"
  //       // businessHours={{
  //       //   daysOfWeek: [1, 2, 3, 4, 5],
  //       //   startTime: "07:00",
  //       //   endTime: "22:00",
  //       // }}
  //       headerToolbar={false}
  //       initialDate={this.state.booking.bookingdate ? moment(this.state.booking.bookingdate).format("YYYY-MM-DD") : new Date()}
  //       views={{
  //         timeGridFourDay: {
  //           type: 'timeGrid',
  //           duration: { days: 1 }
  //         }
  //       }}
  //       contentHeight={'auto'}
  //       events={[
  //         {
  //           title: "田中　良明",
  //           start: "2024-04-23T10:00:00", // 時間を指定するときはISO 8601の形式で。
  //           end: "2024-04-23T13:00:00", // 時間を指定するときはISO 8601の形式で。
  //         },
  //         {
  //           title: "西川　太郎",
  //           start: "2024-04-23T10:00:00", // 時間を指定するときはISO 8601の形式で。
  //           end: "2024-04-23T14:00:00", // 時間を指定するときはISO 8601の形式で。
  //         },
  //         {
  //           title: "西川　太郎",
  //           start: "2024-04-23T10:00:00", // 時間を指定するときはISO 8601の形式で。
  //           end: "2024-04-23T14:00:00", // 時間を指定するときはISO 8601の形式で。
  //         },
  //       ]}
  //     />
  //   );
  // }

  renderPayment() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col p-1">
          <Payment
            siteId={this.props.siteId}
            lineId={this.props.liff_access_token}
            id={this.state.payment_data.id}
            amount={this.state.payment_data.amount}
            fee={this.state.payment_data.fee}
            backUrl={this.state.payment_data.backUrl}
            returnUrl={this.state.payment_data.returnUrl}
            checkUrl={this.state.payment_data.checkUrl}
            redirectReturnUrl={this.state.payment_data.redirectReturnUrl}
            abortUrl={`/${this.state.siteId}/`}
            backFunc={this.backPayment}
            returnFunc={this.returnPayment} />
        </div>
      </div>
    );
  }

  // {this.state.bookingList.filter(b => (
  //   moment(b.bookingdate).isAfter(moment().subtract(1, "month").format('YYYY-MM-DD'))//1ヶ月前まで表示
  //   /*&& b.booking_status !== constClass.STATUS.UPAY && b.booking_status !== constClass.STATUS.CUPAY // 未決済は一覧に表示しない*/
  // )).length === 0 &&
  //   <div>
  //     <div className="row mx-0 px-3-env pt-2 pb-1 bg-green text-white">
  //       <div className='col-12 py-3 h5 text-center'>本日のご予約はありません。</div>
  //     </div>
  //   </div>
  // }

  renderBookSelect() {

    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0">
          <div id="booklist">
            {this.state.bookingList
              .filter(b => !constClass.STATUS_CCL.includes(b.status))
              .map((item, idx) => (
                <div className="row mx-0 my-3 text-center" key={`${idx}`}>
                  <div className="col px-0">
                    <button
                      onClick={async (e) => { this.submitCurrentbooking(item.book_id, (item.status === constClass.STATUS.BEF) ? constClass.CHANGECONFIRM : constClass.CONFIRM); }}
                      className={`btn btn-primary danger w-100 h-100 py-2 px-2`}>
                      <div>
                        <div className='row'>
                          {(item.start_time || item.end_time) ?
                            <div className='col'>{moment(item.bookingdate).format("M月D日")} {(item.start_time)}～{(item.end_time)}</div>
                            : <div className='col'>{moment(item.bookingdate).format("M月D日")}</div>}
                        </div>
                        <div className='row'>
                          <div className='col'>{item.logi_name}</div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <button
                    className={`btn bg-green text-white py-1 px-1`}
                    onClick={() => {
                      this.changeAgainBookClick({ change: false, back: true }, constClass.INPUTBIN1, item.book_id);
                    }}>
                    再予約
                  </button>
                </div>
              ))}
            <hr />
            {this.state.bookingList
              .filter(b => constClass.STATUS_CCL.includes(b.status))
              .map((item, idx) => (
                <div className="row mx-0 my-3 text-center" key={`${idx}`}>
                  <div className="col px-0">
                    <button
                      onClick={async (e) => { this.submitCurrentbooking(item.book_id, (item.status === constClass.STATUS.BEF) ? constClass.CHANGECONFIRM : constClass.CONFIRM); }}
                      className={`btn btn-secondary danger w-100 h-100 py-2 px-2`}>
                      <div>
                        <div className='row'>
                          {(item.start_time || item.end_time) ?
                            <div className='col'>{moment(item.bookingdate).format("M月D日")} {(item.start_time)}～{(item.end_time)}</div>
                            : <div className='col'>{moment(item.bookingdate).format("M月D日")}</div>}
                        </div>
                        <div className='row'>
                          <div className='col'>{item.logi_name}</div>
                        </div>
                        <div className='row'>
                          <div className='col'>({constClass.STATUS_NAME_USERLIST[item.status]})
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <button
                    className={`btn bg-green text-white py-1 px-1`}
                    onClick={() => {
                      this.changeAgainBookClick({ change: false, back: true }, constClass.INPUTBIN1, item.book_id);
                    }}>
                    再予約
                  </button>
                </div>
              ))}
            {/* {this.state.bookingList.filter(b => !(moment(b.book_date).isSameOrAfter(moment().format('YYYY-MM-DD')) && constClass.STATUS_NFIN.includes(b.booking_status))
              && moment(b.book_date).isAfter(moment().subtract(1, "month").format('YYYY-MM-DD'))//1ヶ月前まで表示
              && (b.booking_status !== constClass.STATUS.CUPAY) // キャンセル未決済は一覧に表示しない
            )
              .sort((b, a) => (moment(`${a.book_date} ${CommonBooking.strTotime(a.start_time)}`, 'YYYY-MM-DD k:mm').format('YYYYMMDDkkmm') - moment(`${b.book_date} ${CommonBooking.strTotime(b.start_time)}`, 'YYYY-MM-DD k:mm').format('YYYYMMDDkkmm'))) //予約済み・事前通知済み以外
              //.slice(0, 5)
              .map((item, idx) => (
                <div className="row mx-0 my-3 text-center" key={`${idx}`}>
                  <div className="col px-0">
                    <button
                      onClick={async (e) => { this.submitCurrentbooking(item.book_id, item.book_set_id, (moment(item.book_date).format('YYYYMMDD') === moment().format('YYYYMMDD') && item.booking_status === constClass.STATUS.FIN) ? constClass.RECEPT : constClass.CHANGECONFIRM); }}
                      className={`btn btn-secondary w-100 h-100 py-2 px-2`}>
                      <div className='row'>
                        <div className='col'>受付番号：{item.t_booking.receipt_num}</div>
                      </div>
                      <div className='row'>
                      {(item.start_time || item.end_time) ?
                          <div className='col'>{moment(item.book_date).format("M月D日")} {CommonBooking.strTotime(item.start_time)}～{CommonBooking.strTotime(item.end_time)}</div>
                          : <div className='col'>{moment(item.book_date).format("M月D日")}</div>}
                      </div>
                      <div className='row'>
                        <div className='col'>{item.category_name}</div>
                      </div>
                      {(constClass.STATUS.CPRE === item.booking_status || constClass.STATUS_PRE.includes(item.booking_status)) &&
                        <div className='row'>
                          <div className='col'>未受付</div>
                        </div>
                      }
                      {item.booking_status === constClass.STATUS.FIN &&
                        <div className='row'>
                          <div className='col'>受付済</div>
                        </div>
                      }
                      {constClass.STATUS_CCL.includes(item.booking_status) &&
                        <div className='row'>
                          <div className='col'>キャンセル済</div>
                        </div>
                      }
                    </button>
                  </div>
                </div>
              ))} */}
          </div>
        </div>
      </div>
    );
  }

  renderFinish() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0">
          <div id="bookfinish">
            <div className="row mx-0 pt-5 pb-3 bg-white">
              <div className='col text-center'>
                <h5>ご予約ありがとうございました。</h5>
              </div>
            </div>
            <div className="row mx-0 pt-2 pb-2 bg-white">
              <div className='col text-center'>
                <h6>当日は予約QR表示メニューから<br />QRコードをご提示ください。</h6>
              </div>
            </div>
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>ご予約情報の確認・変更はこちら</div>
            </div>
            <div className="row mx-0 pt-2 pb-1 bg-white">
              <div className='col text-center'>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <button
                      onClick={e => this.submitCurrentbooking(null, constClass.HISTORY)}
                      className={`btn btn-primary w-100 h-100 py-2 px-2`}>
                      <h4 className="mb-0 font-weight-bold">予約一覧</h4>
                    </button>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCorrection() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0">
          <div id="bookfinish">
            <div className="row mx-0 pt-5 pb-3 bg-white">
              <div className='col text-center'>
                <h5>ご予約内容を変更しました。</h5>
              </div>
            </div>
            <div className="row mx-0 pt-2 pb-2 bg-white">
              <div className='col text-center'>
                <h6>当日は予約QR表示メニューから<br />QRコードをご提示ください。</h6>
              </div>
            </div>
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>ご予約情報の確認・変更はこちら</div>
            </div>
            <div className="row mx-0 pt-2 pb-1 bg-white">
              <div className='col text-center'>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <button
                      onClick={e => this.submitCurrentbooking(null, constClass.HISTORY)}
                      className={`btn btn-primary w-100 h-100 py-2 px-2`}>
                      <h4 className="mb-0 font-weight-bold">予約一覧</h4>
                    </button>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCancel() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0">
          <div id="bookfinish">
            <div className="row mx-0 pt-5 pb-3 bg-white">
              <div className='col text-center'>
                <h5>ご予約内容をキャンセルしました。</h5>
              </div>
            </div>
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>ご予約情報の確認・変更はこちら</div>
            </div>
            <div className="row mx-0 pt-2 pb-1 bg-white">
              <div className='col text-center'>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <button
                      onClick={e => this.submitCurrentbooking(null, constClass.HISTORY)}
                      className={`btn btn-primary w-100 h-100 py-2 px-2`}>
                      <h4 className="mb-0 font-weight-bold">予約一覧</h4>
                    </button>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderReceptQr() {
    const confirmationItem = this.state.confirmationList.find(c => c.line_id === this.props.liff_user_id);
    if (confirmationItem) {
      return (
        <div className="row mx-3 my-3 px-0-env">
          <div className="col p-1">
            <BookingQR
              confirmationList={this.state.confirmationList}
              siteId={this.props.siteId}
              lineId={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              book_id={this.props.current_bookId}
              book_set_id={this.props.current_bookSetId}
              category_id={this.props.current_categoryId}
              setting={this.props.settings}
              page={this.props.page}
              recept={this.state.recept}
              getReceptdata={this.getReceptdata}
              submitCurrentbooking={this.submitCurrentbooking}
              person={confirmationItem.name}
            />
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="row mx-3 my-3 px-0-env">
          <div className="col p-1">
            <BookingQR
              confirmationList={this.state.confirmationList}
              siteId={this.props.siteId}
              lineId={this.props.liff_access_token}
              liff_user_id={this.props.liff_user_id}
              book_id={this.props.current_bookId}
              book_set_id={this.props.current_bookSetId}
              category_id={this.props.current_categoryId}
              setting={this.props.settings}
              page={this.props.page}
              recept={this.state.recept}
              getReceptdata={this.getReceptdata}
              submitCurrentbooking={this.submitCurrentbooking}
            />
          </div>
        </div>
      );
    }
  }

  historyBack() {
    if (this.props.history.length <= 1 || [constClass.HISTORY, constClass.FINISH, constClass.RECEPT, constClass.CORRECTION, constClass.CANCEL].includes(this.props.page)) {
      this.submitCurrentbooking(null, null, null);
    } else {
      if (this.props.page === constClass.CHANGECONFIRM && this.state.booking.book_id) {
        this.setState({ change: false });
        // this.submitCurrentbooking(null, null, constClass.HISTORY); ここも確認いる
      } else {
        if (this.props.page === constClass.USER || this.props.page === constClass.CHANGECONFIRM){
          this.setState({ back: true });
        }
        //データ変更を取り消す
        this.setState({
          booking: { ...this.state.set_booking },
          booking_detail: this.state.set_booking_detail.concat(),
          bookCalList: this.state.set_bookCalList,
          bookCalListMessage: this.state.set_bookCalListMessage,
        });
        this.props.history.goBack();
      }
    }
    this.scrollPageTop();
  }

  renderBottom() {
    const Historybackbutton = (h) => {
      return (
        <div className="col-4 text-center p-0">
          <div className='py-2 px-2 p-env-bottom'>
            <button
              className={`btn btn-active w-100 py-2`}
              onClick={e => this.historyBack()}>
              戻る
            </button>
          </div>
        </div>
      )
    }

    const HistorynextButton = () => {
      var disabled = false;
      var nextpage = null;
      if (this.checkInvalid()) disabled = true;
      switch (this.props.page) {
        case constClass.USER:
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.CONFIRM;
          break;
        case constClass.INPUTALERT:
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.INPUT;
          break;
        case constClass.INPUT:
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.INPUTBIN1;
          break;
        case constClass.INPUTBIN1:
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.USER;
          break;
        default:
          return '';
      }
      return (
        <div className="col-8 text-center p-0 nextbutton">
          <div className='py-2 px-2 p-env-bottom'>
            <button
              disabled={disabled}
              className={`btn btn-active w-100 py-2`}
              onClick={() => {
                if (this.props.page === constClass.USER) {
                  const regex = /^\(?0[0-9\-()]{9,12}$/;
                  if (!regex.test(this.state.booking.phone_number)) {
                    alert('※正しい形式で電話番号を入力してください');
                    return '';
                  }
                }
                if (this.props.page === constClass.INPUTBIN1) {
                  var check = false;
                  this.state.reservation.find(r => {
                    const reservationStart = new Date(`${r.start_time}`).getTime();
                    const reservationEnd = new Date(`${r.end_time}`).getTime();
                    const selectedStart = new Date(`${this.state.booking.bookingdate}T${this.state.booking.start_time}`).getTime();
                    const selectedEnd = new Date(`${this.state.booking.bookingdate}T${this.state.booking.end_time}`).getTime();
                    if (selectedStart < reservationEnd && selectedEnd > reservationStart) {
                      alert(`以下の時間帯は予約を受け付けておりません　予約不可日時: ${new Date(reservationStart).toLocaleString()} ～ ${new Date(reservationEnd).toLocaleString()}`);
                      check = true;
                      return true;
                    }
                    return false;
                  });
                  this.state.bookingListtoday.find(book => {
                    const bookStart = new Date(`${book.bookingdate}T${book.start_time}`).getTime();
                    const bookEnd = new Date(`${book.bookingdate}T${book.end_time}`).getTime();
                    const selectedStart = new Date(`${this.state.booking.bookingdate}T${this.state.booking.start_time}`).getTime();
                    const selectedEnd = new Date(`${this.state.booking.bookingdate}T${this.state.booking.end_time}`).getTime();
                    if (selectedStart < bookEnd && selectedEnd > bookStart) {
                      alert(`以下の時間帯は予約済みとなっています　予約日時: ${new Date(bookStart).toLocaleString()} ～ ${new Date(bookEnd).toLocaleString()}`);
                      check = true;
                      return true;
                    }
                    return false;
                  });
                  if (check) {
                    return '';
                  }
                }
                this.setState({
                  set_booking: { ...this.state.booking },
                  set_bookCalList: this.state.bookCalList,
                  set_bookCalListMessage: this.state.bookCalListMessage
                });
                if(nextpage === constClass.INPUTBIN1){
                  this.setState({ back: true });
                }
                this.props.history.push(`/${this.state.siteId}/?page=${nextpage}`);
                this.scrollPageTop();
                if(this.state.selectSlot){
                  this.setState({ selectedSlot: this.state.selectSlot });
                }
              }}>
              次へ
            </button>
          </div>
        </div>
      )
    }

    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100 bg-white border-top-3 border-green">
          <div className="row mx-0 ">
            <div className="col text-center p-0">
              <div className="row mx-0">
                {![constClass.CONFIRMATION].includes(this.props.page) &&
                  <Historybackbutton />
                }
                <HistorynextButton />
                {(this.props.page === constClass.CONFIRM && !constClass.STATUS_CCL.includes(this.state.booking.status)) &&
                  <div className="col-8 text-center p-0">
                    <div className='py-2 px-2 p-env-bottom'>
                      <button
                        disabled={this.state.disabled}
                        className={`btn btn-active w-100 py-2`}
                        onClick={() => { this.submit() }}>
                        予約
                      </button>
                    </div>
                  </div>
                }
                {(this.props.page === constClass.CHANGECONFIRM && !constClass.STATUS_CCL.includes(this.state.booking.status) && this.state.change) &&
                  <div className="col-8 text-center p-0">
                    <div className='py-2 px-2 p-env-bottom'>
                      <button
                        disabled={this.state.disabled}
                        className={`btn btn-active w-100 py-2`}
                        onClick={() => { this.submit() }}>
                        変更
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </footer >
    );
  }

  render() {
    return (
      this.state.sleeping ?
        <div className="liff-top">
          <div className="full-noheader" ref={this.pageRef}>
            <div className="row mx-0">
              <div className="col py-4 text-center">
                <img className='w-50' src={`/${this.props.siteId}/ct__main.svg`} alt="booking" />
              </div>
            </div>
            <div className='text-center py-3'>
              {(!(window.liff.isInClient()) && (this.props.settings && "API_LIFF_URL" in this.props.settings)) ?
                <div className="row mx-3 mt-3 mb-3 text-center">
                  <div className="col px-0">
                    <button
                      onClick={e => window.location.replace(this.props.settings.API_LIFF_URL)}
                      className={`btn btn-primary w-100 h-100 py-3 px-2`}>
                      <div className='row m-0 p-0'>
                        <div className='col m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">LINEで開く</span></div>
                      </div>
                    </button>
                  </div>
                </div>

                : <h5 className='text-white'>{constClass.STOP_MESSAGE[`SITE${this.state.siteId}`]}</h5>
              }
            </div>
          </div>
        </div>
        :
        this.state.ready ?
          <div className={`liff-top ${(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) ? "" : "bg-lightgreen"}`}>
            <ScrollToTop />
            <React.Fragment>
              {!(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) && this.renderHeader()}
              <div className={`page-${(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) ? 'full-noheader' : (this.props.page === constClass.CONFIRMATION) ? 'full' : 'btn'}`} ref={this.pageRef}>
                {(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) &&
                  this.renderMenu()
                }
                {(this.props.page === constClass.USER) &&
                  this.renderUser()
                }
                {(this.props.page === constClass.INPUTALERT) &&
                  this.renderInputAlert()
                }
                {(this.props.page === constClass.INPUT) &&
                  this.renderInputCount()
                }
                {(this.props.page === constClass.INPUTBIN1) &&
                  this.renderInputBin()
                }
                {(this.props.page === constClass.CONFIRM || this.props.page === constClass.CHANGECONFIRM) &&
                  this.renderConfirm()
                }
                {this.props.page === constClass.PAYMENT &&
                  this.renderPayment()
                }
                {this.props.page === constClass.FINISH &&
                  this.renderFinish()
                }
                {this.props.page === constClass.CORRECTION &&
                  this.renderCorrection()
                }
                {this.props.page === constClass.CANCEL &&
                  this.renderCancel()
                }
                {this.props.page === constClass.HISTORY &&
                  this.renderBookSelect()
                }
                {(this.props.page === constClass.RECEPTQR || this.props.page === constClass.RECEPT) &&
                  this.renderReceptQr()
                }
                {this.props.page === constClass.CONFIRMATION &&
                  this.renderConfirmation()
                }
                {this.props.page === constClass.CARDSEARCH &&
                  this.renderCardSearch()
                }
                {this.props.page === constClass.CONFIRMATIONPERSON &&
                  this.renderConfirmationPerson()
                }
              </div>
              {!(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.CONFIRMATION || this.props.page === constClass.CARDSEARCH || this.props.page === constClass.CONFIRMATIONPERSON) &&
                this.renderBottom()
              }
              {this.state.disabled && <Loading />}
            </React.Fragment>
          </div>
          : <Loading />)
  }

}

export default withRouter(Booking);