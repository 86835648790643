import React from 'react';
import Modal from 'react-modal';

const UseAlertModal = ({ isOpen, message, onClose }) => {

  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 1000
    },
    content: {
      position: "absolute",
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      padding: "0px",
      maxHeight: 'auto',
      width: 'auto',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
      overflowX: "hidden",
      pointerEvents: "auto",
      backgroundClip: "padding-box",
      border: "1px rgba(0,0,0,.2)",
      zIndex: 1000
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
        <h3 className="text-center">{message}</h3>
        <div className="mt-3">
          <button className='btn btn-active mx-2' onClick={onClose}>OK</button>
        </div>
      </div>
    </Modal>
  );
};

export default UseAlertModal;
