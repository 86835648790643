import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import Setting from './Setting';
import Logi from './Logi';
import PlacesVisit from './PlacesVisit';
import ReservationBaddate from './ReservationBaddate';
import ConfirmationPerson from './ConfirmationPerson';
import GuardPerson from './GuardPerson';
import User from './User';

const SystemTab = (props) => {

  const [index, setIndex] = useState(0);
  const handleTabSelect = (idx, lastIndex, e) => {
    setIndex(idx);
  };

  // 初期実行トリガー

  return (
    <div className="container">

      <Tabs onSelect={handleTabSelect} defaultIndex={index}>
        <TabList className="nav nav-pills mb-2">
          <Tab className="nav-item"><div className={`nav-link btn ${index === 0 ? 'active' : ''}`} href="#">施設マスタ</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 1 ? 'active' : ''}`} href="#">訪問先マスタ</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 2 ? 'active' : ''}`} href="#">予約不可日時マスタ</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 3 ? 'active' : ''}`} href="#">到着確認者マスタ</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 4 ? 'active' : ''}`} href="#">守衛マスタ</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 5 ? 'active' : ''}`} href="#">ユーザーマスタ</div></Tab>
        </TabList>
        <TabPanel>
          <Logi {...props} /> {/*施設マスタ */}
        </TabPanel>
        <TabPanel>
          <PlacesVisit {...props} /> {/* 訪問先マスタ */}
        </TabPanel>
        <TabPanel>
          <ReservationBaddate {...props} /> {/*予約不可日時マスタ */}
        </TabPanel>
        <TabPanel>
          <ConfirmationPerson {...props} /> {/*到着確認者マスタ */}
        </TabPanel>
        <TabPanel>
          <GuardPerson {...props} /> {/*守衛マスタ */}
        </TabPanel>
        <TabPanel>
          <User {...props} /> {/*ユーザーマスタ */}
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default SystemTab;