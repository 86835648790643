import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import jsQR from 'jsqr';
import Common from '../Common/common';
import constClass from '../../Constants/Constants';
import moment from 'moment';

const videoWidth = 500;
const videoHeight = 500;
const videoFrameRate = 5;

const constraints = {
  audio: false,
  video: {
    width: videoWidth,
    height: videoHeight,
    frameRate: {
      max: videoFrameRate,
    },
    facingMode: 'environment',
  },
};

const QRCodeScanner = ({ match, setMode, setQrCodeData, setBookModalData, scanning, message }) => {
  const history = useHistory();
  const videoRef = useRef(null);
  const intervalRef = useRef();
  const canvasRef = useRef(null);
  const [isContinue, setIsContinue] = useState(false);
  // const [qrCodeData, setQrCodeData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const streamRef = useRef(null);
  const siteId = match.params.siteId;

  const scanQrExternal = useCallback(() => {
    if (window.liff.isInClient()) {
      // var params = {
      //   url: `${process.env.REACT_APP_FRONTEND_URL}/cycletrain/qr`,
      //   external: true,
      // };
      // window.liff.openWindow(params);
      // window.liff.closeWindow();
      window.open(`${process.env.REACT_APP_FRONTEND_URL}/${siteId}/qr?openExternalBrowser=1&timestamp=${moment().format('YYYYMMDDHHmmSS')}`, '_blank');
    } else {
      history.push(`/${siteId}/qr`);
    }
  }, [history, siteId]);

  const handleCloseClick = () => {
    setBookModalData(null);
    setMode(constClass.POINT_SCREEN.MAIN);
  }

  useEffect(() => {
    const openCamera = async () => {
      try {
        if (navigator.mediaDevices) {
          const video = videoRef.current;
          if (video) {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            streamRef.current = stream;
            video.srcObject = stream;
            setIsContinue(true);
          }
        } else {
          console.log('mediaDevices not found');
          if (navigator.getUserMedia) {
            console.log('getUserMedia found');
            const video = videoRef.current;
            if (video) {
              const successFunc = (stream) => {
                streamRef.current = stream;
                video.srcObject = stream;
                setIsContinue(true);
              };
              const errorFunc = (err) => {
                console.log(err);
                setErrorMessage('二次元コード読取が起動できませんでした。(2)');
              }
              navigator.getUserMedia(constraints, successFunc, errorFunc);
            }
          } else {
            console.log('getUserMedia not found');
            // setErrorMessage('二次元コード読取が起動できませんでした。\n駅改札にお申し出ください。');
            // setErrorMessage('ブラウザを起動します。\n二次元コードが読み取れない場合は\n駅改札にお申し出ください。');
            setErrorMessage('ブラウザを起動します。');
            // 外部ブラウザの二次元コードリーダ起動
            scanQrExternal();
          }
        }
      } catch (err) {
        console.log(err);
        setErrorMessage('カメラを起動できませんでした。\nカメラ付き端末でご利用ください。');
      }
    };
    openCamera();
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      setIsContinue(false);
    }
  }, [scanQrExternal]);

  useEffect(() => {
    if (!isContinue) {
      return;
    }

    const decodeQRCode = () => {
      const context = canvasRef ? (canvasRef.current ? canvasRef.current.getContext('2d') : undefined) : undefined;
      const video = videoRef ? videoRef.current : undefined;

      if (!context || !video) {
        return;
      }

      context.drawImage(video, 0, 0, videoWidth, videoHeight);
      const imageData = context.getImageData(0, 0, videoWidth, videoHeight);
      const code = jsQR(imageData.data, videoWidth, videoHeight);

      return code ? code.data : undefined;
    };

    const intervalId = window.setInterval(() => {
      if (scanning) {
        const decodedValue = decodeQRCode();

        if (!decodedValue) {
          return;
        }
        if (decodedValue.includes('page=confirmation')) {
          setErrorMessage('このQRは到着確認用です');
        } else {
          setQrCodeData(decodedValue);
        }
      }
    }, 1000 / videoFrameRate);
    intervalRef.current = intervalId;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isContinue, setQrCodeData, scanning]);

  useEffect(() => {
    if (!scanning) {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [scanning]);

  return (
    <div>
      <div className="bg-white" style={{ display: 'grid' }}>
        {((navigator.mediaDevices || navigator.getUserMedia)) && scanning &&
          <div>
            <video
              autoPlay
              playsInline={true}
              ref={videoRef}
              className={`w-100 ${(videoRef && videoRef.current && videoRef.current.srcObject) ? '' : 'd-none'}`}
            >
              <canvas width={videoWidth} height={videoHeight} ref={canvasRef} />
            </video>
          </div>
        }
        {errorMessage &&
          <div className="text-danger text-center">
            {Common.getBrString(errorMessage)}
          </div>
        }
        {message &&
          <div className="text-danger text-center">
            {Common.getBrString(message)}
          </div>
        }
      </div>
      <div className="row">
        <div className="col text-center">
          <button
            className="btn btn-link text-body"
            onClick={() => handleCloseClick()}>
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeScanner;