import axios from 'axios';
import moment from 'moment';
import constClass from '../../Constants/Constants';

const CommonBooking = {};
CommonBooking.viewBookNo = function (book_date, book_no) {
  return `${moment(book_date).format('YYMMDD')}${('000' + parseInt(book_no)).slice(-3)}`
}
// 変更可能時間確認 true:変更可
CommonBooking.checkChangeTime = function (book_date, start_time, limit) {
  if (!start_time) return false;//start_timeが指定されていなければ変更不可
  return (moment(`${book_date} ${this.strTotime(start_time)}`, 'YYYY-MM-DD k:mm').subtract(parseInt(limit), 'm').isAfter());//開始時刻が現在時刻より後であれば変更可
}
//合計計算
CommonBooking.calcTotal = function (price, count) {
  if (!count) return 0;
  const total = parseInt(price) * (count);
  return total;
}

//時分秒形式(hhmmss)の文字列を 時：分形式(hh:mm)に変換
CommonBooking.strTotime = function (str) {
  if (str) return (str.substr(0, 2) + ":" + str.substr(2, 2));
  else return "";
}

//区間運賃取得
CommonBooking.getPrice = async function (book_date, ride_station_cd, getoff_station_cd) {
  var fare = null
  try {
    const params = {
      book_date: moment(book_date).format('YYYYMMDD'),
      ride_station_cd: ride_station_cd,
      getoff_station_cd: getoff_station_cd
    }
    const farePromise = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_fare/search/`, params);
    fare = (farePromise.data.fare ? farePromise.data.fare : null);
  } catch (error) {
    console.log("getFare error ", error)
  }

  return fare;
}

CommonBooking.getTotal = function (book_detail, refund = 0) {
  const sum = book_detail.filter(x => (!constClass.STATUS_CCL.includes(x.status) || x.status === constClass.STATUS.CFIN)).reduce((sum, item) => sum += item.total, 0) - refund;
  return sum
}
export default CommonBooking;