import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import UseConfirmModal from '../Common/UseConfirmModal';
import UseAlertModal from '../Common/UseAlertModal';  

const BackyardPlacesVisit = (props) => {
  const { user } = props;
  const { addToast } = useToasts();
  const [searchLogiData, setSearchLogiData] = useState(null);
  const [searchPlacesVisitData, setSearchPlacesVisitData] = useState(null);
  const [placesVisitNew, setplacesVisitNew] = useState({ place_visit_id: '', logi_id: props.user.logiId ? props.user.logiId : '', place_visit_name: '', email_address: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');

  const refreshPlacesVisit = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    const placesVisitPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const placesVisit = (await placesVisitPromise).data;
    setSearchPlacesVisitData(placesVisit);
    setSearchLogiData(logi);
    setplacesVisitNew({ place_visit_id: null, logi_id: props.user.logiId ? props.user.logiId : '', place_visit_name: '', email_address: '', upd_date: null });
  }, [props.match.params.siteId, props.user.logiId]);

  // 登録ボタン
  const renderRegistButton = (data, idx) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handlePlacesVisitRegistClick(data)}>
        {data.place_visit_id ? "更新" : "登録"}
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.logi_id === null}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handlePlacesVisitDeleteClick(data)}>
        削除
      </button>
    )
  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...placesVisitNew, [key]: event.target.value };
      setplacesVisitNew(new_data);
    } else {

      const _searchData = [...searchPlacesVisitData];
      _searchData[index] = { ..._searchData[index], [key]: event.target.value };
      setSearchPlacesVisitData(_searchData);
    }
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  // 登録ボタン：処理
  const handlePlacesVisitRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (!data.place_visit_name) {
      setAlertModalMessage('訪問先名を入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (data.place_visit_name.length > 20) {
      setAlertModalMessage('訪問先名は20字以内で入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (!data.email_address) {
      setAlertModalMessage('メールアドレスを入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (data.email_address.length > 255) {
      setAlertModalMessage('メールアドレスは255字以内で入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (!data.logi_id) {
      setAlertModalMessage('施設を選択してください。')
      setIsAlertModalOpen(true);
      return;
    }
    setConfirmModalMessage(`訪問先マスタ[${data.place_visit_name}]を登録します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      //(data.password && data.password.match(/\s+/g))
      const params = {
        site_id: props.match.params.siteId,
        place_visit_id: data.place_visit_id ? data.place_visit_id : '',
        logi_id: Number(data.logi_id),
        place_visit_name: data.place_visit_name,
        email_address: data.email_address,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/places_visit/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('登録しました。', { appearance: 'success', autoDismiss: true });
          await refreshPlacesVisit();
        }
        setLockData(false);
      }
    });
  }

  // 削除ボタン：処理
  const handlePlacesVisitDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    setConfirmModalMessage(`訪問先[${data.place_visit_id}:${data.place_visit_name}]を削除します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      const params = {
        site_id: props.match.params.siteId,
        place_visit_id: data.place_visit_id,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/places_visit/delete/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('削除しました。', { appearance: 'success', autoDismiss: true });
          await refreshPlacesVisit();
        }
        setLockData(false);
      }
    });
  }


  useEffect(() => {
    async function fetchData() {
      await refreshPlacesVisit();
    }
    return fetchData();
  }, [refreshPlacesVisit]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {searchPlacesVisitData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {searchPlacesVisitData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center w-auto">
                    訪問先ID
                  </td>
                  <td className="text-center">
                    訪問先名
                  </td>
                  <td className="text-center">
                    メールアドレス
                  </td>
                  <td className="text-center">
                    施設
                  </td>
                  <td className="text-center">
                    更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr key="new">
                  {/* 訪問先ID */}
                  <td className="text-center align-middle w-auto">
                    新規
                  </td>
                  {/* 訪問先名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"place_visit_name_new"} name="place_visit_name[]" value={placesVisitNew.place_visit_name} onChange={handleChangeCell('new', 'place_visit_name')} size="15"></input>
                  </td>
                  {/* メールアドレス */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"email_address_new"} name="email_address[]" value={placesVisitNew.email_address} onChange={handleChangeCell('new', 'email_address')} size="15"></input>
                  </td>
                  {/* 施設 */}
                  <td className="text-center align-middle">
                    <select className="form-control" id="logi_id" name="logi_id" value={placesVisitNew.logi_id} onChange={handleChangeCell('new', 'logi_id')}>
                      <option value="">選択してください</option>
                      {searchLogiData && searchLogiData.map((data2, idx) => (
                        <option key={data2.logi_id} value={data2.logi_id} >{data2.logi_name}</option>
                      ))}</select>
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(placesVisitNew, 'new')}
                  </td>
                </tr>
                {searchPlacesVisitData.map((data, idx) => (
                  <tr key={idx}>
                    {/* 訪問先ID */}
                    <td className="text-center align-middle w-auto">
                      {data.place_visit_id}
                    </td>
                    {/* 訪問先名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"place_visit_name" + idx} name="place_visit_name[]" value={data.place_visit_name || ''} onChange={handleChangeCell(idx, 'place_visit_name')} size="15"></input>
                    </td>
                    {/* メールアドレス */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"email_address" + idx} name="email_address[]" value={data.email_address || ''} onChange={handleChangeCell(idx, 'email_address')} size="15"></input>
                    </td>
                    {/* 施設 */}
                    <td className="text-center align-middle">
                      <select className="form-control" id="logi_id" name="logi_id" value={data.logi_id} onChange={handleChangeCell(idx, 'logi_id')}>
                        {searchLogiData && searchLogiData.map((data2, idx) => (
                          <option key={data2.logi_id} value={data2.logi_id} >{data2.logi_name}</option>
                        ))}</select>
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:SS') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data, idx)}
                      {data.logi_id && renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
       <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal}/>
       <UseAlertModal isOpen={isAlertModalOpen} message={alertModalMessage} onClose={closeAlertModal}/>
    </div>
  )
}

export default BackyardPlacesVisit