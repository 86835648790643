import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { Link, useParams, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import 'moment/locale/ja';
import ja from 'date-fns/locale/ja';
import UseConfirmModal from '../Common/UseConfirmModal';
import UseAlertModal from '../Common/UseAlertModal';  

registerLocale('ja', ja);

const Booking = (props) => {
  const [logiList, setLogiMaster] = useState([]);
  const [placesVisitList, setPlacesVisitMaster] = useState([]);
  const { book_id } = useParams();
  const history = useHistory();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');

  const [bookData, setBookData] = useState({
    site_id: props.siteId,
    book_id: '',
    logi_id: '',
    logi_name: '',
    place_visit_id: '',
    place_visit_name: '',
    bookingdate: '',
    start_time: '',
    end_time: '',
    names: [''], //氏名
    identity_document: [''], //本人確認書類
    card_number: [''], //カード番号
    return_card: [''], //返却
    bonded_area: [''], //保税エリア
    baggage_inspection: [''], //手荷物検査
    physical_examination: [''], //身体検査
    metal_detector:  [''], //金属探知機
    others_identity_document: [''],
    company_name: '',
    phone_number: '',
    person_charge: '',
    requirement: '',
    others_requirement: '',
    vehicle_number: '', //車両番号
    parking_number: '', //駐車場番号
    remarks: '',
    status: '',
  });


  const { addToast } = useToasts();
  const refreshBook = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.siteId,
    }
    var reg_params = {};
    reg_params = {
      "operator": "and",
      "where": [
        { "site_id": props.siteId }
      ]
    }
    if (book_id) {
      reg_params.where.push({ "book_id": book_id });
      const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard_search/`, reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
      const namesList =  (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/names/backyard_search/`, reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
      var names = [];
      var identity_document = [];
      var others_identity_document = [];
      var card_number = [];
      var return_card = [];
      var bonded_area = [];
      var baggage_inspection = [];
      var physical_examination = [];
      var metal_detector = [];
      namesList.map(async (item, idx) => {
        names.push([item.name, item.name_id]);
        card_number.push(item.card_number);
        return_card.push(item.return_card);
        bonded_area.push(item.bonded_area);
        baggage_inspection.push(item.baggage_inspection);
        physical_examination.push(item.physical_examination);
        metal_detector.push(item.metal_detector);
        if (item.identity_document !== '社員証' && constClass.NAME_LICENCE[item.identity_document]) {
          identity_document.push(constClass.NAME_LICENCE[item.identity_document]);
          others_identity_document.push('');
        }
        else {
          identity_document.push('4')
          const match = item.identity_document.match(/その他\((.*?)\)/);
          const staff_card = item.identity_document.match(/社員証/);
          if (match) {
            others_identity_document.push(match[1]);
          } else if (staff_card) {
            others_identity_document.push(staff_card[0]);
          }
        }
      });
      // var a = {        book_id: data.book_id,
      //   logi_id: data.logi_id,
      //   logi_name: data.logi_name,
      //   place_visit_id: data.place_visit_id,
      //   place_visit_name: data.place_visit_name,
      //   bookingdate: data.bookingdate,
      //   start_time: data.start_time,
      //   end_time: data.end_time,
      //   names: names, //氏名
      //   identity_document: identity_document, //本人確認書類
      //   card_number: card_number, //カード番号
      //   return_card: return_card, //返却
      //   bonded_area: bonded_area, //保税エリア
      //   baggage_inspection: baggage_inspection, //手荷物検査
      //   physical_examination: physical_examination, //身体検査
      //   metal_detector: metal_detector, //金属探知機
      //   others_identity_document: constClass.NAME_REQUIREMENT[data.requirement] ? '' : data.requirement.match(/その他\((.*?)\)/)[1],
      //   company_name: data.company_name,
      //   phone_number: data.phone_number,
      //   person_charge: data.person_charge,
      //   requirement: constClass.NAME_REQUIREMENT[data.requirement] ? constClass.NAME_REQUIREMENT[data.requirement]: '4',
      //   vehicle_number: data.vehicle_number, //車両番号
      //   parking_number: data.parking_number, //駐車場番号
      //   remarks: data.remarks}
      setBookData({
        site_id: props.siteId,
        book_id: data[0].book_id ? data[0].book_id : '',
        logi_id: data[0].logi_id ? data[0].logi_id : '',
        logi_name: data[0].logi_name ? data[0].logi_name : '',
        place_visit_id: data[0].place_visit_id ? data[0].place_visit_id : '' ,
        place_visit_name: data[0].place_visit_name ? data[0].place_visit_name : '',
        bookingdate: data[0].bookingdate ? data[0].bookingdate : '',
        start_time: data[0].start_time ? data[0].start_time : '',
        end_time: data[0].end_time ? data[0].end_time : '',
        names: names ? names : '', //氏名
        identity_document: identity_document ? identity_document : '', //本人確認書類
        card_number: card_number ? card_number : '', //カード番号
        return_card: return_card ? return_card : '', //返却
        bonded_area: bonded_area ? bonded_area : '', //保税エリア
        baggage_inspection: baggage_inspection ? baggage_inspection : '', //手荷物検査
        physical_examination: physical_examination ? physical_examination : '', //身体検査
        metal_detector: metal_detector ? metal_detector : '', //金属探知機
        others_identity_document: others_identity_document ? others_identity_document : '',
        company_name: data[0].company_name ? data[0].company_name : '',
        phone_number: data[0].phone_number ? data[0].phone_number : '',
        person_charge: data[0].person_charge ? data[0].person_charge : '',
        requirement: constClass.NAME_REQUIREMENT[data[0].requirement] ? constClass.NAME_REQUIREMENT[data[0].requirement]: '4',
        others_requirement: constClass.NAME_REQUIREMENT[data[0].requirement] ? '' : data[0].requirement.match(/その他\((.*?)\)/)[1],
        vehicle_number: data[0].vehicle_number ? data[0].vehicle_number : '', //車両番号
        parking_number: data[0].parking_number ? data[0].parking_number : '', //駐車場番号
        remarks: data[0].remarks ? data[0].remarks : '',
        status: data[0].status ? data[0].status : '',
      });
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    const placesVisitPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const placesVisit = (await placesVisitPromise).data;
    setPlacesVisitMaster(placesVisit);
    setLogiMaster(logi);
  }, [props.siteId, book_id]);

  useEffect(() => {
    refreshBook();
  }, [refreshBook]);

  const checkInvalid = () => {
    var result = false;
    // 基本情報の確認
    if (!bookData.logi_id || !bookData.place_visit_id || !bookData.bookingdate || !bookData.start_time || !bookData.end_time || !bookData.bookingdate || !bookData.company_name || !bookData.phone_number || bookData.names.some(item => item === '' || item === null) || bookData.names.some(item => item[0] === '' || item[0] === null) || bookData.identity_document.some(item => item === "" || item === null) || !bookData.person_charge || !bookData.requirement || (bookData.requirement === '4' && !bookData.others_requirement)) {
      result = true;
    }
    for (var i = 0; i < bookData.names.length; i++) {
      if (bookData.identity_document[i] === "4" && !bookData.others_identity_document[i]) {
        result = true;
      }
    }
    return result;
  }

  const handleLogi = (value) => {
    setBookData({ ...bookData, logi_id: value, logi_name: logiList.find(x => x.logi_id === Number(value)) ? logiList.find(x => x.logi_id === Number(value)).logi_name : ''});
  }

  const handlePlacesVisit = (value) => {
    setBookData({ ...bookData, place_visit_id: value, place_visit_name: placesVisitList.find(x => x.place_visit_id === Number(value)) ? placesVisitList.find(x => x.place_visit_id === Number(value)).place_visit_name : ''});
  }

  const changeDate = (value) => {
    setBookData({ ...bookData, bookingdate: value });
  }

  const changeBook = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setBookData({ ...bookData, [name]: value });
  }

  const handleChange = (index, event) => {
    var newData = {};
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    if (name === 'identity_document') {
      const identity_document = [...bookData.identity_document];
      identity_document[index] = value;
      newData = { ...bookData, identity_document: identity_document };
    } else if (name === 'others_identity_document') {
      const others_identity_document = [...bookData.others_identity_document];
      others_identity_document[index] = value;
      newData = { ...bookData, others_identity_document: others_identity_document };
    } else if (name === 'card_number') {
      const card_number = [...bookData.card_number];
      card_number[index] = value;
      newData = { ...bookData, card_number: card_number };
    } else if (name === 'return_card') {
      const return_card = [...bookData.return_card];
      return_card[index] = value;
      newData = { ...bookData, return_card: return_card };
    } else if (name === 'bonded_area') {
      const bonded_area = [...bookData.bonded_area];
      bonded_area[index] = value;
      newData = { ...bookData, bonded_area: bonded_area };
    } else if (name === 'baggage_inspection') {
      const baggage_inspection = [...bookData.baggage_inspection];
      baggage_inspection[index] = value;
      newData = { ...bookData, baggage_inspection: baggage_inspection };
    } else if (name === 'physical_examination') {
      const physical_examination = [...bookData.physical_examination];
      physical_examination[index] = value;
      newData = { ...bookData, physical_examination: physical_examination };
    } else if (name === 'metal_detector') {
      const metal_detector = [...bookData.metal_detector];
      metal_detector[index] = value;
      newData = { ...bookData, metal_detector: metal_detector };
    }
    setBookData(newData);
  };

  const handleChange1 = (index, event) => {
    var newData = {};
    const identity_document = [...bookData.identity_document];
    identity_document[index] = event.target.value;
    newData = { ...bookData, identity_document: identity_document };
    setBookData(newData);
  };

  const handleChange2 = (index, event, name_id) => {
    var newData = {};
    const names = [...bookData.names];
    if (name_id) {
      names[index] = ([event.target.value, name_id]);
    }
    else {
      names[index] = event.target.value;
    }
    newData = { ...bookData, names: names };
    setBookData(newData);
  };

  const handleResetClick = () => {
    setBookData({
      book_id: '',
      logi_id: '',
      logi_name: '',
      place_visit_id: '',
      place_visit_name: '',
      bookingdate: '',
      start_time: '',
      end_time: '',
      names: [''], //氏名
      identity_document: [''], //本人確認書類
      card_number: [''], //カード番号
      return_card: [''], //返却
      bonded_area: [''], //保税エリア
      baggage_inspection: [''], //手荷物検査
      physical_examination: [''], //身体検査
      metal_detector: [''], //金属探知機
      others_identity_document: [''],
      company_name: '',
      phone_number: '',
      person_charge: '',
      requirement: '',
      vehicle_number: '', //車両番号
      parking_number: '', //駐車場番号
      remarks: '',
    })
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  const handleUploadClick = async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    const regex = /^\(?0[0-9\-()]{9,12}$/;
    if (!regex.test(bookData.phone_number)) {
      setAlertModalMessage('※正しい形式で電話番号を入力してください')
      setIsAlertModalOpen(true);
      return;
    }
    setConfirmModalMessage('登録しますか？');
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      try {
        var res;
        if (bookData.book_id) {
          res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard/${bookData.book_id}`, bookData, { headers: { Authorization: `Bearer ${jwt}` } });
        } else {
          res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard/`, bookData, { headers: { Authorization: `Bearer ${jwt}` } });
        }
        //console.log(res)
        if (res.data && res.data.result) {
          addToast(`登録しました`, { appearance: 'success', autoDismiss: true });
          history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}bookinglist`);
        } else {
          setAlertModalMessage(`登録に失敗しました\n${res.data && res.data.message ? res.data.message : ""}`)
          setIsAlertModalOpen(true);
        }
      } catch (error) {
        console.log(util.inspect(error))
        const err_message = error.response && error.response.data && error.response.data.message ? error.response.data.message : "";
        setAlertModalMessage(`登録に失敗しました\n${err_message}`)
        setIsAlertModalOpen(true);
      }
    });
  }

  const handleCancelClick = async () => {
    setConfirmModalMessage('キャンセルしますか？');
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      try {
        //トークンを取得
        const params = {
          site_id: props.match.params.siteId,
          status: constClass.STATUS.CCL,
          upd_name: props.user.userId
        };
        const jwt = localStorage.getItem('jwt');
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard_status/${book_id}`, params, { headers: { Authorization: `Bearer ${jwt}` } });
        addToast('ステータスを更新しました。', { appearance: 'success', autoDismiss: true });
        history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}bookinglist`);
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      }
    });
  }
  
  const addNameInput = () => {
    setBookData(prevState => ({
      ...prevState,
      names: [...prevState.names, ''],
      identity_document: [...prevState.identity_document, ''],
      others_identity_document: [...prevState.others_identity_document, ''],
      card_number: [...prevState.card_number, ''],
      return_card: [...prevState.return_card, ''],
      bonded_area: [...prevState.bonded_area, ''],
      baggage_inspection: [...prevState.baggage_inspection, ''],
      physical_examination: [...prevState.physical_examination, ''],
      metal_detector: [...prevState.metal_detector, ''],
    }));
  };

  const removeNameInput = index => {
    setBookData(prevState => ({
      ...prevState,
      names: prevState.names.filter((_, i) => i !== index),
      identity_document: prevState.identity_document.filter((_, i) => i !== index),
      others_identity_document: prevState.others_identity_document.filter((_, i) => i !== index),
      card_number: prevState.card_number.filter((_, i) => i !== index),
      return_card: prevState.return_card.filter((_, i) => i !== index),
      bonded_area: prevState.bonded_area.filter((_, i) => i !== index),
      baggage_inspection: prevState.baggage_inspection.filter((_, i) => i !== index),
      physical_examination: prevState.physical_examination.filter((_, i) => i !== index),
      metal_detector: prevState.metal_detector.filter((_, i) => i !== index),
    }));
  };

  const renderList = () => {
    return (
      <div className="container">
        <div className='row mb-3'>
          <div className="col-2">
            <div className="col text-green font-weight-bold text-center">
              {/* <button className='btn btn-active px-5'
                  onClick={() 
                  => searchBin()}>新規登録</button> */}
              <Link className="nav-link btn btn-active px-3 text-nowrap text-center" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}bookinglist`}>戻る</Link>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center">
              予約ID
            </div>
          </div>
          <div className="col-3 align-items-center justify-content-center">
            <div className="col-sm-12 csv-input text-left">
              {bookData.book_id ? bookData.book_id : "新規"}
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>施設
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <select className="custom-select w-100 text-center" aria-label="施設"
                value={bookData.logi_id || ''}
                onChange={(e) => handleLogi(e.target.value)}
                disabled={bookData.status === constClass.STATUS.CCL}
              >
                <option value="" >- 選択 -</option>
                {logiList.map((item, idx) =>
                  <option key={idx} value={item.logi_id}>{item.logi_name}</option>
                )}
              </select>
            </div>
          </div>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>訪問先
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <select className="custom-select w-100" aria-label="訪問先"
                value={bookData.place_visit_id || ''}
                onChange={(e) => handlePlacesVisit(e.target.value)}
                disabled={bookData.status === constClass.STATUS.CCL || !bookData.logi_id}
              >
                <option value="">選択してください</option>
                {bookData.logi_id && placesVisitList.filter((visit) => visit.logi_id === Number(bookData.logi_id)).map((item) =>
                  <option key={item.place_visit_id} value={item.place_visit_id}>{item.place_visit_name}</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>日付
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <DatePicker className={`form-control`} aria_label="日付" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="bookingDate" name="bookingDate"
                selected={(!bookData.bookingdate || bookData.bookingdate === '') ? null : moment(bookData.bookingdate, 'YYYY-MM-DD').toDate()}
                minDate={new Date()}
                onChange={date => changeDate(date ? moment(date).format('YYYY-MM-DD') : null)}
                placeholderText="- 選択 -"
                disabledKeyboardNavigation
                disabled={bookData.status === constClass.STATUS.CCL}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>時間帯
            </div>
          </div>
          <div className="col-2">
            <div className="col-sm-12 csv-input">
              <select
                className="custom-select w-100 text-center"
                aria-label="開始時間"
                value={bookData.start_time || ''}
                onChange={e => changeBook(e)}
                name='start_time'
                disabled={bookData.status === constClass.STATUS.CCL}
              >
                {/* Number(reservation.end_time.substring(0, 2)) <= hour */}
                <option value="">- 選択 -</option>
                {[...Array(25).keys()].map((hour) => {
                  return (
                    <React.Fragment key={hour}>
                      {hour !== 24 &&
                        <option value={`${hour.toString().padStart(2, '0')}:00`}>
                          {`${hour.toString().padStart(2, '0')}時00分`}
                        </option>
                      }
                      {hour !== 24 &&
                        <option value={`${hour.toString().padStart(2, '0')}:30`}>
                          {`${hour.toString().padStart(2, '0')}時30分`}
                        </option>
                      }
                      {hour === 24 &&
                        <option value={`00:00`}>
                          {`00時00分`}
                        </option>
                      }
                    </React.Fragment>
                  );
                })}
              </select>
            </div>
          </div>
          ～
          <div className="col-2">
            <div className="col-sm-12 csv-input">
              {/* <input type="text" className="form-control" id={"end_time"} name={"end_time"} value={bookData.end_time} onChange={e => changeBook(e)}></input> */}
              <select
                className="custom-select w-100 text-center"
                aria-label="終了時間"
                value={bookData.end_time || ''}
                onChange={e => changeBook(e)}
                name='end_time'
                disabled={bookData.status === constClass.STATUS.CCL}
              >
                <option value="">- 選択 -</option>
                {[...Array(25).keys()].map((hour) => {
                  const sysdate = new Date();
                  if (bookData.start_time && Number(bookData.start_time.substring(0, 2)) <= hour) {
                    if (Number(bookData.start_time.substring(0, 2)) === hour && Number(bookData.start_time.substring(3, 5)) === 30) {
                      return null;
                    }
                    else {
                      return (
                        <React.Fragment key={hour}>
                          {Number(bookData.start_time.substring(0, 2)) !== hour && hour !== 24 &&
                            <option value={`${hour.toString().padStart(2, '0')}:00`}>
                              {`${hour.toString().padStart(2, '0')}時00分`}
                            </option>
                          }
                          {hour !== 24 &&
                            <option value={`${hour.toString().padStart(2, '0')}:30`}>
                              {`${hour.toString().padStart(2, '0')}時30分`}
                            </option>
                          }
                          {hour === 24 &&
                            <option value={`00:00`}>
                              {`00時00分`}
                            </option>
                          }
                        </React.Fragment>
                      );
                    }
                  } else if (!bookData.start_time) {
                    if (bookData.bookingdate === moment(sysdate).format("YYYY-MM-DD")) {
                      return (
                        <React.Fragment key={hour}>
                          {sysdate.getHours() === hour && sysdate.getMinutes() <= 30 && (
                            <option value={`${hour.toString().padStart(2, '0')}:30`}>
                              {`${hour.toString().padStart(2, '0')}時30分`}
                            </option>
                          )}
                          {sysdate.getHours() < hour && (
                            <>
                              <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                {`${hour.toString().padStart(2, '0')}時00分`}
                              </option>
                              {hour !== 24 &&
                                <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                  {`${hour.toString().padStart(2, '0')}時30分`}
                                </option>
                              }
                              {hour === 24 &&
                                <option value={`00:00`}>
                                  {`00時00分`}
                                </option>
                              }
                            </>
                          )}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={hour}>
                          <option value={`${hour.toString().padStart(2, '0')}:00`}>
                            {`${hour.toString().padStart(2, '0')}時00分`}
                          </option>
                          {hour !== 24 &&
                            <option value={`${hour.toString().padStart(2, '0')}:30`}>
                              {`${hour.toString().padStart(2, '0')}時30分`}
                            </option>
                          }
                          {hour === 24 &&
                            <option value={`00:00`}>
                              {`00時00分`}
                            </option>
                          }
                        </React.Fragment>
                      );
                    }
                  } else {
                    return null;
                  }
                  //}
                })}
              </select>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>会社名
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <input type="text" className="form-control" id={"company_name"} name={"company_name"} value={bookData.company_name} onChange={e => changeBook(e)} disabled={bookData.status === constClass.STATUS.CCL}></input>
            </div>
          </div>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>電話番号
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <input type="text" className="form-control" id={"phone_number"} name={"phone_number"} value={bookData.phone_number} onChange={e => changeBook(e)} disabled={bookData.status === constClass.STATUS.CCL}></input>
            </div>
          </div>
        </div>
        {/* <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              氏名
            </div>
          </div> */}
        {bookData.names.map((name, index) => (
          <div key={index} className="bg-logigreen pt-2 pb-1 mb-2">
            <div className='row mb-2'>
              <div className="col-2">
                <div className="text-right pt-2 text-nowrap">
                  <span className="text-red">※</span>氏名
                </div>
              </div>
              <div className="col-3">
                <div className="col-sm-12 csv-input">
                  <input
                    type="text"
                    name="names"
                    className="form-control"
                    value={Array.isArray(name) ? name[0] : name} // 名前の部分だけ表示
                    onChange={e => handleChange2(index, e, Array.isArray(name) ? name[1] : "")}
                    placeholder={`氏名 ${index + 1}`}
                    disabled={bookData.status === constClass.STATUS.CCL}
                  />
                </div>
              </div>
              <div className="col-1">
                <div className="d-flex align-items-center justify-content-center pt-2 text-nowrap">
                  <span className="text-red">※</span>本人確認書類
                </div>
              </div>
              <div className="col-5 mx-1 form-check-inline">
                {constClass.LICENSE.map((identity_document, idx) => (
                  identity_document !== '2' && identity_document !== '4' && (
                    <div key={idx} className={`custom-control custom-radio`}>
                      <input type="radio" className="custom-control-input" id={`identity_document_${identity_document}_${index}`} onChange={e => handleChange1(index, e)} value={identity_document} checked={bookData.identity_document && identity_document === bookData.identity_document[index]} disabled={bookData.status === constClass.STATUS.CCL} />
                      <label className="custom-control-label text-nowrap" htmlFor={`identity_document_${identity_document}_${index}`}>{constClass.LICENSE_NAME[identity_document]}</label>
                    </div>
                  )
                ))}
              </div>
            </div>
            <div className='row mb-2'>
              <div className="col-6"></div>
              {constClass.LICENSE.map((identity_document, idx) => (
                identity_document === '4' && (
                  <div key={idx} className={`custom-control custom-radio mt-1`}>
                    <input type="radio" className="custom-control-input" id={`identity_document_${identity_document}_${index}`} onChange={e => handleChange1(index, e)} value={identity_document} checked={bookData.identity_document && identity_document === bookData.identity_document[index]} disabled={bookData.status === constClass.STATUS.CCL} />
                    <label className="custom-control-label text-nowrap" htmlFor={`identity_document_${identity_document}_${index}`}>{constClass.LICENSE_NAME[identity_document]}</label>
                  </div>
                )
              ))}
              {bookData.identity_document && bookData.identity_document[index] === '4' &&
                <input className="form-control mb-1 ml-1 col-4" name="others_identity_document" value={bookData.others_identity_document[index]} onChange={e => handleChange(index, e)} disabled={bookData.status === constClass.STATUS.CCL}></input>
              }
            </div>
            {bookData.book_id &&
              <div className='row mx-1 mb-2'>
                <div className='col-5'></div>
                <div className="col-1 d-flex align-items-center justify-content-center pt-2 text-nowrap">
                  カードNO.
                </div>
                <div className="col-2 text-left">
                  <input
                    type="text"
                    name="card_number"
                    className="form-control"
                    value={bookData.card_number[index] || ''}
                    onChange={e => handleChange(index, e)}
                    disabled={bookData.status === constClass.STATUS.CCL}
                  />
                </div>
                <div className='col-3 text-left'>
                  <div className="custom-control custom-checkbox form-check-reverse">
                    <input type="checkbox" className="custom-control-input" id={`return_card_${index}`} name="return_card" value="true" checked={bookData.return_card[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookData.status === constClass.STATUS.CCL}/>
                    <label className="custom-control-label m-1 text-danger text-nowrap" htmlFor={`return_card_${index}`}>カードは返却しましたか？</label>
                  </div>
                </div>
              </div>
            }
            {bookData.book_id &&
              <div className='row mx-1 mb-2'>
                <div className='col-5'></div>
                <div className="col-1">
                  <div className="custom-control custom-checkbox form-check-reverse d-flex align-items-center justify-content-center pt-2 text-nowrap">
                    <input type="checkbox" className="custom-control-input" id={`bonded_area_${index}`} name="bonded_area" value="true" checked={bookData.bonded_area[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookData.status === constClass.STATUS.CCL}/>
                    <label className="custom-control-label m-1 text-right text-nowrap" htmlFor={`bonded_area_${index}`}>保税エリア</label>
                  </div>
                </div>
                <div className="col-3 text-left text-danger d-flex align-items-center justify-content-center pt-2 text-nowrap">
                  検査は実施しましたか？
                </div>
              </div>
            }
            {bookData.book_id &&
              <div className='col-12 row mx-1 mb-1'>
                <div className='col-8 ml-4 text-right'>
                  <div className="custom-control custom-checkbox form-check-reverse">
                    <input type="checkbox" className="custom-control-input" id={`baggage_inspection_${index}`} name="baggage_inspection" value="true" checked={bookData.baggage_inspection[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookData.status === constClass.STATUS.CCL}/>
                    <label className="custom-control-label m-1 text-nowrap" htmlFor={`baggage_inspection_${index}`}>手荷物検査</label>
                  </div>
                </div>
              </div>
            }
            {bookData.book_id &&
              <div className='col-12 row mx-1 mb-1'>
                <div className='col-8 ml-4 text-right'>
                  <div className="custom-control custom-checkbox form-check-reverse">
                    <input type="checkbox" className="custom-control-input" id={`physical_examination_${index}`} name="physical_examination" value="true" checked={bookData.physical_examination[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookData.status === constClass.STATUS.CCL}/>
                    <label className="custom-control-label m-1 text-nowrap" htmlFor={`physical_examination_${index}`}>身体検査　</label>
                  </div>
                </div>
              </div>
            }
            {bookData.book_id &&
              <div className='col-12 row mx-1 mb-1'>
                <div className='col-8 ml-4 text-right'>
                  <div className="custom-control custom-checkbox form-check-reverse">
                    <input type="checkbox" className="custom-control-input" id={`metal_detector_${index}`} name="metal_detector" value="true" checked={bookData.metal_detector[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookData.status === constClass.STATUS.CCL}/>
                    <label className="custom-control-label m-1 text-nowrap" htmlFor={`metal_detector_${index}`}>金属探知機</label>
                  </div>
                </div>
              </div>
            }

            <div className='row mx-1 mb-3 d-flex align-items-center justify-content-center'>
              <button className="btn btn-primary" onClick={addNameInput} disabled={bookData.status === constClass.STATUS.CCL}>追加</button>
              {index !== 0 &&
                <div className="col-auto">
                  <button
                    className="btn btn-danger"
                    onClick={() => removeNameInput(index)}
                    disabled={bookData.status === constClass.STATUS.CCL}
                  >
                    削除
                  </button>
                </div>
              }
            </div>
          </div>
        ))}
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>訪問先担当者名
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <input type="text" className="form-control" id={"person_charge"} name={"person_charge"} value={bookData.person_charge} onChange={e => changeBook(e)} disabled={bookData.status === constClass.STATUS.CCL}></input>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              <span className="text-red">※</span>用件
            </div>
          </div>
          <div className="row mx-0 px-3-env text-left form-group">
            {constClass.REQUIREMENT.map((requirement, idx) => (
              <div key={idx} className={`custom-control custom-radio`}>
                <input type="radio" className="custom-control-input" id={`requirement_${requirement}`} name="requirement" onChange={e => changeBook(e)} value={requirement} checked={bookData.requirement === requirement} disabled={bookData.status === constClass.STATUS.CCL}/>
                <label className="custom-control-label text-nowrap" htmlFor={`requirement_${requirement}`}>{constClass.REQUIREMENT_NAME[requirement]}</label>
              </div>
            ))}
          </div>
          {bookData.requirement === '4' && <input className="form-control mb-1 ml-1 col-4" name="others_requirement" value={bookData.others_requirement} onChange={e => changeBook(e)} disabled={bookData.status === constClass.STATUS.CCL}></input>}
        </div>
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              車両番号
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <input type="text" className="form-control" id={"vehicle_number"} name={"vehicle_number"} value={bookData.vehicle_number} onChange={e => changeBook(e)} disabled={bookData.status === constClass.STATUS.CCL}></input>
            </div>
          </div>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              駐車場No.
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <input type="text" className="form-control" id={"parking_number"} name={"parking_number"} value={bookData.parking_number} onChange={e => changeBook(e)} disabled={bookData.status === constClass.STATUS.CCL}></input>
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center pt-2">
              備考
            </div>
          </div>
          <div className="col-3">
            <div className="col-sm-12 csv-input">
              <input type="text" className="form-control" id={"remarks"} name={"remarks"} value={bookData.remarks} onChange={e => changeBook(e)} disabled={bookData.status === constClass.STATUS.CCL}></input>
            </div>
          </div>
        </div>
        <div className='row mx-1 mb-3 pb-3 d-flex align-items-center justify-content-center'>
          <button className="btn btn-primary" disabled={checkInvalid() || bookData.status === constClass.STATUS.CCL}  onClick={handleUploadClick}>登録</button>
          <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal}/>
          <UseAlertModal isOpen={isAlertModalOpen} message={alertModalMessage} onClose={closeAlertModal}/>
          <div className="col-auto">
          <button className="btn btn-primary" onClick={handleResetClick} disabled={bookData.status === constClass.STATUS.CCL}>リセット</button>
          </div>
          <button className="btn btn-danger" disabled={!bookData.book_id || bookData.status === constClass.STATUS.CCL} onClick={handleCancelClick}>キャンセル</button>
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(props.user)}</div></div>
      {renderList()}
    </div>
  )
}

export default Booking;