import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import util from 'util';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import BookingDetailView from './BookingDetailView';
import CommonTrain from '../Common/common_train';

class BookingCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      disabled: false,
      nowDate: moment(),
      siteId: this.props.siteId,
      book_id: this.props.current_bookId ? this.props.current_bookId : null,
      book_date: null,
      booked: null,
      cancel: null,
      stationMaster: []
    }
    this.refreshCancelBookData = this.refreshCancelBookData.bind(this);
  }

  componentDidMount() {
    this.refreshCancelBookData();
    this.disableBounceScroll();
  }
  // async componentWillUnmount() {
  //   // clearInterval(this.intervalCount);
  // }
  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        el = el.parentElement;
      }

      if (noScroll) {
        e.preventDefault();
      }

      touchY = moveY;
    }, { passive: false });
  }

  async refreshCancelBookData() {
    const book_id = this.props.current_bookId ? this.props.current_bookId : null;
    const params = {
      site_id: this.state.siteId,
      line_id: this.props.liff_access_token,
      book_id: book_id,
    }
    //console.log(params)
    const bookingPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/search_detail/`, params);
    const bookingList = (await bookingPromise).data;
    var notcancel = [];
    var canceled = [];
    var book = [];
    //キャンセルできないデータが含まれているか
    await Promise.all(bookingList.map(async item => {
      if (constClass.STATUS_PRE.includes(item.status)) {
        book.push(item);
      } else
        if (constClass.STATUS_CCL.includes(item.status)) {
          canceled.push(item);
        } else {
          notcancel.push(item);
        }
    }));
    if (!book_id || book.length !== bookingList.length) {
      alert((canceled.length === bookingList.length) ? 'キャンセル済のご予約が指定されています' : 'キャンセルできないご予約が指定されています');
      this.props.setCurrentBook(null, null);
      this.props.history.replace(`${constClass.CYCLETRAIN_LINKURL}/?page=${constClass.HISTORY}`);
      await this.props.refreshBookData();
    }
    const before_total = CommonTrain.getTotal(bookingList);

    this.setState({
      booked: bookingList,
      cancel: book,
      before_total,
    });
    this.setReady();
  }
  setReady() {
    if (this.props.settings !== null && this.state.cancel !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }
  async cancelSubmit() {
    var result = { data: null };
    if (!window.confirm('本当にキャンセルしますか？この操作は取り消しできません')) {
      return;
    }
    this.setState({ disabled: true });
    try {
      //キャンセル
      const params = {
        line_id: this.props.liff_access_token,
        site_id: this.props.siteId,
        book_id: this.state.book_id,
        status: constClass.STATUS.CCL
      }
      result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/train_booking/status/${params.book_id}`, params));

      if (result.data && result.data.result) {
        alert('キャンセルしました。');
        this.props.setCurrentBook(null, null);
        this.props.history.replace(`${constClass.CYCLETRAIN_LINKURL}/?page=${constClass.HISTORY}`);
        await this.props.refreshBookData();
      } else {
        //err
        console.log("'キャンセル失敗", util.inspect(result))
        alert('キャンセルに失敗しました。');
      }
    } catch (error) {
      console.log(util.inspect(error))
      alert('キャンセルに失敗しました。');
    }
    this.setState({ disabled: false });
  }


  renderCancelConfirm() {
    return (
      <div id="bookinputcheck">
        <div className='m-0 p-0'>
          <div className="row mx-0 my-2 px-3-env py-2 bg-green text-white">
            <div className='col font-weight-bold text-center'>下記の予約情報をキャンセルします。</div>
          </div>
          <div className='row m-0 p-0'>
            <div className='col m-0 p-0'>
              <BookingDetailView
                state={{ booking: this.state.cancel[0], booking_detail: this.state.cancel, booked: null }}
                cancel_view={true}
                settings={this.props.settings}
              />
            </div>
          </div>
          <div className="row mx-0 mt-3 py-2 pb-0 bg-white">
            <div className="col py-2 font-weight-bold text-green text-right">
              合計
            </div>
            <div className="col-auto py-2 font-weight-bold text-green text-right">
              {this.state.before_total.toLocaleString()}円<br />
            </div>
          </div>
        </div>
        <div className="row mx-0 pt-2 pb-3">
          <div className="col font-weight-bold text-center">
            <button
              className={`btn btn-submit py-2 px-1 w-75`}
              disabled={this.state.disabled}
              onClick={() => this.cancelSubmit()}>
              キャンセル
              { }
            </button>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      this.state.ready ? <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0">
          {this.renderCancelConfirm()}
        </div>
        {this.state.disabled && <Loading />}
      </div > : <Loading />
    )
  }
}

export default withRouter(BookingCancel);