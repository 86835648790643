import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import util from 'util';
import constClass from '../../Constants/Constants';
// import queryString from 'query-string';
import Loading from '../Loading/Loading';
import CommonTrain from '../Common/common_train';
import ArrowRight from '../Images/arrow_right.svg';
import QRCodeScanner from '../Common/QRCodeScanner';
import Modal from 'react-modal';
import CloseImg from '../Images/close.svg';
import Common from '../Common/common';

Modal.setAppElement("#root");

const BookingQRRead = ({ page, siteId, lineId, book_id, book_no, station_cd, settings, getRidedata, submitCurrentbooking }) => {
  const history = useHistory();
  const [stationMaster, setStationMaster] = useState([])
  const [rideData, setRideData] = useState(null)
  const [rideStation, setRideStation] = useState({ station_cd: null, chk: false })
  const [nowDate, setNowDate] = useState(null);
  const [fareList, setFareList] = useState({ fare: 0, fare_bringin: 0, fare_adult1: 0, fare_child1: 0, fare_adult2: 0, fare_child2: 0 });
  const [values, setValues] = useState({
    station_cd: null,
    book_date: null,
    ride: null,
    ride_ckeck: false,
    // ride_error: false,
    stationMaster: []
  });
  const [scanning, setScanning] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [scanMessage, setScanMessage] = useState("");
  const [rideError, setRideError] = useState(false);

  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '0.5rem',
      right: '0.5rem',
      top: '10vw',
      bottom: 'auto',
      marginTop: '0.5rem',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto"
    }
  };

  const refreshData = useCallback(async () => {
    //console.log("refreshData")
    const params = {
      site_id: siteId,
      line_id: lineId,
      book_date: new Date()
    }
    const stationPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_station/list/`, params);
    const bookingPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/search_detail/`, params);
    const detail_lst = (await bookingPromise).data;
    //対象データ取得 (乗車予定データ（QRが有ればQR優先）)
    var ride = await getRidedata(detail_lst, station_cd);
    //対象データ取得 (画面遷移時に指定した乗車データ)
    var ride2 = (book_id && book_no) ? detail_lst.find(x => x.book_id === book_id && x.book_no === book_no) : null;
    var ride_error = false;
    if (!ride && !ride2) {
      // どちらも対象が無ければエラー
      ride_error = true;
    } else if (ride2 && (!ride || ride.status !== constClass.STATUS.RDE)) {
      // 乗車中・乗車予定どちらも無ければ画面遷移時のデータを表示
      ride = ride2;
    }
    var fare = ride ? ride.fare : 0;
    var fare_bringin = ride ? (parseInt(ride.count_bringin) * parseInt(settings.TRAIN_FARE_BRINGIN)).toLocaleString() : 0;
    var fare_adult1 = ride && ride.book_type === constClass.TRAIN_BOOK_TYPE.ALL && ride.count_adult1 > 0 ? CommonTrain.calcFare(ride.fare, { count_adult1: ride.count_adult1 }) : 0;
    var fare_child1 = ride && ride.book_type === constClass.TRAIN_BOOK_TYPE.ALL && ride.count_child1 > 0 ? CommonTrain.calcFare(ride.fare, { count_child1: ride.count_child1 }) : 0;
    var fare_adult2 = ride && ride.book_type === constClass.TRAIN_BOOK_TYPE.ALL && ride.count_adult2 > 0 ? CommonTrain.calcFare(ride.fare, { count_adult2: ride.count_adult2 }) : 0;
    var fare_child2 = ride && ride.book_type === constClass.TRAIN_BOOK_TYPE.ALL && ride.count_child2 > 0 ? CommonTrain.calcFare(ride.fare, { count_child2: ride.count_child2 }) : 0;
    const stationMaster = (await stationPromise).data;
    setStationMaster(stationMaster);
    setRideData({
      ...ride,
      view_ride_time: ride ? CommonTrain.strTotime(ride.ride_time) : null,
      view_book_date_ja: ride ? moment(ride.book_date).format('YYYY年MM月DD日') : null,
      car_no_list_str: ride ? ride.train_booking_detail_car.sort((a, b) => a.car_no - b.car_no).map((item) => (`${item.car_no}号車 乗車${item.count}台`)).join("/") : null
    });
    setFareList({
      fare,
      fare_bringin,
      fare_adult1,
      fare_child1,
      fare_adult2,
      fare_child2
    });
    setNowDate(moment().format('HH:mm:ss.SS'));
    setValues({
      // ride_error: ride_error,
      book_date: ride ? ride.book_date : null,
      book_id: book_id,
      book_no: book_no,
      station_cd: station_cd,
      status: null,
      ride_ckeck: (page === constClass.RIDETICKET || page === constClass.GETOFFTICKET) ? true : false,
    });
    setRideError(ride_error);
    // 二次元コードを読み取っていれば乗車処理を実行（state更新のためuseEffectにて実行）
    if (!ride_error && station_cd !== null && station_cd !== undefined && page === constClass.RIDEQR) {
      setRideStation({ station_cd: station_cd, chk: false });
    }
  }, [siteId, lineId, book_id, book_no, station_cd, page, getRidedata, settings.TRAIN_FARE_BRINGIN]);

  const checkReady = useCallback(() => {
    return (siteId !== null && siteId !== undefined) && lineId && (rideData || rideError);
  }, [siteId, lineId, rideData, rideError]);

  useEffect(() => {
    async function fetchData() {
      await refreshData();
    }
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    var intervalId;
    if ([constClass.RIDETICKET, constClass.GETOFFTICKET].includes(page)) {
      intervalId = setInterval(() => {
        const date = moment().format('HH:mm:ss.SS');
        setNowDate(date);
      }, 100);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [page]);

  const viewTicket = useCallback((status, id = null, no = null) => {
    submitCurrentbooking(id, no, (status === constClass.STATUS.RDE ? constClass.RIDETICKET : constClass.GETOFFTICKET));
    // history.push(`${constClass.CYCLETRAIN_LINKURL}/?page=${status === constClass.STATUS.RDE ? constClass.RIDETICKET : constClass.GETOFFTICKET}`);
    // clearInterval(timer);
    // setValues({
    //   ...values,
    //   ride_ckeck: true
    // });
    // console.log("settimer")
    // setTimer(setInterval(() => {
    //   const date = moment().format('HH:mm:ss.SS');
    //   setNowDate(date);
    // }, 100));
  }, [submitCurrentbooking]);

  //乗降処理
  const submitRide = useCallback(async (status, station_cd, ride) => {
    //console.log("submitRide")
    try {
      var data = {
        site_id: siteId,
        line_id: lineId,
        book_id: rideData.book_id,
        book_no: rideData.book_no,
        status: status,
        detail: ride,
      }
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/train_booking/status/${data.book_id}/${data.book_no}/`, data);

      setRideData({ ...rideData, status: status })
      setValues({
        station_cd,
        ride: { ...ride, status: status },
      });
      viewTicket(status, rideData.book_id, rideData.book_no);
    } catch (error) {
      console.log(util.inspect(error));
      if (error.response.data && error.response.data.msg) {
        alert('エラーが発生しました。\n' + error.response.data.msg);
      }
    }
  }, [siteId, lineId, rideData, viewTicket]);

  const handleRide = useCallback(async (qr_station_cd, chk) => {
    //console.log("handleRide")
    var change_getoff_sta_cd = null;
    var change_getoff_sta_name = null;
    var change_fare = null;
    var change_total = 0;
    var status = rideData.status;
    if (status !== constClass.STATUS.RDE && rideData.ride_station_cd !== qr_station_cd) {
      if (chk) {
        alert("乗車駅が予約した駅と違います。予約を変更またはキャンセル後再登録してください。");
      } else {
        // submitCurrentbooking(null, null, null);
        // history.replace(`${constClass.CYCLETRAIN_LINKURL}/`);
        setRideError(true);
      }
      return;
    }
    if (status === constClass.STATUS.RDE && rideData.getoff_station_cd !== qr_station_cd) {
      if (rideData.ride_station_cd === qr_station_cd) {
        //alert(`乗車済の駅が指定されました。乗車券を表示します`);
        setRideStation({ station_cd: null, chk: false });
        viewTicket(status, rideData.book_id, rideData.book_no);
        return;
      }
      change_getoff_sta_cd = qr_station_cd;
      change_getoff_sta_name = stationMaster.find(x => x.station_cd === change_getoff_sta_cd).station_name;
      change_fare = await CommonTrain.getFare(rideData.book_date, rideData.ride_station_cd, change_getoff_sta_cd);
      change_total = CommonTrain.calcTotal(change_fare, settings.TRAIN_FARE_BRINGIN, rideData);
      //console.log(rideData.fare, change_fare, change_total)
      if (!window.confirm(`予約した${rideData.getoff_station_name}駅と違う${change_getoff_sta_name}駅で降車しようとしています。降車しますか？${change_total > rideData.total ? `\n※金額が${rideData.total.toLocaleString()} 円 から ${change_total.toLocaleString()}円 に変更になります。\n駅窓口にお申し出ください` : ""}`)) {
        setRideStation({ station_cd: null, chk: false });
        return;
      } else {
        status = constClass.STATUS.FIN;
      }
    } else {
      if (!window.confirm(`${rideData.status === constClass.STATUS.RDE ? `${rideData.getoff_station_name}で降車` : `${rideData.ride_station_name}から乗車`} します。よろしいでしょうか？`)) {
        setRideStation({ station_cd: null, chk: false });
        return;
      } else {
        status = rideData.status === constClass.STATUS.RDE ? constClass.STATUS.FIN : constClass.STATUS.RDE;
      }
    }

    //ステータス変更処理
    submitRide(status, qr_station_cd,
      {
        ...rideData,
        change_getoff_sta_cd: change_getoff_sta_cd,
        change_getoff_sta_name: change_getoff_sta_cd ? stationMaster.find(x => x.station_cd === change_getoff_sta_cd).station_name : null,
        change_fare
      });
    setRideStation({ station_cd: null, chk: false });
  }, [rideData, settings.TRAIN_FARE_BRINGIN, stationMaster, submitRide, viewTicket]);

  useEffect(() => {
    if (rideStation.station_cd) {
      handleRide(rideStation.station_cd, rideStation.chk);
    }
  }, [rideStation, handleRide]);

  const scanQR = () => {
    // if (window.liff.isInClient() && window.liff.isLoggedIn()) {
    //   try {
    //     alert('===111');
    //     const result = window.liff.scanCodeV2()
    //       .then(result => {
    //         alert('===222');
    //         const qrstring = (result && result.value) ? result.value.split('?') : [''];
    //         const qs = queryString.parse(qrstring.length > 1 ? qrstring[1] : qrstring[0]);
    //         if (!("cd" in qs)) {
    //           alert("読み込みに失敗しました。再度読み込みをお願いします")
    //         } else {
    //           alert('===333');
    //           handleRide(qs.cd, true);
    //           alert('===444');
    //         }
    //       })
    //       .catch(error => {
    //         alert('===555');
    //         console.log(error);
    //         alert("読み込みに失敗しました。再度読み込みをお願いします");
    //         alert(error);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //     alert("読み込みに失敗しました。再度読み込みをお願いします");
    //     alert(error);
    //   }
    // } else {
    //   if (rideStation.station_cd) {
    //     handleRide(rideStation.station_cd, true);
    //   } else {
    //     alert("QR読み込みが起動できませんでした。駅改札にお申し出ください。");
    //   }
    // }
    setScanMessage("");
    setScanning(true);
  }

  // const scanQrExternal = () => {
  //   if (window.liff.isInClient()) {
  //     // var params = {
  //     //   url: `${process.env.REACT_APP_FRONTEND_URL}/cycletrain/qr`,
  //     //   external: true,
  //     // };
  //     // window.liff.openWindow(params);
  //     window.open(`${process.env.REACT_APP_FRONTEND_URL}/cycletrain/qr?openExternalBrowser=1&timestamp=${moment().format('YYYYMMDDHHmmSS')}}`, '_blank');
  //   } else {
  //     history.push('/cycletrain/qr');
  //   }
  // }

  useEffect(() => {
    if (scanning && qrCodeData) {
      try {
        // const qrstring = qrCodeData.split('?');
        // const qs = queryString.parse(qrstring.length > 1 ? qrstring[1] : qrstring[0]);
        // if (!("cd" in qs)) {
        //   setScanMessage("読み込みに失敗しました。再度読み込みをお願いします。")
        // } else {
        //   setScanning(false);
        //   setQrCodeData("");
        //   setScanMessage("");
        //   handleRide(qs.cd, true);
        // }
        const cd = Common.getCdFromQrString(qrCodeData);
        if (cd) {
          setScanning(false);
          setQrCodeData("");
          setScanMessage("");
          handleRide(cd, true);
        } else {
          setScanMessage("読み取りに失敗しました。再度読み取りをお願いします。");
        }
      } catch (error) {
        console.log(error);
        setScanMessage("読み取りに失敗しました。再度読み取りをお願いします。");
        // alert(error);
      }
    }
  }, [scanning, qrCodeData, handleRide]);

  const renderTicket = () => {
    const ticket_css = {
      "backgroundColor": "#FFF",
      "backgroundImage": `url("/${siteId}/ticket_bg.png") `,
    }
    const maru_css = {
      "display": "inline-block",
      "border": "1px solid #000",
      "borderRadius": "50%",
      "width": "16px",
      "height": "16px",
      "lineHeight": "12px",
      "textAlign": "center",
      "fontSize": "12px",
    }
    return (

      <React.Fragment>
        {rideData.status === constClass.STATUS.FIN &&
          <div className="row mb-3">
            <div className='col-12 py-3 bg-green text-white text-center'>
              この画面を改札で提示して、<br />
              固定ベルトをお返しください。
            </div>
          </div>}
        {rideData.status === constClass.STATUS.RDE &&
          <div className="row mb-3">
            <div className='col-12 py-3 bg-green text-white text-center'>
              この画面を改札で提示して、<br />
              固定ベルトをお受け取りください。
            </div>
          </div>}
        <div className="row mb-2 bg-white py-3">
          <div className="col-12">
            <h4 className='py-3 text-center text-green'>{nowDate}</h4>
            <div className='row'><div className='col-auto px-1 mx-1 text-green'>[発券番号]</div><div className='col px-1 mx-1'>{rideData.view_book_no}</div></div>
            <div className='row'><div className='col-auto px-1 mx-1 text-green'>[乗車日付]</div><div className='col px-1 mx-1'>{rideData.view_book_date_ja}</div></div>
            <div className='row'><div className='col-auto px-1 mx-1 text-green'>[乗車駅・時刻]</div><div className='col px-1 mx-1'>{rideData.ride_station_name} {rideData.view_ride_time}発</div></div>
            <div className='row'><div className='col-auto px-1 mx-1 text-green'>[降車駅]</div><div className='col px-1 mx-1'>{rideData.getoff_station_name}</div></div>
            <div className='row'><div className='col-auto px-1 mx-1 text-green'>[号車・台数]</div><div className='col px-1 mx-1'>{rideData.car_no_list_str}</div></div>
            <div className='row'><div className='col-auto px-1 mx-1 text-green'>[持ち込み料]</div><div className='col px-1 mx-1'>{fareList.fare_bringin} 円</div></div>
            {(settings[`LP_STATIONMAP_${rideData.ride_station_cd}`] || settings[`LP_STATIONMAP_${rideData.getoff_station_cd}`]) &&
              <div className='row'><div className='col-auto px-1 mx-1 text-green'>[構内図]</div>
                {(rideData.status === constClass.STATUS.RDE && settings[`LP_STATIONMAP_${rideData.ride_station_cd}`]) && <div className='col-auto px-1 mx-1 text-center'><a href={Common.getExternalUrl(settings[`LP_STATIONMAP_${rideData.ride_station_cd}`])} target="_blank" rel="noreferrer">乗車駅</a></div>}
                {settings[`LP_STATIONMAP_${rideData.getoff_station_cd}`] && <div className='col-auto px-1 mx-1 text-center'><a href={Common.getExternalUrl(settings[`LP_STATIONMAP_${rideData.getoff_station_cd}`])} target="_blank" rel="noreferrer">降車駅</a></div>}
              </div>
            }
            {(rideData.status === constClass.STATUS.RDE && settings[`LP_TRAIN_RIDE_POSITION`]) &&
              <div className='row'><div className='col-auto px-1 mx-1 text-green'>[乗車位置]</div>
                <div className='col px-1 mx-1'>
                  <a href={Common.getExternalUrl(settings[`LP_TRAIN_RIDE_POSITION`])} target="_blank" rel="noreferrer">車内の駐輪位置図</a>
                </div>
              </div>
            }
          </div>
        </div >
        {rideData.book_type === constClass.TRAIN_BOOK_TYPE.ALL &&
          <div className='row my-3 mx-2 bg-white font-weight-bold'>
            <div className='col' style={ticket_css}>
              <div className='row px-3-env pt-2'>
                <span style={maru_css}>検</span>
                <span style={{ "fontSize": "15px", "lineHeight": "15px" }}>西日本鉄道</span>
              </div>
              <div className='row mt-3 px-3-env'>
                <div className='col-12 text-center'>
                  <span className='h3 font-weight-bold'>{rideData.ride_station_name}
                    <img src={ArrowRight} style={{ width: "0.8em" }} alt="→" />
                    {rideData.fare}</span> 円区間
                </div>
              </div>
              <div className='row mt-2 px-3-env'>
                <div className='col-12 text-center h5 font-weight-bold p-0 m-0'>
                  {rideData.count_adult1 > 0 && <div className='row my-2 mx-0'><div className="col-7 p-0 m-0 text-right">大人 {rideData.count_adult1} 名</div><div className="col-1 p-0 m-0 text-left">：</div><div className="col-4 p-0 m-0 text-left">{fareList.fare_adult1.toLocaleString()} 円</div></div>}
                  {rideData.count_child1 > 0 && <div className='row my-2 mx-0'><div className="col-7 p-0 m-0 text-right"><small className='bg-black p-0 m-0 px-1'>小</small>小児 {rideData.count_child1} 名</div><div className="col-1 p-0 m-0 text-left">：</div><div className="col-4 p-0 m-0 text-left">{fareList.fare_child1.toLocaleString()} 円</div></div>}
                  {rideData.count_adult2 > 0 && <div className='row my-2 mx-0'><div className="col-7 p-0 m-0 text-right"><small className='bg-black p-0 m-0 px-1'>割</small>大人 {rideData.count_adult2} 名</div><div className="col-1 p-0 m-0 text-left">：</div><div className="col-4 p-0 m-0 text-left">{fareList.fare_adult2.toLocaleString()} 円</div></div>}
                  {rideData.count_child2 > 0 && <div className='row my-2 mx-0'><div className="col-7 p-0 m-0 text-right"><small className='bg-black p-0 m-0 px-1'>小・割</small>小児 {rideData.count_child2} 名</div><div className="col-1 p-0 m-0 text-left">：</div><div className="col-4 p-0 m-0 text-left">{fareList.fare_child2.toLocaleString()} 円</div></div>}
                </div>
              </div>
              <div className='row align-self-end'>
                <div className='col-6 text-left' style={{ "fontSize": "3vw" }}>{rideData.fare < 410 && "下車前途無効"}</div>
                <div className='col-6 text-right' style={{ "fontSize": "3vw" }}>通用利用当日限</div>
              </div>
            </div>
          </div>
        }
        {rideData.book_type !== constClass.TRAIN_BOOK_TYPE.ALL &&
          <div className='row m-3 bg-white text-red font-weight-bold text-center'>
            <div className='col h4 p-3 '>別途乗車券が必要です</div>
          </div>
        }
        <div className="row mx-0 pt-3 pb-3">
          <div className="col font-weight-bold text-center">
            <button
              className={`btn btn-primary py-2 px-1 w-75`}
              onClick={() => {
                submitCurrentbooking(null, null, null);
                history.replace(`${constClass.CYCLETRAIN_LINKURL}/`);
              }}>
              メニューに戻る
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
  const renderQR = () => {
    //console.log("renderQR")
    return (
      <React.Fragment>
        {scanning &&
          <Modal isOpen={scanning} style={modalStyle} onRequestClose={() => setScanning(false)}>
            <div className="container-fluid section-white m-0 py-2 vh-50 overflow-auto">
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col px-0 align-self-center">
                  <span>二次元コード読み取り</span>
                </div>
                <div className="col-auto px-0 align-self-start">
                  <div className="text-right px-0" onClick={() => setScanning(false)}><img className="icon" src={CloseImg} alt="閉じる" /></div>
                </div>
              </div>
              <div className="row mx-0 px-3-env mt-3 mb-2 text-left">
                <div className="col px-0 align-self-center">
                  <QRCodeScanner
                    setQrCodeData={setQrCodeData}
                    scanning={scanning}
                  />
                </div>
              </div>
              <div className="row mx-0 px-3-env mt-2 mb-3 text-center">
                <div className="col px-0 align-self-center text-danger">{scanMessage}</div>
              </div>
              <div className="row mx-0 px-3-env pt-1 pb-3">
                <div className="col-12 text-center p-0">
                  <button
                    className="btn btn-link text-body"
                    onClick={() => setScanning(false)}>
                    閉じる
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col">
                <QRCodeScanner
                  setQrCodeData={setQrCodeData}
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-danger">
                {scanMessage}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col text-danger">
                <button
                  className={`btn btn-primary w-100 h-100 py-2 px-2`}
                  onClick={() => setScanning(false)}>
                  <h4 className="mb-0 font-weight-bold">戻る</h4>
                </button>
              </div>
            </div> */}
          </Modal>
        }
        {(constClass.STATUS_RDEOK.includes(rideData.status) && rideStation.station_cd === null) &&
          <div className="row mb-3">
            <div className='col-12 p-2 bg-green text-white text-center'>
              {rideData.status === constClass.STATUS.RDE ? "降車" : "乗車"}駅の二次元コードを読み取ってください
            </div>
          </div>
        }
        <div className="row mb-3 bg-white">
          <div className="col-12 p-2 text-left">
            {(constClass.STATUS_RDEOK.includes(rideData.status) && rideStation.station_cd === null) &&
              <div className="row mx-3 mb-3pt-2 pb-2">
                <div className='col text-center'>
                  <button
                    className={`btn btn-active w-100 h-100 py-2 px-2`}
                    onClick={e => { scanQR() }}>
                    <h4 className="mb-0 font-weight-bold">二次元コード読取</h4>
                  </button>
                </div>
                {/* <div className='col text-center'>
                  <button
                    className={`btn btn-active w-100 h-100 py-2 px-2`}
                    onClick={e => { scanQrExternal() }}>
                    <h4 className="mb-0 font-weight-bold">ＱＲテスト</h4>
                  </button>
                </div> */}
              </div>
            }
            {constClass.STATUS.FIN === rideData.status &&
              <div className="row mx-0 px-3-env pt-2 pb-1 bg-green text-white text-center">
                <div className='col text-center h5'>降車済</div>
              </div>
            }
            {rideData && <React.Fragment>
              <div className="row mx-3 my-3 px-0-env bg-white">
                <div className="col-12">
                  <div className='row'><div className='col-auto px-1 mx-1 text-green'>[発券番号]</div><div className='col px-1 mx-1'>{rideData.view_book_no}</div></div>
                  <div className='row'><div className='col-auto px-1 mx-1 text-green'>[乗車日付]</div><div className='col px-1 mx-1'>{rideData.view_book_date_ja}</div></div>
                  <div className='row'><div className='col-auto px-1 mx-1 text-green'>[乗車駅・時刻]</div><div className='col px-1 mx-1'>{rideData.ride_station_name} {rideData.view_ride_time}発</div></div>
                  <div className='row'><div className='col-auto px-1 mx-1 text-green'>[降車駅]</div><div className='col px-1 mx-1'>{rideData.getoff_station_name}</div></div>
                  <div className='row'><div className='col-auto px-1 mx-1 text-green'>[号車・台数]</div><div className='col px-1 mx-1'>{rideData.car_no_list_str}</div></div>
                  <div className='row'><div className='col-auto px-1 mx-1 text-green'>[持ち込み料]</div><div className='col px-1 mx-1'>{fareList.fare_bringin} 円</div></div>
                  {(settings[`LP_STATIONMAP_${rideData.ride_station_cd}`] || settings[`LP_STATIONMAP_${rideData.getoff_station_cd}`]) &&
                    <div className='row'><div className='col-auto px-1 mx-1 text-green'>[構内図]</div>
                      {settings[`LP_STATIONMAP_${rideData.ride_station_cd}`] && <div className='col-auto px-1 mx-1 text-center'><a href={Common.getExternalUrl(settings[`LP_STATIONMAP_${rideData.ride_station_cd}`])} target="_blank" rel="noreferrer">乗車駅</a></div>}
                      {settings[`LP_STATIONMAP_${rideData.getoff_station_cd}`] && <div className='col-auto px-1 mx-1 text-center'><a href={Common.getExternalUrl(settings[`LP_STATIONMAP_${rideData.getoff_station_cd}`])} target="_blank" rel="noreferrer">降車駅</a></div>}
                    </div>
                  }
                  {(constClass.STATUS_RDEOK.includes(rideData.status) && settings[`LP_TRAIN_RIDE_POSITION`]) &&
                    <div className='row'><div className='col-auto px-1 mx-1 text-green'>[乗車位置]</div>
                      <div className='col px-1 mx-1'>
                        <a href={Common.getExternalUrl(settings[`LP_TRAIN_RIDE_POSITION`])} target="_blank" rel="noreferrer">車内の駐輪位置図</a>
                      </div>
                    </div>
                  }
                </div>
              </div >
              {((rideData.count_adult2 > 0 || rideData.count_child2 > 0) && "BOOKING_DISABILITY_CERTIFICATE_MESSAGE" in settings) &&
                <div className='row'>
                  <div className='col mx-2 text-red'>※{settings.BOOKING_DISABILITY_CERTIFICATE_MESSAGE}</div>
                </div>
              }
              <hr />
            </React.Fragment>
            }
            {[constClass.STATUS.RDE, constClass.STATUS.FIN].includes(rideData.status) &&
              <div className="row mx-0 my-3 pt-2 pb-3">
                <div className="col-12 text-left">
                  <div className="row mx-0 pt-2 pb-1 ">
                    <div className='col text-center'>
                      <button
                        className={`btn btn-active w-100 h-100 py-2 px-2`}
                        onClick={e => viewTicket(rideData.status, rideData.book_id, rideData.book_no)}>
                        <h4 className="mb-0 font-weight-bold">乗車券表示</h4>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }

  const renderErr = () => {
    return (
      <React.Fragment>
        <div>
          <div className="row mx-0 px-3-env pt-2 pb-1 bg-green text-white">
            <div className='col-12 py-3 h5 text-center'>対象の予約がございません</div>
          </div>
        </div>
        {/* <div className="row mx-3 mb-3pt-2 pb-2">
          <div className='col text-center'>
            <button
              className={`btn btn-active w-100 h-100 py-2 px-2`}
              onClick={e => { scanQrExternal() }}>
              <h4 className="mb-0 font-weight-bold">テスト</h4>
            </button>
          </div>
        </div> */}
      </React.Fragment>
    );
  }


  return (
    checkReady() ?
      <React.Fragment>
        {rideError ?
          renderErr()
          :
          <div className="row mx-3 my-3 px-0-env">
            <div className="col px-2">
              {(page === constClass.RIDEQR && !values.ride_ckeck) &&
                renderQR()
              }
              {(page === constClass.RIDETICKET || page === constClass.GETOFFTICKET || values.ride_ckeck) &&
                renderTicket()
              }
            </div>
          </div>}
      </React.Fragment>
      : <Loading />
  )

}
export default BookingQRRead;
