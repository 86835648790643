import React, { useState, useEffect, useRef,useCallback } from 'react';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import moment from 'moment';

const BookingList = (props) => {
  // const [bookData, setBookData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const componentRef = useRef();
  const refreshBook = useCallback(async () => {
    //トークンを取得
    CreateCsvData(props.condition.bookData);

  },[props.condition]);

  const CreateCsvData = (data) => {
    if (data) {
      var list = [];

      var head = [];
      head.push({ label: "日付", key: "bookingdate" });
      head.push({ label: "開始時間帯", key: "start_time" });
      head.push({ label: "終了時間帯", key: "end_time" });
      head.push({ label: "施設", key: "logi_name" });
      head.push({ label: "訪問先", key: "place_visit_name" });
      head.push({ label: "訪問先担当者", key: "person_charge" });
      head.push({ label: "会社名", key: "company_name" });
      head.push({ label: "氏名", key: "names" }); // 連結した値を格納するキー
      head.push({ label: "本人確認書類", key: "identity_document" });
      head.push({ label: "カードNo.", key: "card_number" });
      head.push({ label: "返却", key: "return_card" });
      head.push({ label: "保税エリア", key: "bonded_area" });
      head.push({ label: "実施検査", key: "inspection" });
      head.push({ label: "用件", key: "requirement" });
      head.push({ label: "車両番号", key: "vehicle_number" });
      head.push({ label: "備考", key: "remarks" });
      head.push({ label: "入館確認者", key: "guard_entry" });
      head.push({ label: "入館時刻", key: "entry_time" });
      head.push({ label: "到着確認者", key: "confirmation_person" });
      head.push({ label: "到着確認時刻", key: "confirmation_time" });
      head.push({ label: "退館確認者", key: "guard_closing" });
      head.push({ label: "退館時刻", key: "closing_time" });

      data.forEach(p => {
        var booking = {
          bookingdate: p.bookingdate,
          start_time: p.start_time,
          end_time: p.end_time,
          logi_name: p.logi_name,
          place_visit_name: p.place_visit_name,
          company_name: p.company_name,
          names: p.t_booking_names.filter(t => t.name).map(t => t.name).join(', '),
          identity_document: p.t_booking_names.filter(t => t.identity_document).map(t => t.identity_document).join(', '),
          card_number: p.t_booking_names.filter(t => t.card_number).map(t => t.card_number).join(', '),
          return_card: p.t_booking_names.filter(t => t.return_card).map(t => t.return_card === constClass.FLAG.ON ? '〇' : '').filter(value => value).join(', '),
          bonded_area: p.t_booking_names.filter(t => t.bonded_area).map(t => t.bonded_area === constClass.FLAG.ON ? '〇' : '').filter(value => value).join(', '),
          inspection: p.t_booking_names.map(t => (t.baggage_inspection === constClass.FLAG.ON ? '手荷物検査　' : '') + (t.physical_examination === constClass.FLAG.ON ? '身体検査　' : '') + (t.metal_detector === constClass.FLAG.ON ? '金属探知機　' : '')).filter(value => value).join(', '),
          person_charge: p.person_charge,
          requirement: p.requirement,
          vehicle_number: p.vehicle_number,
          remarks: p.remarks,
          entry_time: p.entry_time ? moment(p.entry_time).format("YYYY/MM/DD HH:mm:ss") : '',
          guard_entry: p.guard_entry,
          guard_closing: p.guard_closing,
          closing_time: p.closing_time ? moment(p.closing_time).format("YYYY/MM/DD HH:mm:ss") : '',
          confirmation_person: p.confirmation_person,
          confirmation_time: p.confirmation_time ? moment(p.confirmation_time).format("YYYY/MM/DD HH:mm:ss") : '',
        };
        list.push(booking);
      });

      setCsvData({ header: head, list: list });
    }
  }

  useEffect(() => {
    refreshBook();
  }, [refreshBook]);

  const renderList = () => {
    return (
      <div ref={componentRef} className="print-list">
        <div className="row mb-0 p-0 ">
          <div className="col pl-0 text-left align-self-end">
            {(props.condition.logi_id && props.condition.logiList.length > 0) && <div className="row"><div className="col-12"><h4>{props.condition.logiList.find(s => s.logi_id === Number(props.condition.logi_id)).logi_name}</h4></div></div>}
          </div>
        </div>
        <div className="row">
          <div className="col-2 pr-2">
            <ReactToPrint
              trigger={() => (
                <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100"
                  disabled={lockData || props.condition.bookData.length < 1}
                >
                  一覧印刷
                </button>
              )}
              content={() => componentRef.current}
              pageStyle={""}
            />
          </div>
          <div className="col-2 px-1">
            {csvData &&
              <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list} headers={csvData.header} filename={"予約データ.csv"}>
                CSV出力
              </CSVLink>
            }
          </div>
          <div className="col-6"></div>
          <div className="col-2 mb-2">
            <div className="col text-green font-weight-bold text-center">
              {/* <button className='btn btn-active px-5'
                  onClick={() 
                  => searchBin()}>新規登録</button> */}
              <Link className="nav-link btn btn-active px-3 text-nowrap text-center print-none" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}booking`}>新規登録</Link>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped" height="1">
              <thead className={`table-success`}>
                <tr>
                  <th className="text-center align-middle text-nowrap like-button">
                    予約<br />ID
                  </th>
                  <th className="text-center align-middle like-button">
                    日付
                  </th>
                  <th className="text-center align-middle like-button">
                    時間帯
                  </th>
                  <th className="text-center align-middle like-button">
                    施設
                  </th>
                  <th className="text-center align-middle like-button">
                    訪問先
                  </th>
                  <th className="text-center align-middle like-button">
                    訪問先担当者名
                  </th>
                  <th className="text-center align-middle like-button">
                    会社名
                  </th>
                  <th className="text-center align-middle like-button">
                    氏名
                  </th>
                  <th className="text-center align-middle like-button">
                    本人確認書類
                  </th>
                  <th className="text-center align-middle like-button">
                    カードNo.
                  </th>
                  <th className="text-center align-middle like-button">
                    返却
                  </th>
                  <th className="text-center align-middle like-button">
                    保税エリア
                  </th>
                  <th className="text-center align-middle like-button">
                    入館確認者
                  </th>
                  <th className="text-center align-middle like-button">
                    入館時刻
                  </th>
                  <th className="text-center align-middle like-button">
                    到着確認者
                  </th>
                  <th className="text-center align-middle like-button">
                    到着確認時刻
                  </th>
                  <th className="text-center align-middle like-button">
                    退館確認者
                  </th>
                  <th className="text-center align-middle like-button">
                    退館時刻
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.condition.bookData.length <= 0 && <tr><td className="text-center align-middle p-3" colSpan={18}>条件に一致する予約がありません</td></tr>}
                {props.condition.bookData.map((data, idx) => (
                  <tr key={idx}>
                    {/* 予約番号 */}
                    <td className="text-center align-middle p-1">
                      <Link to={`${generatePath(`${props.match.path}booking/:book_id/`, { siteId: props.match.params.siteId, book_id: data.book_id })}`}>{data.book_id}</Link>
                    </td>
                    {/* 日付 */}
                    <td className="text-center align-middle p-1 text-nowrap">
                      {data.bookingdate}
                    </td>
                    {/* 時間帯 */}
                    <td className="text-center align-middle p-1 text-nowrap">
                      {data.start_time} ～ {data.end_time}
                    </td>
                    {/* ロジ */}
                    <td className="text-center align-middle p-1">
                      {data.logi_name}<br />
                    </td>
                    {/* 訪問先 */}
                    <td className="text-center align-middle p-1">
                      {data.place_visit_name}
                    </td>
                    {/* 訪問先担当者名 */}
                    <td className="text-center align-middle p-1">
                      {data.person_charge}
                    </td>
                    {/* 会社名 */}
                    <td className="text-center align-middle p-1">
                      {data.company_name}
                    </td>
                    <td>
                      {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                        <div key={nameIdx} className="text-center align-middle p-1 text-nowrap">
                          {nameData.name}
                        </div>
                      ))}
                    </td>
                    <td>
                      {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                        <div key={nameIdx} className="text-center align-middle p-1 text-nowrap">
                          {nameData.identity_document.match(/その他\((.*?)\)/) ? "その他" : nameData.identity_document}
                        </div>
                      ))}
                    </td>
                    <td>
                      {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                        <div key={nameIdx} className="text-center align-middle p-1">
                          {nameData.card_number}
                        </div>
                      ))}
                    </td>
                    <td>
                      {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                        <div key={nameIdx} className="text-center align-middle p-1">
                          {nameData.return_card === constClass.FLAG.ON ? "〇" : ""}
                        </div>
                      ))}
                    </td>
                    <td>
                      {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                        <div key={nameIdx} className="text-center align-middle p-1">
                          {nameData.bonded_area === constClass.FLAG.ON ? "〇" : ""}
                        </div>
                      ))}
                    </td>
                    {/* 入館確認者 */}
                    <td className="text-center align-middle p-1">
                      {data.guard_entry}
                    </td>
                    {/* 入館時刻 */}
                    <td className="text-center align-middle p-1">
                      {data.entry_time ? moment(data.entry_time).format("YYYY/MM/DD HH:mm:ss") : ''}
                    </td>
                    {/* 到着確認者 */}
                    <td className="text-center align-middle p-1">
                      {data.confirmation_person}
                    </td>
                    {/* 到着確認時刻 */}
                    <td className="text-center align-middle p-1">
                      {data.confirmation_time ? moment(data.confirmation_time).format("YYYY/MM/DD HH:mm:ss") : '' }
                    </td>
                    {/* 退館確認者 */}
                    <td className="text-center align-middle p-1">
                      {data.guard_closing}
                    </td>
                    {/* 退館時刻 */}
                    <td className="text-center align-middle p-1">
                      {data.closing_time ? moment(data.closing_time).format("YYYY/MM/DD HH:mm:ss") : ''}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container-fluid">
      <div className="row d-none"><div className="col-12">{util.inspect(props.condition.bookData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(props.user)}</div></div>
      {props.condition.bookData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {props.condition.bookData !== null && renderList()}
    </div>
  )
}

export default BookingList;