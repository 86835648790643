import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
// import { connect } from 'react-redux';

const UserOnly = (props) => {
  // static contextTypes = {
  //   router: PropTypes.object.isRequired
  // }
  const { user } = props;
  const history = useHistory();

  useEffect(() => {
    if (!user.isLoggedIn) {
      history.replace(generatePath(`${props.match.path}login`, { siteId: props.match.params.siteId }));
    }
  }, [user, history, props.match.path, props.match.params.siteId])

  return <div>{props.children}</div>;
}

UserOnly.propTypes = {
  children: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

// function select({ auth }) {
//   return { auth };
// }

export default UserOnly;