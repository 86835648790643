import axios from 'axios';
import moment from 'moment';
import constClass from '../../Constants/Constants';

const CommonTrain = {};
CommonTrain.viewBookNo = function (book_date, book_no) {
  return `${moment(book_date).format('YYMMDD')}${('000' + parseInt(book_no)).slice(-3)}`
}
// 変更可能時間確認 true:変更可
CommonTrain.checkChangeTime = function (book_date, ride_time, limit) {
  if (!ride_time) return false;//ride_timeが指定されていなければ変更不可
  return (moment(`${book_date} ${this.strTotime(ride_time)}`, 'YYYY-MM-DD k:mm').subtract(parseInt(limit), 'm').isAfter());//乗車時刻が現在時刻より後であれば変更可
}
//合計計算
CommonTrain.calcTotal = function (fare, fare_bringin, count) {
  if (!count) return 0;
  const total = parseInt(fare_bringin) * (count.count_bringin) //持ち込み料
    + CommonTrain.calcFare(fare, count)
    ;
  return total;
}

//運賃計算
CommonTrain.calcFare = function (fare, count) {
  return (fare * parseInt(count.count_adult1 ? count.count_adult1 : 0) //大人運賃
    + (Math.ceil((fare / 2) / 10) * 10 * parseInt(count.count_child1 ? count.count_child1 : 0)) //こども運賃(半額 10円単位切り上げ)
    + (Math.ceil((fare / 2) / 10) * 10 * parseInt(count.count_adult2 ? count.count_adult2 : 0)) //大人障がい者運賃(半額 10円単位切り上げ)
    + (Math.ceil((fare / 2 / 2) / 10) * 10 * parseInt(count.count_child2 ? count.count_child2 : 0)) //こども障がい者運賃(半額 10円単位切り上げ)
  );
}

//前ゼロなしの時分形式の文字列を 時：分形式に変換
CommonTrain.strTotime = function (str) {
  if (str) return (str.substr(0, str.length - 2) + ":" + str.substr(-2, 2));
  else return "";
}
//区間運賃取得
CommonTrain.getFare = async function (book_date, ride_station_cd, getoff_station_cd) {
  var fare = null
  try {
    const params = {
      book_date: moment(book_date).format('YYYYMMDD'),
      ride_station_cd: ride_station_cd,
      getoff_station_cd: getoff_station_cd
    }
    const farePromise = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_fare/search/`, params);
    fare = (farePromise.data.fare ? farePromise.data.fare : null);
  } catch (error) {
    console.log("getFare error ", error)
  }

  return fare;
}

CommonTrain.getTotal = function (book_detail, refund = 0) {
  const sum = book_detail.filter(x => (!constClass.STATUS_CCL.includes(x.status) || x.status === constClass.STATUS.CFIN)).reduce((sum, item) => sum += item.total, 0) - refund;
  return sum
}
export default CommonTrain;