var Constants = {};
//サイクルトレイン予約のサイトID
Constants.CYCLETRAIN_SITE_ID = 1;
Constants.CYCLETRAIN_LINKURL = '/cycletrain';

// BFE: '入構前'
// ARE: '入構済み'
// ACA: '到着確認後'
// AMO: '退構済み'

Constants.STATUS = {
  BFE: 'BFE', //入構前
  ARE: 'ARE', //入構済み
  ACA: 'ACA', //到着確認後
  AMO: 'AMO', //退構済み
  CCL: 'CCL', //キャンセル
};

Constants.STATUS_NAME = {
  BFE: '入構前',
  ARE: '入構済み',
  ACA: '到着確認後',
  AMO: '退構済み',
  CCL: 'キャンセル'
};

Constants.STATUS_NAME_QR = {
  BFE: '入構用QR',
  ARE: '到着確認用QR',
  ACA: '退構用QR',
};

Constants.STATUS_NAME_USERLIST = {
  BFE: '受付済み',
  ARE: '入構済み',
  ACA: '入構済み',
  AMO: '退構済み',
  CCL: 'キャンセル'
};

Constants.POINT_SCREEN = {
  MAIN: 1,
  CAMERA: 2,
  DETAIL: 3,
}

// Constants.STATUS = {
  //   REG: 'REG',
  //   PRE: 'PRE',
//   CPRE: 'CPRE',
//   RDE: 'RDE',
//   FIN: 'FIN',
//   CCL: 'CCL',
//   CUPAY: 'CUPAY',
//   UPAY: 'UPAY',
//   CFIN: 'CFIN'
// };

//乗車/降車処理できるステータス
Constants.STATUS_RDEOK = [Constants.STATUS.REG, Constants.STATUS.PRE, Constants.STATUS.RDE];
//変更可能ステータス
Constants.STATUS_PRE = [null, Constants.STATUS.UPAY, Constants.STATUS.REG, Constants.STATUS.PRE];
//未完了扱いするステータス
Constants.STATUS_NFIN = [Constants.STATUS.UPAY, Constants.STATUS.REG, Constants.STATUS.PRE, Constants.STATUS.CPRE, Constants.STATUS.RDE];
//キャンセル扱いするステータス
Constants.STATUS_CCL = [Constants.STATUS.ARE, Constants.STATUS.ACA, Constants.STATUS.AMO, Constants.STATUS.CCL];

//===========================
//汎用版
//受付処理できるステータス
Constants.STATUS_RECEPTOK = [Constants.STATUS.REG, Constants.STATUS.PRE];

Constants.STATUS_NAME_PKG = {
  BFE: '入構前',
  ARE: '入構済み',
  ACA: '到着確認後',
  AMO: '退構済み',
};
//===========================



Constants.STATUS_NAME_USER = {
  UPAY: '未決済',
  REG: '予約済み',
  PRE: '予約済み',
  CPRE: '予約済み',
  RDE: '乗車',
  FIN: '降車',
  CUPAY: 'キャンセル未決済',
  CCL: 'キャンセル',
  CFIN: 'キャンセル'
};

Constants.STATUS_NAME_CSV = {
  UPAY: '未決済',
  REG: '未乗車',
  PRE: '未乗車',
  CPRE: '未乗車',
  RDE: '乗車',
  FIN: '完了',
  CUPAY: '未決済',
  CCL: 'キャンセル',
  CFIN: 'キャンセル(返金)'
};

Constants.PAYMENT_TYPE_CSV = {
  credit: 2,
  paypay: 3,
};

Constants.PAYMENT_TYPE_NAME_CSV = {
  credit: 'クレカ',
  paypay: 'PayPay',
};

Constants.PAPER_ORDER = 'PAPER_ORDER';

Constants.METHOD = {
};

Constants.METHOD_NAME = {
};

Constants.CLASS = {
  ADMIN: '1',
  SHOP: '2',
};

Constants.CLASS_NAME = {
  '1': '管理者',
  '2': '一般',
  ADMIN: '管理者',
  SHOP: '一般',
};

Constants.CHANNEL = {
};

Constants.SAIBAN_CLASS = {
};

Constants.MENU = "menu";
Constants.USER = "user";
Constants.BOOKMENU = "bookmenu";
Constants.INPUTALERT = "inputalert";
Constants.INPUT = "input";
Constants.INPUTBIN1 = "inputbin1";
Constants.INPUTBIN2 = "inputbin2";
Constants.CONFIRM = "confirm";
Constants.FINISH = "finish";
Constants.CORRECTION = "correction";
Constants.CANCEL = "cancel"
Constants.HISTORY = "history";
Constants.PAYMENT = "payment";

Constants.CHANGECONFIRM = "changeconfirm"
Constants.RIDEQR = "rideqr";
Constants.RIDETICKET = "rideticket"
Constants.GETOFFTICKET = "getoffticket"
Constants.CANCEL = "cancel";
Constants.CONFIRMATION = "confirmation";
Constants.CARDSEARCH = "cardsearch";
Constants.CONFIRMATIONPERSON = "confirmationperson";
//==========================
//汎用版
Constants.RECEPT = "recept";
Constants.RECEPTQR = "receptqr";
//==========================


Constants.OPEN = "1";
Constants.CLOSE = "2";

Constants.FLAG = {
  OFF: '0',
  ON: '1'
}

Constants.ACTIVE_BUTTONS = {
  'REG': [
    'RDE',
  ],
  'PRE': [
    'RDE',
  ],
  'RDE': [
    'FIN',
  ],
  'CCL': [],
  'FIN': [],
  'UPAY': [],
  'CUPAY': [],
};
Constants.STOP_MESSAGE_TITLE = {
  SITE1: 'サービス停止中'
}
Constants.STOP_MESSAGE = {
  SITE1: 'サービス停止中です。'
}

Constants.REQUIREMENT = [
  '1', '2', '3', '4'
];
Constants.REQUIREMENT_NAME = {
  '1': '打ち合わせ',
  '2': '納品',
  '3': '機械メンテナンス',
  '4': 'その他',
}

Constants.NAME_REQUIREMENT = {
  '打ち合わせ': '1',
  '納品': '2',
  '機械メンテナンス': '3',
  'その他': '4',
}

Constants.LICENSE = [
  '1', '2', '3', '4',
];
Constants.LICENSE_NAME = {
  '1': '免許証',
  '2': '社員証',
  '3': 'マイナンバーカード・パスポート',
  '4': 'その他',
}

Constants.NAME_LICENCE = {
  '免許証': '1',
  '社員証': '2',
  'マイナンバーカード・パスポート': '3',
  'その他': '4',
}

Constants.CUSTOMER_AGE_TYPE = [
  '1', '2', '3', '4', '5', '6',
]
Constants.CUSTOMER_AGE_TYPE_NAME = {
  '1': '10代',
  '2': '20代',
  '3': '30代',
  '4': '40代',
  '5': '50代',
  '6': '60代以上',
}
Constants.COUNT_PASSENGER_LIST = [
  { name: 'count_adult1', text: '大人' },
  { name: 'count_child1', text: '小児' },
  { name: 'count_adult2', text: '大人(障がい者または介護者)' },
  { name: 'count_child2', text: '小児(障がい者または介護者)' },
];
/* サイクルトレイン用 */
Constants.TRAIN_BOOK_TYPE = {
  ALL: '1',
  BRINGIN: '2',
}
Constants.TRAIN_BOOK_TYPE_NAME = {
  '1': '乗車券＋持ち込み料',
  '2': '持ち込み料のみ',
  ALL: '乗車券＋持ち込み料',
  BRINGIN: '持ち込み料のみ'
}

Constants.TRAIN_ROUND_TYPE = {
  "0": '行き',
  "1": '帰り',
  OUTBOUND: "0",
  RETURNTRIP: "1",
}
Constants.TRAIN_JYOGE_TYPE = {
  NOBORI: "0",
  KUDARI: "1",
}
Constants.TRAIN_JYOGE_TYPE_NAME = {
  "0": "上り",
  "1": "下り",
  NOBORI: "上り",
  KUDARI: "下り",
}
Constants.TRAIN_DAIYA_SBT_LIST = [
  '1', '4', '5', '3'
]
Constants.TRAIN_DAIYA_SBT = {
  '1': '平日',
  '4': '土曜',
  '5': '日祝',
  '3': '正月',
  'F': '運賃'
}
Constants.TRAIN_OPERATE_NAME = {
  LIMITED_EXPRESS: '特急',
  EXPRESS: '急行',
  LOCAL: '普通',
  '1': '特急',
  '2': '急行',
  '3': '普通',
}
Constants.TRAIN_OPERATE_NAME_SHORT = {
  LIMITED_EXPRESS: '特',
  EXPRESS: '急',
  LOCAL: '普',
  '1': '特',
  '2': '急',
  '3': '普',
}


export default Constants;