import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import util from 'util';
import constClass from '../../Constants/Constants';
// import queryString from 'query-string';
import Loading from '../Loading/Loading';
import CommonBooking from '../Common/common_booking';
import QRCodeScannerBooking from '../Common/QRCodeScanner_booking';
import Modal from 'react-modal';
import CloseImg from '../Images/close.svg';
import Common from '../Common/common';
import QRCode from "react-qr-code";
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

Modal.setAppElement("#root");

const BookingQR = ({ confirmationList, page, siteId, lineId, liff_user_id, setting, person }) => {
  const [lockData, setLockData] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [scanMessage, setScanMessage] = useState("");
  const [qrData, setQrData] = useState(null);
  const [validTime, setValidTime] = useState(moment());

  const getQrData = useCallback(async () => {
    const qr_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/line/qrdata/${siteId}/`, { site_id: siteId, line_id: lineId })).data;
    setQrData(qr_data);
    const date = moment();
    setValidTime(date + setting.MEMBER_CARD_QRCODE_VALID_TIME * 60 * 1000);
  }, [siteId, lineId, setting]);

  const checkReady = useCallback(() => {
    return (siteId !== null && siteId !== undefined) && lineId;
  }, [siteId, lineId]);

  useEffect(() => {
    getQrData();
  }, [getQrData]);

  useEffect(() => {
    var intervalId;
    intervalId = setInterval(() => {
      const date = moment();
      const sec = Math.floor((validTime - date) / 1000);
      //setRestTime(sec);
      if (sec <= 0) {
        getQrData();
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [validTime, getQrData]);

  const checksubmit = async (book_id) => {
    try {
      if (!window.confirm('確認を行います。よろしいですか？')) {
        return;
      }
      setLockData(true);
      var result = null;
      
      const params = {
        site_id: siteId,
        book_id: book_id,
        status: constClass.STATUS.ACA,
        person: person
      }
      result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/booking/status/${book_id}`, params));
      setLockData(false);

      if (result.data.result) {
        alert('確認しました。');
        setLockData(true);
        getQrData();
      } else {
        alert('確認失敗');
        setLockData(false);
      }
    } catch (error) {
      setLockData(false);
      console.log(util.inspect(error))
      alert('確認失敗');
    }
    setLockData(true);
  }

  useEffect(() => {
    if (scanning && qrCodeData) {
      try {
        const cd = Common.getCdFromQrString(qrCodeData);
        if (cd) {
          setScanning(false);
          setQrCodeData("");
          setScanMessage("");
        } else {
          setScanMessage("読み取りに失敗しました。再度読み取りをお願いします。");
        }
      } catch (error) {
        console.log(error);
        setScanMessage("読み取りに失敗しました。再度読み取りをお願いします。");
      }
    }
  }, [scanning, qrCodeData]);

  // const renderTicket = () => {
  //   return (

  //     <React.Fragment>
  //       {qrData && qrData.map((item, idx) => (    
  //         <div className="row mb-3">
  //           <div className='col-12 py-3 bg-green text-white text-center'>
  //           (<div className="qr-box"><QRCode className="qr-img" size={100} value={item} /></div>)
  //           </div>
  //         </div>
  //       ))}
  //     </React.Fragment>
  //   )
  // }

  const renderQR = () => {
    const confirmationItem = confirmationList.find(c => c.line_id === liff_user_id);
    return (
      <React.Fragment>
        {qrData && !qrData.length > 0 &&
          <div className="row px-3-env py-3 bg-green text-white">
            <div className='col font-weight-bold text-center'>本日のご予約はありません。</div>
          </div>
        }
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true, el: "#pagination" }}
          slidesPerView={'auto'}
        >
          {qrData && qrData.length > 0 && qrData.map((qr, index) => (
            <div key={index} className='pb-4'>
              {index}
              <SwiperSlide key={index}>
                <div className="row px-3-env py-3 bg-green text-white">
                  <div className='col font-weight-bold text-center'>{constClass.STATUS_NAME_QR[qr.status]}</div>
                </div>
                <div className="row mb-3 bg-white">
                  <div className='col-12 pt-1 text-center'>
                    {qr.start_time}～{qr.end_time}
                  </div>
                  <div className='col-12 py-2 bg-white text-center'>
                    <QRCode className="qr-img" size={200} value={qr.qr_data} />
                  </div>
                </div>
                {qr.status === constClass.STATUS.ARE && confirmationItem &&
                  <div className="col-12 text-center">
                    <div className='p-4 p-env-bottom m-4'>
                      <button
                        disabled={lockData}
                        className={`btn btn-active w-100`}
                        onClick={() => checksubmit(qr.book_id)}
                      >
                        確認
                      </button>
                    </div>
                  </div>
                }
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </React.Fragment>
    )
  }

  return (
    checkReady() ?
      <React.Fragment>
        {
          <div className="row mx-3 my-3 px-0-env">
            <div className="col px-2">
              {page === constClass.RECEPTQR &&
                renderQR()
              }
            </div>
          </div>}
      </React.Fragment>
      : <Loading />
  )

}
export default BookingQR;
