import React, { Component } from 'react';
import axios from 'axios';
import DocumentMeta from 'react-document-meta';
import Booking from '../Components/Booking/Booking';
import queryString from 'query-string';
import Modal from 'react-modal';
import Common from '../Components/Common/common.js';
import constClass from '../Constants/Constants';
// import VConsole from 'vconsole';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: isFinite(props.match.params.siteId) ? props.match.params.siteId : 0,
      liff_access_token: null,
      liff_user_id: null,
      channel_id: null,
      page: null,
      open_data: null,
      my_order: null,
      current_bookId: null,
      current_bookSetId: null,
      current_categoryId: null,
      settings: null,
      meta: null,
    };

    this.liff_init = this.liff_init.bind(this);
  }

  liff_init() {
    if (window.liff.isInClient() && window.liff.isLoggedIn()) {
      const accessToken = window.liff.getAccessToken();
      const decodedId = window.liff.getDecodedIDToken();
      this.setState({
        liff_access_token: accessToken,
        liff_user_id: decodedId.sub
      });
    } else {
      this.setState({
        liff_access_token: 'token_unusable',
        liff_user_id: 'test_user_id'
      });
    }
  }

  setPage(page) {
    this.setState({ page: page });
  }

  setCurrentBook(current_bookId, current_categoryId) {
    this.setState({ current_bookId: parseInt(current_bookId), current_categoryId: current_categoryId });
  }

  setOpenData(open_data) {
    this.setState({ open_data: open_data });
  }

  setMyOrder(order) {
    this.setState({ my_order: order });
  }

  async componentDidMount() {
    // if (process.env.REACT_APP_ENV !== 'pro') {
    //   var vConsole= new VConsole();
    // }
    var qs = queryString.parse(this.props.location.search);
    var liffqs = (qs["liff.state"] !== undefined ? queryString.parse(qs["liff.state"]) : null);
    this.setState({ channel_id: qs.channel, page: qs.page, current_bookId: parseInt(qs.id), current_categoryId: isNaN(qs.cd) ? null : qs.cd });
    await this.getSettings();
    if (process.env.REACT_APP_ENV !== 'dev') {
      if (window.liff.id === null) {
        var liffId = Common.getSettingValue(this.state.settings, (process.env.REACT_APP_ENV !== "pro" ? "LINE_LIFF_ID_TEST" : "LINE_LIFF_ID_PRO"), process.env.REACT_APP_LIFF_ID);
        if (liffqs !== null && liffqs.page === constClass.CANCEL) {
          liffId = Common.getSettingValue(this.state.settings, (process.env.REACT_APP_ENV !== "pro" ? "LINE_LIFF_ID_CANCEL_TEST" : "LINE_LIFF_ID_CANCEL_PRO"), process.env.REACT_APP_LIFF_ID_CANCEL);;
        }
        window.liff.init({ liffId: liffId });
      }
      window.liff.ready.then(this.liff_init);
    } else {
      this.liff_init();
    }
  }
  async getSettings() {
    try {
      const reg_params = {
        "operator": "and",
        "where": [{ "site_id": this.state.siteId }]
      }
      const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${this.state.siteId}/`, reg_params)).data;

      const temp = {
        link: {
          rel: {
            icon: `/${this.state.siteId}/favicon.ico`,
            "apple-touch-icon": `/${this.state.siteId}/logo192.png`,
            manifest: `/${this.state.siteId}/manifest.json`,
            stylesheet: `/${this.state.siteId}/style.css`,
          },
        },
        title: data.TITLE_NAME ? data.TITLE_NAME : '',
        description: data.TITLE_NAME ? data.TITLE_NAME : '',
      };
      this.setState({
        settings: data,
        meta: temp
      });
    } catch (error) {
      this.setState({
        settings: { "CONTRACT_PERIOD_START": "00010101", "CONTRACT_PERIOD_END": "00010101" }
      })
    }

  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var qs = queryString.parse(this.props.location.search);
      this.setState({ channel_id: qs.channel, page: qs.page });
    }
  }

  render() {
    return (
      <React.Fragment>
        <DocumentMeta  {...this.state.meta} />
        <Modal isOpen={Common.checkContractPeriod(this.state.settings) === false}>
          <div className="text-center section-white m-0 py-2">
            <h4>サービス停止中です</h4>
          </div>
        </Modal>
        <Booking
          liff_access_token={this.state.liff_access_token}
          liff_user_id={this.state.liff_user_id}
          channel_id={this.state.channel_id}
          page={this.state.page}
          siteId={this.state.siteId}
          settings={this.state.settings}
          setPage={(page) => { this.setPage(page); }}
          current_bookId={this.state.current_bookId}
          current_bookSetId={this.state.current_bookSetId}
          current_categoryId={this.state.current_categoryId}
          setCurrentBook={(current_bookId, current_categoryId = null) => { this.setCurrentBook(current_bookId, current_categoryId); }}
        />
      </React.Fragment>
    );
  }
}

export default App;