import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import DatePicker, { registerLocale } from "react-datepicker";
import Modal from 'react-modal';
import UseConfirmModal from '../Common/UseConfirmModal';
import UseAlertModal from '../Common/UseAlertModal';  

const BackyardReservationBaddate = (props) => {
  const { user } = props;
  const [scanning, setScanning] = useState(true);
  const { addToast } = useToasts();
  const [indication, setIndication] = useState(false);
  const [searchLogiData, setSearchLogiData] = useState(null);
  const [reservationData, setReservationData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [searchPlacesVisitData, setSearchPlacesVisitData] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => { });
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');
  const [state, setState] = useState({
    reservation_baddate_id: "",
    logi_id: props.user.logiId,
    place_visit_id: "",
    inputTitle: "",
    inputMemo: "",
    inputStart: "",
    inputEnd: "",
    isChange: false,
    upd_date: ""
  })

  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    const placesVisitPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const placesVisit = (await placesVisitPromise).data;
    setSearchPlacesVisitData(placesVisit);
    setSearchLogiData(logi);
  }, [props.match.params.siteId]);

  useEffect(() => {
    async function fetchData() {
      await refreshData();
    }
    return fetchData();
  }, [refreshData]);


  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 1,
    },
    content: {
      backgroundColor: 'white',
      position: "absolute",
      left: '30%',
      right: '30%',
      top: '30%',
      bottom: 'auto',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto",
      maxHeight: "auto",
      width: "40%",
      overflowX: "visible",
      overflowY: "visible",
    }
  };
  const changeDateToString = (dt) => {
    const year = dt.getFullYear();
    const month = getdoubleDigestNumer(dt.getMonth() + 1);
    const date = getdoubleDigestNumer(dt.getDate());
    const hour = getdoubleDigestNumer(dt.getHours());
    const minutes = getdoubleDigestNumer(dt.getMinutes());

    const retDate = `${year}-${month}-${date} ${hour}:${minutes}:00`;
    return retDate;
  }
  const getdoubleDigestNumer = (number) => {
    return ("0" + number).slice(-2);
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  const onAddEvent = async () => {
    const starttime = changeDateToString(state.inputStart);
    const endtime = changeDateToString(state.inputEnd);

    if (starttime >= endtime) {
      setAlertModalMessage('開始時間と終了時間を確認してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (state.inputTitle > 20) {
      setAlertModalMessage('タイトルは20字以内で入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    setConfirmModalMessage(`予約不可時間帯[${starttime} ～ ${endtime}]を登録します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      let res;
      try {
        setLockData(true);
        const jwt = localStorage.getItem('jwt');
        const data = {
          site_id: props.match.params.siteId,
          logi_id: state.logi_id,
          place_visit_id: state.place_visit_id,
          reservation_baddate_id: state.reservation_baddate_id ? state.reservation_baddate_id : '',
          title: state.inputTitle,
          start_time: starttime,
          end_time: endtime,
          upd_date: null
        };
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reservation/`, data, { headers: { Authorization: `Bearer ${jwt}` } });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('登録しました。', { appearance: 'success', autoDismiss: true });
          await refreshData();
          handleReturnClick();
          const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${props.match.params.siteId}/${state.logi_id}/${Number(state.place_visit_id)}`);
          const reservation = (await reservationPromise).data;
          setReservationData(reservation);
        }
        setLockData(false);
      }
    });
  }
  const onChangeEvent = async () => {
    const starttime = changeDateToString(state.inputStart);
    const endtime = changeDateToString(state.inputEnd);

    if (starttime >= endtime) {
      setAlertModalMessage('開始時間と終了時間を確認してください。')
      setIsAlertModalOpen(true);
      return;
    }
    setConfirmModalMessage(`予約不可時間帯[${starttime} ～ ${endtime}]を登録します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      let res;
      try {
        const jwt = localStorage.getItem('jwt');
        const data = {
          site_id: props.match.params.siteId,
          logi_id: state.logi_id,
          place_visit_id: state.place_visit_id,
          reservation_baddate_id: state.reservation_baddate_id ? state.reservation_baddate_id : '',
          title: state.inputTitle,
          start_time: starttime,
          end_time: endtime,
          upd_date: state.upd_date
        };
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reservation/`, data, { headers: { Authorization: `Bearer ${jwt}` } });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('登録しました。', { appearance: 'success', autoDismiss: true });
          await refreshData();
          handleReturnClick();
          const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${props.match.params.siteId}/${state.logi_id}/${Number(state.place_visit_id)}`);
          const reservation = (await reservationPromise).data;
          setReservationData(reservation);
        }
        setLockData(false);
      }
    });
  }
  const onDeleteEvent = async () => {
    const starttime = changeDateToString(state.inputStart);
    const endtime = changeDateToString(state.inputEnd);
    const jwt = localStorage.getItem('jwt');
    setConfirmModalMessage(`予約不可時間帯[${starttime} ～ ${endtime}]を削除します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      const params = {
        site_id: props.match.params.siteId,
        logi_id: state.logi_id,
        place_visit_id: state.place_visit_id,
        reservation_baddate_id: state.reservation_baddate_id,
        upd_date: state.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reservation/delete/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('削除しました。', { appearance: 'success', autoDismiss: true });
          await refreshData();
          handleReturnClick();
          const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${props.match.params.siteId}/${state.logi_id}/${Number(state.place_visit_id)}`);
          const reservation = (await reservationPromise).data;
          setReservationData(reservation);
        }
        setLockData(false);
      }
    });
  }

  const handleReturnClick = async (e) => {
    setState({
      ...state, 
      inputTitle: "",
      inputStart: "",
      inputEnd: "",
      isChange: false,
    });
    document.body.style.overflow = 'auto'
    setIndication(false);
    setScanning(true);
  }

  const handleSetLogi = async (e) => {
    //トークンを取得
    const target = e.target;
    var tmp = { ...state, logi_id: Number(target.value), place_visit_id: "" };
    setState(tmp);
  }

  const handleSetPlaceVisit = async (e) => {
    //トークンを取得
    const target = e.target;
    var tmp = { ...state, place_visit_id: Number(target.value) };
    setState(tmp);
    const reservationPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/reservation/search/${props.match.params.siteId}/${state.logi_id}/${Number(target.value)}`);
    const reservation = (await reservationPromise).data;
    setReservationData(reservation);
  }

  const handleSelectClick = (selectInfo) => {
    setIndication(true);
    setScanning(false);
    let start = new Date(selectInfo.start);
    let end = new Date(selectInfo.end);
    start.setHours(start.getHours());
    end.setHours(end.getHours());
    document.body.style.overflow = "hidden"

    setState({ ...state, logi_id: state.logi_id, inputTitle: "", inputStart: start, inputEnd: end, isChange: false });
  };

  const handleClick = (info) => {
    setIndication(true);
    setScanning(false);
    const selEventID = info.event.id;
    // const selEvent = this.myEvents[info.event.id];
    const title = info.event.title;
    const start = new Date(info.event.start);
    const end = new Date(info.event.end);
    setState({ ...state, reservation_baddate_id: selEventID, inputTitle: title, inputStart: start, inputEnd: end, isChange: true, upd_date: info.event.extendedProps.upd_date });
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
        {searchLogiData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
        <div className="row mr-1 text-center align-self-center my-2 mt-4">
          <span className='mt-2'>施設</span>
          <div className="col-4 mr-2 text-center align-self-center mb-2">
            <select className="custom-select w-100" aria-label="施設" value={state.logi_id || ''} onChange={handleSetLogi}>
            <option value="">選択してください</option>
              {searchLogiData && searchLogiData.map((item) =>
                <option key={item.logi_id} value={item.logi_id}>{item.logi_name}</option>
              )}
            </select>
          </div>
          <span className='mt-2'>訪問先</span>
          <div className="col-4 mr-2 text-center align-self-center mb-2">
            <select className="custom-select w-100" aria-label="施設" value={state.place_visit_id || ''} onChange={handleSetPlaceVisit}>
            <option value="">選択してください</option>
              {searchPlacesVisitData && searchPlacesVisitData.filter((item) => item.logi_id === Number(state.logi_id)).map((item) =>
                <option key={item.place_visit_id} value={item.place_visit_id}>{item.place_visit_name}</option>
              )}
            </select>
          </div>
        </div>
        <Modal isOpen={indication} style={modalStyle} onRequestClose={() => setScanning(false)}>
          <div className='row'>
            <div className="col text-green font-weight-bold text-left">
              <button className="btn btn-active px-3 text-nowrap text-center m-2" onClick={handleReturnClick}>戻る</button>
            </div>
          </div>
          <div className={"container-fluid section-white m-0 py-2 vh-50 overflow-auto"}>
            <h4 className='border-bottom border-success'>{!state.isChange ? "予定を入力" : "予定を変更"}</h4>
            <div>タイトル</div>
            <input
              className="form-control"
              type="text"
              value={state.inputTitle}
              onChange={(e) => {
                setState({ ...state, inputTitle: e.target.value });
              }}
            />
            <div>開始日時</div>
            <DatePicker
              className="custom-select w-100 text-center border-textbox text-primary"
              locale="ja"
              dateFormat="yyyy/MM/d HH:mm"
              selected={state.inputStart}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              onChange={(time) => {
                setState({ ...state, inputStart: time });
              }}
            />
            <div>終了日時</div>
            <DatePicker
              className="custom-select w-100 text-center border-textbox text-primary overflow-visible"
              locale="ja"
              dateFormat="yyyy/MM/d HH:mm"
              selected={state.inputEnd}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              onChange={(time) => {
                setState({ ...state, inputEnd: time });
              }}
            />
            <div>
              {!state.isChange ? (
                <div className='mt-4 text-center'>
                  <button
                    className="btn btn-primary mr-4"
                    disabled={!state.inputTitle}
                    onClick={onAddEvent}
                  >
                    保存
                  </button>
                </div>
              ) : (
                <div className='mt-4 text-center'>
                  <button
                    className="btn btn-primary mr-4"
                    onClick={onChangeEvent}
                  >
                    変更
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={onDeleteEvent}
                  >
                    削除
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal>
        <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal}/>
        <UseAlertModal isOpen={isAlertModalOpen} message={alertModalMessage} onClose={closeAlertModal}/>
        {state.logi_id && state.place_visit_id &&
        <div className='py-4'>
          <FullCalendar
            locale="ja"
            initialView="timeGridWeek"
            slotDuration="00:30:00"
            selectable={true}
            allDaySlot={false}
            contentHeight={'auto'}
            titleFormat={{
              year: "numeric",
              month: "short",
              day: "numeric",
            }}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek",
            }}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5],
              startTime: "0:00",
              endTime: "24:00",
            }}
            events={reservationData ? reservationData.map(r => ({
              id: r.reservation_baddate_id,
              title: r.title,
              start: new Date(`${r.start_time}`),
              end: new Date(`${r.end_time}`),
              color: "#808080",
              upd_date: r.upd_date
            })) : []}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={true}
            select={handleSelectClick}
            // selectOverlap={false}
            unselectAuto={false}
            eventClick={handleClick} // イベントクリック時
          />
          </div>
        }
      </div>
    </React.Fragment>
  )

}

export default BackyardReservationBaddate