import React, { Component } from 'react';
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import util from 'util';
import moment from 'moment';
import 'moment/locale/ja';
import Common from '../Common/common';
import CommonTrain from '../Common/common_train';
import ScrollToTop from '../Scroll/ScrollToTop';

import Payment from '../Payment/Payment';
import BookingQRRead from './BookingQRRead';
import BookingCancel from './BookingCancel';
import BookingDetailView from './BookingDetailView';

import TrainImg from '../Images/train.svg';
import CycleImg from '../Images/cycle.svg';

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sleeping: false,
      ready: false,
      disabled: false,
      siteId: isFinite(this.props.siteId) ? this.props.siteId : 0,
      book_id: null,
      book_no: null,
      stationMaster: null,
      bookDateList: null,
      bookingList: null,
      set_booking: {
        book_id: null,
        book_date: null,
        total: 0,
        count_adult1: 0,
        count_child1: 0,
        count_adult2: 0,
        count_child2: 0,
        count_bringin: 0,
        book_type: constClass.TRAIN_BOOK_TYPE.ALL,
        returntrip_type: constClass.FLAG.OFF,
        customer_id: null,
        customer_name: '',
        customer_kana: '',
        customer_address: '',
        customer_gender: '',
        customer_age_type: '',
        privacy_policy: constClass.FLAG.OFF,
      },
      set_booking_detail: [{ round_type: constClass.TRAIN_ROUND_TYPE.OUTBOUND }, { round_type: constClass.TRAIN_ROUND_TYPE.RETURNTRIP }],
      set_bookBinList: { [constClass.TRAIN_ROUND_TYPE.OUTBOUND]: null, [constClass.TRAIN_ROUND_TYPE.RETURNTRIP]: null },
      set_bookBinListMessage: { [constClass.TRAIN_ROUND_TYPE.OUTBOUND]: null, [constClass.TRAIN_ROUND_TYPE.RETURNTRIP]: null },
      my_customer: {
        customer_id: null,
        customer_name: '',
        customer_kana: '',
        customer_address: '',
        customer_gender: '',
        customer_age_type: '',
      },

      bookBinList: { [constClass.TRAIN_ROUND_TYPE.OUTBOUND]: null, [constClass.TRAIN_ROUND_TYPE.RETURNTRIP]: null },
      bookBinListMessage: { [constClass.TRAIN_ROUND_TYPE.OUTBOUND]: null, [constClass.TRAIN_ROUND_TYPE.RETURNTRIP]: null },
      round_type: constClass.TRAIN_ROUND_TYPE.OUTBOUND,
      ride: null,
      change: false,
      user_modal_flag: false,
      user_modal_read: false,
      bookingDetailList: null,
      cancel_modal_flag: false,
      payment_data: {},
    }
    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '50%',
        bottom: 'auto',
        marginTop: '-25%',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };
    this.setReady = this.setReady.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.refreshBookData = this.refreshBookData.bind(this);
    this.backPayment = this.backPayment.bind(this);
    this.returnPayment = this.returnPayment.bind(this);
    this.submitCurrentbooking = this.submitCurrentbooking.bind(this);
    this.pageRef = React.createRef();
  }
  setReady() {
    if (this.state.bookDateList !== null &&
      this.state.stationMaster !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }
  async refreshData() {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalMaster) {
        this.intervalMaster = setInterval(() => {
          this.refreshData();
        }, 500);
      }
    } else {
      clearInterval(this.intervalMaster);
      try {
        const params = {
          site_id: this.state.siteId,
          operator: "and",
          where: [
            { site_id: this.state.siteId },
            { line_id: this.props.liff_access_token }
          ]
        }
        if ((("CONTRACT_PERIOD_STARTTIME" in this.props.settings) && parseInt(this.props.settings.CONTRACT_PERIOD_STARTTIME) > parseInt(moment().format('YYYYMMDDkkmm')))) {
          this.props.history.replace(`${constClass.CYCLETRAIN_LINKURL}/`);
        }
        const calendarPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/train_calendar/listdate/${this.state.siteId}`);
        const customerPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search/`, params);
        const month = ("BOOKING_DATE_LIMIT_MONTH" in this.props.settings) ? parseInt(this.props.settings.BOOKING_DATE_LIMIT_MONTH) : 1;
        const bookDateList = (await calendarPromise).data.filter(x => moment(x.book_date).isBefore(moment().add(month, "month").subtract(1, "day")));//翌月の1日前先まで予約可能
        const customer = (await customerPromise).data;
        await this.getStationList(null);
        this.setState({
          bookDateList,
          my_customer: customer.length > 0 ? customer.find(c => c.line_id === this.props.liff_user_id) : this.state.my_customer,
        });
        await this.refreshBookData();
        //決済入力画面の場合は、submitPaymentを実行
        if (this.props.page === constClass.PAYMENT && isFinite(this.props.current_bookId)) {
          this.submitPayment(this.props.current_bookId);
        }
      } catch (error) {
        console.log(error)
        this.setState({ sleeping: true });
      }
    }
    this.setReady();
  }

  async refreshBookData() {
    const book_id = isFinite(this.props.current_bookId) ? this.props.current_bookId : null;
    const book_no = isFinite(this.props.current_bookNo) ? this.props.current_bookNo : null;
    const params = {
      site_id: this.state.siteId,
      line_id: this.props.liff_access_token
    }
    const bookingPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/search_detail/`, params);
    const bookingDetailList = (await bookingPromise).data;
    var my_customer = this.state.my_customer;
    var booking = null;
    var booking_detail = [];
    var booked = null;
    var round_type = constClass.TRAIN_ROUND_TYPE.OUTBOUND;
    var binlist = this.state.bookBinList;
    var ride = await this.getRidedata(bookingDetailList);
    if (book_id) {
      var detail = await bookingDetailList.filter(x => (x.book_id === book_id));
      await Promise.all(detail.map(async (item) => {
        if (book_no && item.book_no === book_no) round_type = item.round_type;
        binlist[item.round_type] = await this.getBindetail(item, item, detail);
        binlist[item.round_type].forEach((item2, idx) => {
          if (item2.train_no === item.train_no) {
            binlist[item.round_type][idx] = { ...item2, bookok_count: (item2.book_status === constClass.FLAG.OFF) ? 0 : item2.bookok_count + item.count_bringin }
          }
        });
        const errmsg = (item.train_no && binlist[item.round_type].findIndex(x => x.train_no === item.train_no) < 0)
          || (item.train_no && binlist[item.round_type].findIndex(x => x.train_no === item.train_no) >= 0 && binlist[item.round_type].find(x => x.train_no === item.train_no).bookok_count < item.count_bringin)
          ? "ご乗車できない便が選択されています。再選択してください" : null;
        booking_detail.push({
          ...item,
          book_date: item.book_date,
          errcheck: errmsg,
        })
      }));
      booking = {
        ...booking_detail[0].t_booking,
        book_date: booking_detail[0].book_date,
        book_type: booking_detail[0].book_type,
        status: booking_detail[0].booking_status,
        returntrip_type: (booking_detail.length > 1) ? constClass.FLAG.ON : constClass.FLAG.OFF,
        count_bringin: booking_detail[0].count_bringin,
        count_adult1: booking_detail[0].count_adult1,
        count_child1: booking_detail[0].count_child1,
        count_adult2: booking_detail[0].count_adult2,
        count_child2: booking_detail[0].count_child2,
        booking_detail: booking_detail,
      };
      my_customer = {
        ...this.state.my_customer,
        customer_id: booking.customer_id,
        customer_name: booking.customer_name,
        customer_kana: booking.customer_kana,
        customer_address: booking.customer_address,
        customer_gender: booking.customer_gender,
        customer_age_type: booking.customer_age_type,
      }
      this.getStationList(booking.book_date);
      booked = { ...booking, booking_detail: booking_detail.concat() };
    } else {
      booking = this.blankBooking();
      booking_detail = this.blankBookingDetail();
    }
    this.setState({
      book_id: book_id,
      book_no: book_no,
      round_type: round_type,
      set_booking: { ...booking },
      set_booking_detail: booking_detail.concat(),
      set_bookBinList: { ...binlist },
      my_customer,
      booking: booking,
      booking_detail: booking_detail,
      bookBinList: binlist,
      ride: ride,
      bookingDetailList: bookingDetailList,
      booked,
      change: false,
    });
  }

  blankBooking() {
    return {
      book_id: null,
      book_date: null,
      total: 0,
      count_adult1: 0,
      count_child1: 0,
      count_adult2: 0,
      count_child2: 0,
      count_bringin: 0,
      book_type: constClass.TRAIN_BOOK_TYPE.ALL,
      returntrip_type: constClass.FLAG.ON,
      customer_id: this.state.my_customer.customer_id ? this.state.my_customer.customer_id : '',
      customer_name: this.state.my_customer.customer_name ? this.state.my_customer.customer_name : '',
      customer_kana: this.state.my_customer.customer_kana ? this.state.my_customer.customer_kana : '',
      customer_address: this.state.my_customer.customer_address ? this.state.my_customer.customer_address : '',
      customer_gender: this.state.my_customer.customer_gender ? this.state.my_customer.customer_gender : '',
      customer_age_type: this.state.my_customer.customer_age_type ? this.state.my_customer.customer_age_type : '',
      privacy_policy: constClass.FLAG.OFF,
      payment_fin_flag: constClass.FLAG.OFF,
      status: null,
    }
  }
  blankBookingDetail() {
    return [
      { round_type: constClass.TRAIN_ROUND_TYPE.OUTBOUND },
      { round_type: constClass.TRAIN_ROUND_TYPE.RETURNTRIP }
    ];
  }
  async getStationList(book_date) {
    var params = {
      site_id: this.state.siteId,
      book_date: book_date ? book_date : null,
    }
    const stationPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_station/list/`, params);
    const stationMaster = (await stationPromise).data;
    this.setState({ stationMaster });
  }
  //予約対象の便リストを取得
  async getBindetail(book, book_detail, list = null) {
    if ((!book_detail.ride_station_cd && !book_detail.getoff_station_cd)
      || (book.status && constClass.STATUS_CCL.includes(book.status))) return [];
    const params = {
      site_id: this.state.siteId,
      book_date: book.book_date,
      ride_station_cd: book_detail.ride_station_cd,
      getoff_station_cd: book_detail.getoff_station_cd
    }
    this.setState({ disabled: true });
    const dataPromise = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_calendar/searchbin/`, params);
    this.setState({ disabled: false });
    var outbound_getoff_time = book_detail.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && list && list.find(x => x.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND && x.train_no) ? list.find(x => x.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND && x.train_no).getoff_time : null;
    var returntrip_ride_time = book_detail.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND && list && list.find(x => x.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && x.train_no) ? list.find(x => x.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && x.train_no).ride_time : null;;

    //往路降車時刻以降の便のみ対象
    var now_date = moment().add(parseInt(this.props.settings.TRAIN_CANCEL_LIMIT), "m");
    var after_date = (outbound_getoff_time !== null) ? moment(`${book.book_date} ${CommonTrain.strTotime(outbound_getoff_time)}`, 'YYYY-MM-DD k:mm') : null;
    if (after_date === null || after_date < now_date) after_date = now_date;

    //復路降車時刻以前の便のみ対象
    var before_date = (returntrip_ride_time !== null) ? moment(`${book.book_date} ${CommonTrain.strTotime(returntrip_ride_time)}`, 'YYYY-MM-DD k:mm') : null;

    if (before_date) {
      return (dataPromise.data.filter(d => moment(`${d.book_date} ${CommonTrain.strTotime(d.ride_time)}`, 'YYYY-MM-DD k:mm').isAfter(now_date)));
    } else {
      return (dataPromise.data.filter(d => moment(`${d.book_date} ${CommonTrain.strTotime(d.ride_time)}`, 'YYYY-MM-DD k:mm').isAfter(after_date)));
    }

  }
  async setReturnStation() {
    //帰りが登録されていなければ行きの逆方向をセット
    const outbound = this.state.booking_detail.findIndex(x => x.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND);
    const returntrip = this.state.booking_detail.findIndex(x => x.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP);

    if (!(
      returntrip >= 0
      && (this.state.booking_detail[returntrip].ride_station_cd || this.state.booking_detail[returntrip].getoff_station_cd)
    )) {
      var detail = {
        round_type: constClass.TRAIN_ROUND_TYPE.RETURNTRIP,
        book_type: this.state.booking_detail[outbound].book_type,
        ride_station_cd: this.state.booking_detail[outbound].getoff_station_cd,
        getoff_station_cd: this.state.booking_detail[outbound].ride_station_cd,
        ride_station_name: this.state.booking_detail[outbound].getoff_station_name,
        getoff_station_name: this.state.booking_detail[outbound].ride_station_name,
        fare: this.state.booking_detail[outbound].fare,
        train_no: null,
        total: 0,
      }
      this.setState({
        booking_detail: this.state.booking_detail.map(item => (item.round_type === detail.round_type ? detail : item)),
      });
    }
  }
  //変更対象予約情報をセット
  async submitCurrentbooking(book_id, book_no, page) {
    await this.props.setCurrentBook(book_id, book_no);
    const url = `${constClass.CYCLETRAIN_LINKURL}/?${page ? `page=${page}` : ""}${book_id ? `&id=${book_id}` : ""}${book_no ? `&no=${book_no}` : ""}`;
    this.props.history.push(url);
    await this.refreshBookData();
  }

  componentDidMount() {
    this.refreshData();
    this.disableBounceScroll();
  }

  // async componentWillUnmount() {
  //   // clearInterval(this.intervalCount);
  //   window.removeEventListener('popstate', this.resetCustomer, false);
  // }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      // let el = e.target;
      let el = this.pageRef.current;// e.targetが機種によって変動するため、pageに固定
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
        el = el.parentElement;
      }
      // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
      if (noScroll) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }

      touchY = moveY;
    }, { passive: false });
  }

  scrollPageTop() {
    setTimeout(() => {
      let el = this.pageRef.current;// e.targetが機種によって変動するため、pageに固定
      if (el) el.scrollTo(0, 0);
      else window.scrollTo(0, 0);
    }, 1);
  }

  scrollWindowTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }
  /* 乗車区分を変更（全リセット） */
  changeBookType(type) {
    var detail = this.state.booking_detail;
    var alltotal = 0;

    detail.forEach((item, idx) => {
      if (item.train_no) {
        var total = CommonTrain.calcTotal(type !== constClass.TRAIN_BOOK_TYPE.ALL ? 0 : item.fare, this.props.settings.TRAIN_FARE_BRINGIN, this.state.booking);
        detail[idx] = { ...item, total: total };
        alltotal += total;
      }
    });
    this.setState({
      booking: { ...this.state.booking, book_type: type, total: alltotal },
      booking_detail: detail
    });
  }
  changeCustomer(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    var booking = { ...this.state.booking, [name]: value };
    if (name === "customer_kana") booking = { ...booking, customer_name: value };
    this.setState({
      booking
    });
  }
  //乗車対象便の取得と確認
  async setChangeBinDetail(book, book_detail) {
    var binlist = this.state.bookBinList;
    var binmsg = this.state.bookBinListMessage;
    var total = 0;
    await Promise.all(book_detail.map(async (item, idx) => {
      if (book_detail[idx]["ride_station_cd"] && book_detail[idx]["getoff_station_cd"]) {
        const fare = await CommonTrain.getFare(book.book_date, book_detail[idx]["ride_station_cd"], book_detail[idx]["getoff_station_cd"]);
        if (fare === null) {
          alert("運賃が検索できませんでした。最初からやり直してください");
          this.submitCurrentbooking(null, null, null);
          return;

        }
        var bindetail = await this.getBindetail(book, book_detail[idx], book_detail);
        //予約可件数に自身の予約済件数を加算
        if (this.state.booked && this.state.booked.booking_detail && this.state.booking.book_date === this.state.booked.book_date && this.state.booked.booking_detail.findIndex(x => x.round_type === item.round_type) >= 0) {
          const booked_detail = this.state.booked.booking_detail.find(x => x.round_type === item.round_type);
          bindetail.forEach((item2, idx) => {
            if (item2.train_no === booked_detail.train_no) {
              bindetail[idx] = { ...item2, bookok_count: (item2.book_status === constClass.FLAG.OFF) ? 0 : item2.bookok_count + this.state.booked.count_bringin }
            }
          });
        }
        const errmsg = !fare ? "エラーが発生しました"
          : (book_detail[idx].train_no && bindetail.findIndex(x => x.train_no === book_detail[idx].train_no) < 0)
            || (book_detail[idx].train_no && bindetail.findIndex(x => x.train_no === book_detail[idx].train_no) >= 0 && bindetail.find(x => x.train_no === book_detail[idx].train_no).bookok_count < book.count_bringin)
            ? "ご乗車できない便が選択されています。再選択してください" : null;
        book_detail[idx] = {
          ...book_detail[idx],
          fare: fare,
          total: book_detail[idx].train_no ? CommonTrain.calcTotal(book.book_type !== constClass.TRAIN_BOOK_TYPE.ALL ? 0 : fare, this.props.settings.TRAIN_FARE_BRINGIN, book) : 0,
          errcheck: errmsg,
        };
        total += book_detail[idx].total;
        binlist = { ...binlist, [item.round_type]: bindetail };
        binmsg = { ...binmsg, [item.round_type]: bindetail.length <= 0 ? "対象便がありません" : null };
      }
    }));
    this.setState({
      booking: { ...book, total: total },
      booking_detail: book_detail,
      bookBinList: binlist,
      bookBinListMessage: binmsg,
    });
  }
  /* 乗車日変更 */
  async changeBookDate(val) {
    const book = { ...this.state.booking, book_date: val };
    await this.getStationList(val);
    await this.setChangeBinDetail(book, this.state.booking_detail);
  }
  // 人数・台数変更
  async changeCount(name, val) {
    //上限取得
    const bookdate = this.state.booking.book_date ? this.state.bookDateList.find(x => x.book_date === this.state.booking.book_date) : this.state.bookDateList[0];
    var bookok_count = await this.state.booking_detail.reduce((count, item) => {
      var bin_count = 999;

      if (item.train_no && this.state.bookBinList[item.round_type].find(b => b.train_no === item.train_no)) {
        bin_count = this.state.bookBinList[item.round_type].find(b => b.train_no === item.train_no).bookok_count;
      }
      return ((bin_count > count) ? count : bin_count);
    }, bookdate.book_count ? bookdate.book_count : 12);
    var count = this.state.booking[name] + val;
    var count_bringin = this.state.booking['count_bringin'] + val;
    if (count < 0) { count = 0; count_bringin++; }
    if (count_bringin > bookok_count) {
      alert('これ以上予約できません');
      count = this.state.booking[name];
      count_bringin = this.state.booking['count_bringin'];
    }
    var booking = {};
    booking = { ...this.state.booking, [name]: count, count_bringin: count_bringin };
    var detail = this.state.booking_detail.map(item => ({ ...item, total: CommonTrain.calcTotal((this.state.booking.book_type !== constClass.TRAIN_BOOK_TYPE.ALL ? 0 : item.train_no && item.fare ? item.fare : 0), (item.train_no ? this.props.settings.TRAIN_FARE_BRINGIN : 0), booking) }));

    this.setState({ booking: booking, booking_detail: detail });
  }
  // 駅変更
  async changeStation(name, val) {

    var booking_detail = this.state.booking_detail;
    const idx = booking_detail.findIndex(x => x.round_type === this.state.round_type);
    booking_detail[idx] = {
      ...booking_detail[idx],
      [`${name}_station_cd`]: val,
      [`${name}_station_name`]: val ? this.state.stationMaster.find(x => x.station_cd === val).station_name : '',
      train_no: null,
      ride_order_no: null,
      getoff_order_no: null,
      operate_type: null,
      des_station_cd: null,
      des_name: null,
      des_short: null,
      ride_time: null,
      getoff_time: null,
      errcheck: null,
      fare: null,
      total: 0,
    };

    this.setState({
      booking_detail: booking_detail,
      bookBinList: { ...this.state.bookBinList, [this.state.round_type]: [] },
      bookBinListMessage: { ...this.state.bookBinListMessage, [this.state.round_type]: null },
    });
  }

  async searchBin() {
    var booking_detail = this.state.booking_detail;
    const idx = booking_detail.findIndex(x => x.round_type === this.state.round_type);
    const o_idx = booking_detail.findIndex(x => x.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND);
    const r_idx = booking_detail.findIndex(x => x.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP);
    booking_detail[idx] = {
      ...booking_detail[idx],
      train_no: null,
      ride_order_no: null,
      getoff_order_no: null,
      operate_type: null,
      des_station_cd: null,
      des_name: null,
      des_short: null,
      ride_time: null,
      getoff_time: null,
      errcheck: null,
      fare: null,
      total: 0,
    };
    if (booking_detail[idx].getoff_station_cd && booking_detail[idx].ride_station_cd && booking_detail[idx].getoff_station_cd !== booking_detail[idx].ride_station_cd) {
      await this.setChangeBinDetail(this.state.booking, booking_detail, ((idx !== o_idx && booking_detail[o_idx] && booking_detail[o_idx].getoff_time) ? booking_detail[o_idx].getoff_time : null), ((idx !== r_idx && booking_detail[r_idx] && booking_detail[r_idx].ride_time) ? booking_detail[o_idx].ride_time : null));
    } else {
    }
  }
  // 便変更
  changeTrainno(val) {
    const value = val;
    var detail = this.state.booking_detail;
    const idx = this.state.booking_detail.findIndex(x => x.round_type === this.state.round_type);
    if (value) {
      if (value.book_status === constClass.FLAG.OFF || value.bookok_count < this.state.booking.count_bringin) {
        return
      }
    }

    detail[idx] = {
      ...detail[idx],
      train_no: value && value.train_no ? value.train_no : null,
      ride_order_no: value && value.ride_order_no ? value.ride_order_no : null,
      getoff_order_no: value && value.getoff_order_no ? value.getoff_order_no : null,
      operate_type: value && value.operate_type ? value.operate_type : null,
      des_station_cd: value && value.des_station_cd ? value.des_station_cd : null,
      des_name: value && value.des_name ? value.des_name : null,
      des_short: value && value.des_short ? value.des_short : null,
      ride_time: value && value.ride_time ? value.ride_time : null,
      getoff_time: value && value.getoff_time ? value.getoff_time : null,
      errcheck: null,
      total: (value && value.train_no) ? CommonTrain.calcTotal(this.state.booking.book_type !== constClass.TRAIN_BOOK_TYPE.ALL ? 0 : detail[idx].fare, this.props.settings.TRAIN_FARE_BRINGIN, this.state.booking) : 0,
    };
    this.setChangeBinDetail(this.state.booking, detail);
  }
  // 帰りの予約変更
  changeReturntripType(bol) {
    this.setState({ booking: { ...this.state.booking, returntrip_type: bol } });
    if (bol === constClass.FLAG.OFF) {
      const detail = this.state.booking_detail;
      const idx = this.state.booking_detail.findIndex(x => x.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP);
      detail[idx] = { round_type: constClass.TRAIN_ROUND_TYPE.RETURNTRIP, total: 0 };
      this.setState({ booking_detail: detail, bookBinList: { ...this.state.bookBinList, [this.state.booking_detail[idx].round_type]: null } });
    } else {
      this.setReturnStation();
    }
  }
  changeClick(state, page) {
    this.setState(state);
    this.props.history.push(`${constClass.CYCLETRAIN_LINKURL}/?page=${page}`);
  }
  checkInvalid() {
    const c = this.state.booking;
    var inputCheck = false;
    const chk = this.state.booking_detail.find(x => x.round_type === this.state.round_type);
    switch (this.props.page) {
      case constClass.USER:
        inputCheck = (!c.customer_name || !c.customer_kana || !c.customer_address || !c.customer_gender || !c.customer_age_type || !c.customer_name.trim() || !c.customer_kana.trim() || !c.customer_address.trim())
        break;
      case constClass.INPUTALERT:
        inputCheck = (c.privacy_policy !== constClass.FLAG.ON)
        break;
      case constClass.INPUT:
        inputCheck = (!this.state.booking.book_date) || ((this.state.booking.count_adult1 + this.state.booking.count_adult2) <= 0)
        break;
      case constClass.INPUTBIN1:
        inputCheck = !chk || !chk.train_no || !chk.ride_station_cd || !chk.getoff_station_cd
        break;
      case constClass.INPUTBIN2:
        inputCheck = (this.state.booking.returntrip_type === constClass.FLAG.ON && (!chk || !chk.train_no || !chk.ride_station_cd || !chk.getoff_station_cd))
        break;
      case constClass.BOOKMENU:
        inputCheck = !this.state.booking.book_type
        break;
      default:
        break;
    }
    return inputCheck;
  }
  // 予約便の確認 true:有効
  checkBookingValid(booking, booking_detail, bookBinList, settings = null) {
    var errchk = [];
    booking_detail.forEach(detail => {
      if (detail.train_no !== undefined && detail.train_no !== null) {
        const bookbin = bookBinList[detail.round_type].find(x => x.train_no === detail.train_no);
        const count = bookbin ? bookbin.bookok_count : 0;
        if (booking.count_bringin > count) errchk.push(false);
      }
    });
    if (errchk.length > 0) return false;
    return true;
  }
  //同一駅・同一便の予約件数制限
  checkBookingDateCount() {
    var res = 0;
    const book_date = this.state.booking.book_date;
    const list = this.state.bookingDetailList.filter(x => !(constClass.STATUS_CCL.includes(x.status)) && (!this.state.booking.book_id || x.book_id !== this.state.booking.book_id) && x.book_date === book_date);
    this.state.booking_detail.forEach(detail => {
      //同一駅予約は2件まで
      if (list.filter(x => x.ride_station_cd === detail.ride_station_cd).length >= parseInt(this.props.settings.TRAIN_MAX_SAME_STATION)) res = 1;
      //同便・乗車区間が重なる場合にNG
      if (list.filter(x => x.train_no === detail.train_no
        && ((x.ride_order_no >= detail.ride_station_cd && x.ride_order_no <= detail.getoff_station_cd)
          || (x.getoff_order_no >= detail.ride_station_cd && x.getoff_order_no <= detail.getoff_station_cd))
      ).length > 0) res = 2;

    });
    //往復は同便指定NG
    if (this.state.booking_detail.length > 1 && this.state.booking_detail[0].train_no && this.state.booking_detail[1].train_no
      && this.state.booking_detail[0].train_no === this.state.booking_detail[1].train_no
    ) {
      res = 3;
    }
    return res
  }

  resetBooking() {
    const book = this.blankBooking();
    const detail = this.blankBookingDetail();
    this.setState({
      booking: book,
      booking_detail: detail,
      bookBinList: { [constClass.TRAIN_ROUND_TYPE.OUTBOUND]: null, [constClass.TRAIN_ROUND_TYPE.RETURNTRIP]: null },
      bookBinListMessage: { [constClass.TRAIN_ROUND_TYPE.OUTBOUND]: null, [constClass.TRAIN_ROUND_TYPE.RETURNTRIP]: null },
      set_booking: { ...book },
      set_booking_detail: detail.concat(),
      set_bookBinList: { [constClass.TRAIN_ROUND_TYPE.OUTBOUND]: null, [constClass.TRAIN_ROUND_TYPE.RETURNTRIP]: null },
      booked: null,
      round_type: constClass.TRAIN_ROUND_TYPE.OUTBOUND,
      change: false,
    })
  }
  async submit() {
    // API実行
    if (!this.checkBookingValid(this.state.booking, this.state.booking_detail, this.state.bookBinList)) {
      alert('指定の台数の予約が取れません。便または予約台数の変更をお願いします。');
      return false;
    }
    const check_res = this.checkBookingDateCount();
    if (check_res === 1) {
      alert(`同日、同一駅出発分の予約は${this.props.settings.TRAIN_MAX_SAME_STATION}件までとなりますので、ご予約できません`);
      return false;
    }
    if (check_res === 2) {
      alert('同日・同一列車で、乗車区間が重なる予約が登録済です。ご予約できません');
      return false;
    }
    if (check_res === 3) {
      alert('同一列車での予約がされています。ご予約できません');
      return false;
    }

    try {
      const detail = this.state.booking_detail.filter(x => (x.train_no)).map((item) => ({
        site_id: this.state.siteId,
        book_id: item.book_id ? item.book_id : null,
        book_no: item.book_no ? item.book_no : null,
        book_type: this.state.booking.book_type,
        round_type: item.round_type,
        train_no: item.train_no,
        ride_order_no: item.ride_order_no,
        getoff_order_no: item.getoff_order_no,
        ride_station_cd: item.ride_station_cd,
        getoff_station_cd: item.getoff_station_cd,
        operate_type: item.operate_type,
        des_station_cd: item.des_station_cd,
        ride_time: item.ride_time,
        getoff_time: item.getoff_time,
        fare: item.fare,
        count_adult1: this.state.booking.count_adult1,
        count_child1: this.state.booking.count_child1,
        count_adult2: this.state.booking.count_adult2,
        count_child2: this.state.booking.count_child2,
        count_bringin: this.state.booking.count_bringin,
        fare_bringin: this.props.settings.TRAIN_FARE_BRINGIN,
        total: CommonTrain.calcTotal(this.state.booking.book_type !== constClass.TRAIN_BOOK_TYPE.ALL ? 0 : item.fare, this.props.settings.TRAIN_FARE_BRINGIN, this.state.booking),
        status: item.book_id ? item.status : constClass.STATUS.UPAY,
      })
      );
      var data = {
        site_id: this.state.siteId,
        book_id: this.state.booking.book_id ? this.state.booking.book_id : null,
        book_date: new Date(this.state.booking.book_date),
        customer_id: this.state.booking.customer_id,
        receipt_num: (this.state.booking.book_id) ? this.state.booking.receipt_num : null,
        receipt_datetime: (this.state.booking.book_id) ? new Date(this.state.booking.receipt_datetime) : new Date(),
        customer_name: this.state.booking.customer_name,
        customer_kana: this.state.booking.customer_kana,
        customer_address: this.state.booking.customer_address,
        customer_gender: this.state.booking.customer_gender,
        customer_age_type: this.state.booking.customer_age_type,
        privacy_policy: this.state.booking.privacy_policy,
        line_id: this.props.liff_access_token,
        subtotal: 0, discount: 0, tax: 0,
        total: CommonTrain.getTotal(detail),
        before_total: (this.state.booked && this.state.booked.total) ? this.state.booked.total : null,
        payment_fin_flag: (this.state.booking.book_id) ? this.state.booking.payment_fin_flag : constClass.FLAG.OFF,
        status: (this.state.booking.book_id) ? this.state.booking.status : constClass.STATUS.UPAY, //初期ステータスは未決済
        train_booking_detail: detail,
        before_booking_detail: (this.state.booking.book_id) ? this.state.booked.booking_detail : null,
      }

      //if (!window.confirm(`${this.state.booking.book_id ? "変更" : "登録"}しますか？`)) {
      //  return;
      //}
      this.setState({ disabled: true });
      var result = null;
      if (this.state.booking.book_id) {
        result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/train_booking/${data.book_id}`, data));
      } else {
        result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/`, data));
      }
      this.setState({ disabled: false });

      if (result.data && result.data.result) {
        const book_id = result.data && result.data.result && result.data.book && result.data.book.book_id ? result.data.book.book_id : data.book_id;
        //alert('登録しました。');
        if (book_id && result.data.book && result.data.book.status === constClass.STATUS.UPAY) {
          this.props.setCurrentBook(book_id, null);
          await this.refreshBookData();
          await this.submitPayment(book_id);
        } else {
          this.submitCurrentbooking(null, null, constClass.FINISH);
        }
      } else {
        //err
        console.log(util.inspect(result))
        alert('登録に失敗しました。1');
      }
    } catch (e) {
      var errMsg = "";

      if (e.fileName && e.lineNumber) {

        // ファイル名と行番号が取得できたらメッセージとしてログに出力する
        errMsg = "ファイル名：" + e.fileName + "、 行番号：" + e.lineNumber;
      } else {

        errMsg = e.message;
      }

      //例外エラーがおきたら、コンソールにログを出力する
      console.error("エラー：", errMsg);
      alert('登録に失敗しました。2');
    }
    return false;
  }

  async submitPayment(book_id) {
    console.log("submitPayment");
    this.setState({ disabled: true });
    const params = {
      Overview: this.props.settings.TITLE_SHORT_NAME,
    };
    const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/train_booking/payment/${this.props.siteId}/${book_id}`, params)).data;
    //console.log("payment_data", payment_data)
    // urlがある⇒画面遷移
    if (payment_data.url) {
      const form = document.createElement('form');
      form.action = payment_data.url;
      form.method = payment_data.method;

      // body に追加
      document.body.append(form);
      for (const i in payment_data.data) {
        // obj.hasOwnProperty() はオブジェクトのプロトタイプチェーンからプロパティを絞り込むために使用しています
        if (payment_data.data.hasOwnProperty(i)) {
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', i);
          input.setAttribute('value', payment_data.data[i]);
          form.appendChild(input);
        }
      }

      // submit
      form.submit();
    } else {
      // urlが無い⇒paymentモジュール画面へ遷移
      this.setState({ payment_data: payment_data.data, disabled: false });
      this.props.history.push(`${constClass.CYCLETRAIN_LINKURL}/?page=${constClass.PAYMENT}&id=${book_id}`);
    }
  }
  async backPayment() {
    console.log("backPayment")
    await this.refreshBookData();
  }
  async returnPayment() {
    console.log("returnPayment")
    await this.refreshBookData();
  }

  renderHeader() {
    const titlename = () => {
      switch (this.props.page) {
        case constClass.USER: return `お客様情報`;
        case constClass.INPUTALERT: return `注意事項`;
        case constClass.INPUT: return `予約登録`;
        case constClass.INPUTBIN1: return `予約登録`;
        case constClass.INPUTBIN2: return `予約登録`;
        case constClass.BOOKMENU: return `予約登録`;
        case constClass.CONFIRM: return `予約登録`;
        case constClass.PAYMENT: return `決済`;
        case constClass.FINISH: return `予約完了`;
        case constClass.CHANGECONFIRM: return `予約内容確認`;
        case constClass.HISTORY: return `ご予約履歴・変更`;
        case constClass.CANCEL: return `キャンセル`;
        case constClass.RIDEQR: return `改札`;
        case constClass.RIDETICKET: return `乗車`;
        case constClass.GETOFFTICKET: return `降車`;
        default: return `メニュー`;
      }
    }
    return (
      <header className="header">
        <div className="row mx-0">
          <div className={`col section text-center`}>
            {titlename(this)}
          </div>
        </div>
      </header>
    );
  }
  renderPagetitle() {
    return (
      <div>
        {/* <div className="row mx-0">
              <div className="col section py-0 line-height-2-2">
                <span className="d-inline-block align-middle">{titlename(this)}</span>
              </div>
            </div> */}
      </div>
    );
  }
  async getRidedata(bookingDetailList, station_cd = null) {
    const nowDate = moment().format('YYYYMMDD')
    var ride = null;
    var list = [];
    await Promise.all(bookingDetailList.map(async item => {
      if (moment(item.book_date).format('YYYYMMDD') === nowDate && constClass.STATUS.RDE === item.status) ride = item;
      else
        if (constClass.STATUS_RDEOK.includes(item.status)
          && moment(item.book_date).format('YYYYMMDD') === nowDate
          //&& moment(`${item.book_date} ${CommonTrain.strTotime(item.ride_time)}`, 'YYYY-MM-DD k:mm').isAfter() //乗車時間は見ない
        ) {
          const res = bookingDetailList.find(x => (x.book_id === item.book_id && x.book_no !== item.book_no));
          //往路未乗車の場合の復路は乗車対象外
          if (!(item.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && res && constClass.STATUS_PRE.includes(res.status))) {
            list.push(item);
          }
        }
    }));

    if (ride !== null) return ride;
    if (list.length > 0) {
      if (station_cd && list.findIndex(x => x.ride_station_cd === station_cd) >= 0) {
        return list.sort((a, b) => a.ride_time - b.ride_time).find(x => x.ride_station_cd === station_cd);
      }
      return list.sort((a, b) => a.ride_time - b.ride_time)[0];
    }
    else return null
  }
  renderRideButton() {
    return (
      <div className="row mx-0 mt-3 mb-3 text-center">
        <div className="col px-0">
          <button
            onClick={async (e) => this.submitCurrentbooking(this.state.ride ? this.state.ride.book_id : null, this.state.ride ? this.state.ride.book_no : null, constClass.RIDEQR)}
            className={`btn btn-primary w-100 h-100 py-3 px-2`}>
            <div className='row m-0 p-0'>
              <div className='col-2 m-0 p-0 pl-2 text-left align-self-center'><img src={TrainImg} style={{ width: "40px" }} alt={this.state.ride && this.state.ride.status === constClass.STATUS.RDE ? "降車" : "乗車"} /></div>
              <div className='col-8 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">{this.state.ride && this.state.ride.status === constClass.STATUS.RDE ? "降車" : "乗車"}する</span></div>
              <div className='col-2 m-0 p-0 pr-2 text-right align-self-center'><img src={CycleImg} style={{ width: "36px" }} alt={this.state.ride && this.state.ride.status === constClass.STATUS.RDE ? "降車" : "乗車"} /></div>
            </div>
          </button>

        </div>
      </div>
    )
  }
  renderMenu() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col py-4 text-center">
              <img className='w-50' src={`/${this.props.siteId}/ct__main.svg`} alt="cycletrain" />
            </div>
          </div>
          {(("CONTRACT_PERIOD_STARTTIME" in this.props.settings) && parseInt(this.props.settings.CONTRACT_PERIOD_STARTTIME) > parseInt(moment().format('YYYYMMDDkkmm')))
            ?
            <div className="mx-3 px-3-env bg-green text-white h5 text-center">{this.props.settings.CONTRACT_PERIOD_BEFORE_MESSAGE}</div>
            :
            <div>
              <div className="row mx-0 px-3-env pt-2 pb-1">
                <div className="col py-1 px-1 align-self-center">
                  <div className="row mx-0 mt-3 mb-3 text-center">
                    <div className="col px-0">
                      <Link to={`${constClass.CYCLETRAIN_LINKURL}/?page=${constClass.INPUTALERT}`}>
                        <button
                          onClick={e => this.resetBooking()}
                          className={`btn btn-primary btn-booking w-100 h-100 py-3 px-2`}>
                          <div className='row m-0 p-0'>
                            <div className='col-1 m-0 p-0 pl-2 align-self-center'><img src={`/${this.props.siteId}/icon_booking.png`} style={{ width: "2rem" }} alt="ご予約" /></div>
                            <div className='col-10 m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">ご予約はこちら</span></div>
                            <div className='col-1 m-0 align-self-center'></div>
                          </div>
                        </button>
                      </Link>
                    </div>
                  </div>
                  {
                    this.renderRideButton()
                  }
                  <div className="row mx-0 my-3 text-center">
                    <div className="col-6 px-0 pr-1">
                      <Link to={`${constClass.CYCLETRAIN_LINKURL}/?page=${constClass.HISTORY}`}>
                        <button
                          className={`btn btn-primary w-100 h-100 py-2 px-2`}>
                          <img src={`/${this.props.siteId}/icon_list.png`} style={{ width: "2rem" }} alt="list" /><br />
                          <span className="mb-0 h5 font-weight-bold">ご予約履歴<br />・変更</span>
                        </button>
                      </Link>
                    </div>
                    <div className="col-6 px-0 pl-1">
                      <a href={Common.getExternalUrl(this.props.settings.ATTENTION_URL)} target="_blank" rel="noreferrer">
                        <button
                          className={`btn btn-primary w-100 h-100 py-2 px-2`}>
                          <img src={`/${this.props.siteId}/icon_attention.png`} style={{ width: "2rem" }} alt="attention" /><br />
                          <span className="mb-0 h5 font-weight-bold">注意事項</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
        </div>
      </div>
    );
  }
  renderBookMenu() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col bg-white px-0 pb-3 mb-3">
          <div id="bookmenu">
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>予約する種類を選んでください</div>
            </div>
            <div className='px-4 py-2'>
              持ち込み料金：<span className='text-green'>300円</span><br />
              定期券利用等以外の場合は乗車券を購入頂くと当日スムーズなご利用が可能です<br />

            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className={`col-12 text-left bg-white py-2 px-0 btn ${this.state.booking.book_type === constClass.TRAIN_BOOK_TYPE.ALL ? "border-green bg-lightyellow" : "border-gray"}`}>
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input" id={`book_type_${constClass.TRAIN_BOOK_TYPE.ALL}`} name="book_type" onChange={e => this.changeBookType(constClass.TRAIN_BOOK_TYPE.ALL)} value={constClass.TRAIN_BOOK_TYPE.ALL} checked={this.state.booking.book_type === constClass.TRAIN_BOOK_TYPE.ALL} />
                  <label className="custom-control-label w-100 " htmlFor={`book_type_${constClass.TRAIN_BOOK_TYPE.ALL}`}>{constClass.TRAIN_BOOK_TYPE_NAME.ALL}</label>
                </div>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className={`col-12 text-left bg-white py-2 px-0 btn ${this.state.booking.book_type === constClass.TRAIN_BOOK_TYPE.BRINGIN ? "border-green bg-lightyellow" : "border-gray"}`}>
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input" id={`book_type_${constClass.TRAIN_BOOK_TYPE.BRINGIN}`} name="book_type" onChange={e => this.changeBookType(constClass.TRAIN_BOOK_TYPE.BRINGIN)} value={constClass.TRAIN_BOOK_TYPE.BRINGIN} checked={this.state.booking.book_type === constClass.TRAIN_BOOK_TYPE.BRINGIN} />
                  <label className="custom-control-label w-100 " htmlFor={`book_type_${constClass.TRAIN_BOOK_TYPE.BRINGIN}`}>{constClass.TRAIN_BOOK_TYPE_NAME.BRINGIN}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderUser() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col bg-white px-0 mb-3">
          <div id="user">
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>お客様情報をご入力ください</div>
            </div>
            <div className="px-3-env py-2">※複数人でご利用される場合は、代表者の情報をご入力ください。</div>
            {/* <div className="row mx-0 px-3-env pt-2">
              <div className="col px-0 text-green font-weight-bold">
                お名前
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="customer_name" name="customer_name" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.customer_name} />
              </div>
            </div> */}
            <div className="row mx-0 px-3-env pt-2">
              <div className="col px-0 text-green font-weight-bold">
                お名前(カナ)
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="customer_kana" name="customer_kana" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.customer_kana} placeholder="例）ニシテツ タロウ" />
                <span className='small'>※カタカナで氏名をご入力ください。</span>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2">
              <div className="col px-0 text-green font-weight-bold">
                居住地（市町村）
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              <div className="col px-0">
                <input type="text" className="form-control" id="customer_address" name="customer_address" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.customer_address} />
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2">
              <div className="col px-0 text-green font-weight-bold">
                性別
              </div>
            </div>
            <div className="row mx-0 px-3-env text-left form-group">
              {constClass.CUSTOMER_GENDER.map((customer_gender, idx) => (
                <div key={idx} className={`col mx-1 px-3-env align-self-center form-control custom-radio ${customer_gender === this.state.booking.customer_gender ? "border-green bg-lightyellow" : "border-gray"}`}>
                  <input type="radio" className="custom-control-input" id={`customer_gender_${customer_gender}`} name="customer_gender" onChange={e => this.changeCustomer(e)} value={customer_gender} onBlur={this.scrollWindowTop} checked={this.state.booking.customer_gender === customer_gender} />
                  <label className="custom-control-label text-nowrap" htmlFor={`customer_gender_${customer_gender}`}>{constClass.CUSTOMER_GENDER_NAME[customer_gender]}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="row mx-0 px-3-env pt-2">
            <div className="col px-0 text-green font-weight-bold">
              年代
            </div>
          </div>
          <div className="row mx-0 px-3-env text-left form-group">
            <select className={`form-control ${this.state.booking.customer_age_type ? "bg-lightyellow" : ""}`} id="customer_age_type" name="customer_age_type" onChange={e => this.changeCustomer(e)} onBlur={this.scrollWindowTop} value={this.state.booking.customer_age_type || ''}>
              <option value="">-- 選択 --</option>
              {constClass.CUSTOMER_AGE_TYPE.map((customer_age_type) => (
                <option value={customer_age_type} key={customer_age_type}>{constClass.CUSTOMER_AGE_TYPE_NAME[customer_age_type]}</option>
              ))}
            </select>
          </div>
        </div>
      </div >
    );
  }
  renderInputAlert() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0 pb-3">
          <div className="row m-0 px-0-env">
            <div className="col bg-white px-0 mb-3">
              <div id="inputalert">
                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className="col ml-0 pl-0">
                    <div className='pt-3 pb-3 ml-0 pl-0 font-weight-bold text-alert'>必読</div>
                    <div className='text-alert font-weight-bold'>
                      <ul className='pl-3'>
                        <li className="py-1" style={{ listStyleType: `"〇"` }} >エレベーターは、車いすやベビーカーをご利用のお客さま等、必要としているお客さまのご利用を優先してください。</li>
                        <li className="py-1" style={{ listStyleType: `"〇"` }}>列車を乗降する際及び車いすスペースでは、車いすやベビーカーをご利用のお客さまを優先してください。<br />
                          ※車いすやベビーカーをご利用のお客さまが、車いすスペースにご乗車の際は、自転車を移動させてください。</li>
                        <li className="py-1" style={{ listStyleType: `"〇"` }}>乗務員室出入口付近への駐輪は禁止いたします。</li>
                        <li className="py-1" style={{ listStyleType: `"〇"` }}>ご自身および周囲に対する安全は、お客さまの責任で管理してください。<br />
                          ※お客さま同士のトラブルには、当社は関与いたしません。</li>
                        <li className="py-1" style={{ listStyleType: `"〇"` }}>持ち込んだ自転車の汚損またはき損などの損害は賠償いたしません。</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 px-3-env pt-2 pb-1">
                  <div className='col text-center'>
                    詳細はこちらをご確認ください
                  </div>
                </div>
                <div className="row mx-0 px-3-env">
                  <div className='col text-center'>
                    ↓
                  </div>
                </div>
                <div className="row mx-0 px-3-env">
                  <div className="col ml-0 pl-0">
                    <div className='pt-3 ml-0 pl-0 font-weight-bold'>１.乗車券・持込料金のルール</div>
                    <ul className='pl-4'>
                      <li>同日、同一駅出発分の予約は2件までとなります。</li>
                      <li>改札前にキャンセルまたは乗車する列車を変更する際は、お客さまご自身でお願いいたします。</li>
                      <li>改札後にキャンセルされる場合は、駅係員へお申し出ください。（キャンセルできないこともございます）</li>
                      <li>ご予約はご乗車日の1カ月前から乗車希望対象列車出発の10分前まで受付いたします。<br />
                        （例：5月14日の列車予約は4月15日からの受付となります。）</li>
                    </ul>
                    <div className='pt-3 font-weight-bold'>２．自転車持ち込みのルール</div>
                    <ul className='pl-4'>
                      <li>ご自身および周囲に対する安全は、お客さまの責任で管理してください。<br />
                        ※お客さま同士のトラブルには、当社は関与いたしません。</li>
                      <li>ご利用される際はお１人様１台のみ持ち込み可能です。</li>
                      <li>長さ180cm/幅45㎝（ハンドル幅を除く）サイズの自転車まで持ち込み可能です。<br />
                        <div className='text-alert'>※持ち込みできない自転車<br />
                          ①上記サイズ以上の自転車<br />
                          ②原動機付自転車<br />
                          ③ブレーキがない自転車<br />
                          ④著しく汚れた自転車</div></li>
                      <li>持込料金については、１乗車につき１回とし、途中下車された際は再度乗車希望対象列車のご予約が必要になります。</li>
                      <li>小学生以下のお客さまもご利用いただけます。この場合、保護者が同伴し、お子さまの安全と自転車の管理等をお願いします。</li>
                      <li>駅構内、階段および車内などでの自転車の移動・積み降ろしは、お客さまご自身で他のお客さまと距離を空けて行ってください。</li>
                      <li>折りたたみ自転車等は袋に収納すれば、全ての列車にご乗車できます。</li>
                    </ul>

                    <div className='pt-3 font-weight-bold'>３.駅構内でのお願い</div>
                    <ul className='pl-4'>
                      <li>ご予約時に乗車列車の車両番号を指定いたしますので、ご利用時には駅ホーム上に表示した号車番号でお待ちいただき、号車番号前に停止した車両にご乗車ください。<br />
                        （編成数によってご乗車する位置が異なりますので、ご乗車の際には編成数をご確認ください。）</li>
                      <li>エレベーターは車いすやベビーカーをご利用のお客さま等、必要としているお客さまのご利用を優先してください。</li>
                      <li>一部の駅（大橋駅、新栄町駅）においてエレベーターで自転車をお運びすることができませんので、ご利用の際は、お客さまご自身での運搬をお願いします。また、エスカレーターは使用できません。</li>
                      <li>通路や駅ホームでは点字ブロックの上に自転車を停めないでください。</li>
                      <li>駅ホームでは、自転車も含め白い線または黄色い線（点字ブロック）の内側で列車をお待ちください。</li>
                      <li>駅構内や車内での移動時は危険ですので絶対に自転車に乗らないでください。また、駅構内の狭い場所などで歩行者とすれ違う時は、一旦停止のうえ、歩行者に道をお譲りください。</li>
                      <li>線路に自転車を落とした場合は、駅備え付けのＳＯＳボタンを押してください。</li>
                    </ul>

                    <div className='pt-3 font-weight-bold'>４．列車内でのお願い</div>
                    <ul className='pl-4'>
                      <li>列車の乗り降りの際は、車いすやベビーカーをご利用のお客さまを優先してください。<br />
                        また、車いすご利用のお客さま等が、乗降されている場合は、他の扉をご利用ください。</li>
                      <li>進行方向に向かって、右側の位置に駐輪してください。</li>
                      <li>乗務員室出入口付近への駐輪は禁止いたします。</li>
                      <li>自転車は転倒・滑走しないようベルトで固定し、列車の揺れ、急停車に備えご自身で支えてください。</li>
                      <li>車いすスペースでは、車いすやベビーカーをご利用のお客さまを優先してください。<br />
                        ※車いすやベビーカーをご利用のお客さまが、ご乗車の際は、自転車を移動させてください。</li>
                      <li>混雑した列車をご利用の際は、必ず周りのお客さまにお声かけをお願いします。</li>
                      <li>列車内での自転車の移動は列車が停止してからご移動ください。</li>
                    </ul>

                    <div className='pt-3 font-weight-bold'>５．その他</div>
                    <ul className='pl-4'>
                      <li>事故、災害等の影響でサイクルトレインをご利用できない場合がございますので、お客さまご自身でキャンセル対応をお願いいたします。</li>
                      <li>事故・災害等により、列車の遅延、列車の運行ができない場合に実施するバスやタクシーによる代替輸送の場合には、自転車を持ち込むことはできません。</li>
                      <li>持ち込んだ自転車の汚損またはき損などの損害は賠償いたしません。</li>
                      <li>本サービスの利用にあたり自転車損害賠償責任保険等または施設賠償責任保険に加入することを推奨します。</li>
                      <li>二次元コードが上手く読み取れなかった場合等は、駅窓口にお申し出ください。</li>
                      <li>係員から駐輪場所や固定方法に関してお願いをさせていただくことがあります。</li>
                      {("SPECIFIED_COMMERCIAL_TRANSACTIONS_URL" in this.props.settings) && <li>特定商取引法については<a href={Common.getExternalUrl(this.props.settings.SPECIFIED_COMMERCIAL_TRANSACTIONS_URL)} target="_blank" rel="noreferrer"><u>こちら</u></a></li>}
                      {("TERMS_OF_SERVICE_URL" in this.props.settings) && <li>利用規約については<a href={Common.getExternalUrl(this.props.settings.TERMS_OF_SERVICE_URL)} target="_blank" rel="noreferrer"><u>こちら</u></a></li>}
                      {("PRIVACYPOLICY_URL" in this.props.settings) && <li>個人情報の取り扱いについては<a href={Common.getExternalUrl(this.props.settings.PRIVACYPOLICY_URL)} target="_blank" rel="noreferrer"><u>こちら</u></a></li>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 px-3-env pt-2 pb-1">
            <div className='col'>
              <div className="row mx-0 px-3-env pt-2 pb-1">
                <div className='col-12 text-center bg-white py-2'>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="privacy_policy" name="privacy_policy" value="true" checked={this.state.booking.privacy_policy === constClass.FLAG.ON} onChange={e => this.changeCustomer(e)} />
                    <label className="custom-control-label m-1" htmlFor="privacy_policy">注意事項に同意する</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderInputCount() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col bg-white px-0 mb-3">
          <div id="book">
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>乗車日を選択してください</div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-auto text-green font-weight-bold p-1">
                乗車日
              </div>
              <div className="col">
                <select className="custom-select w-100 text-center" aria-label="乗車日"
                  value={this.state.booking.book_date || ''}
                  onChange={(e) => this.changeBookDate(e.target.value)}
                  onBlur={this.scrollWindowTop}>
                  <option value="" >- 選択 -</option>
                  {this.state.bookDateList.map((item, idx) =>
                    <option key={idx} value={item.book_date}>{moment(item.book_date).format('YYYY/MM/DD')}({moment(item.book_date).format('dd')})</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>乗車人数(台数)を入力してください</div>
            </div>
            {constClass.COUNT_PASSENGER_LIST.map((item, idx) =>
              <div className="row mx-0 px-3 py-2 border-bottom" key={idx}>
                <div className="col text-green font-weight-bold p-1 small">
                  {item.text}
                </div>
                <div className="col-auto text-center bg-lightwhite p-1 align-self-center">
                  <div className='d-flex px-1'>
                    <div className='align-self-center'>
                      <button
                        disabled={this.state.disabled}
                        className={`btn btn-active btn-circle rounded-circle p-0`}
                        onClick={() => { this.changeCount(item.name, -1) }}>
                        －
                      </button>
                    </div>
                    <div className='align-self-center'>
                      <div className='h5 my-0 py-0 px-2' style={{ width: "2.2em" }}>{this.state.booking[item.name]}</div>
                    </div>
                    <div className='align-self-center'>
                      <button
                        disabled={this.state.disabled}
                        className={`btn btn-active btn-circle rounded-circle p-0`}
                        onClick={() => { this.changeCount(item.name, 1) }}>
                        ＋
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mx-0 bg-white px-3-env pt-4 pb-1 small">
              <div className='col-6 mx-0 px-0'>
                <div className='row mx-0 px-0'><div className='col-auto mx-0 px-0 text-green font-weight-bold'>大人</div><div className='col mx-1 px-0'>中学生以上のお客さま</div></div>
              </div>
              <div className='col-6 mx-0 px-0'>
                <div className='row  mx-0 px-0'><div className='col-auto mx-0 px-0 text-green font-weight-bold'>小児</div><div className='col mx-1 px-0'>小学生のお客さま</div></div>
              </div>
            </div>
            <div className="row mx-0 bg-white px-3-env pt-2">
              <div className='col mx-0 px-0 small'>
                ※大人・小児の区分について<a href={Common.getExternalUrl(Common.getSettingValue(this.props.settings, "TRAIN_RULE1_URL", "https://www.nishitetsu.jp/train/kippu/rule/"))} target="_blank" rel="noreferrer"><u>詳しくはこちら</u></a>をご覧ください<br />
              </div>
            </div>
            <div className="row mx-0 bg-white px-3-env pt-2 pb-4">
              <div className='col mx-0 px-0 small'>
                ※障がい者または介護者について<a href={Common.getExternalUrl(Common.getSettingValue(this.props.settings, "TRAIN_RULE2_URL", "https://www.nishitetsu.jp/train/kippu/rule/"))} target="_blank" rel="noreferrer"><u>詳しくはこちら</u></a>をご覧ください<br />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }

  renderInputBin() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col bg-white px-0 mb-3">
          <div id="book">
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>{`${constClass.TRAIN_ROUND_TYPE[this.state.round_type]}の`}便を選択してください。</div>
            </div>
            {this.props.page === constClass.INPUTBIN2 &&
              <React.Fragment>
                <div className='px-3-env py-2'>翌日以降の日程でご予約されたい際は、お手数ですが行き帰り別々にご予約ください</div>
                <div className="row mx-0 pl-3 pr-1 text-left form-group">
                  <div className='col-auto mx-0 p-0 align-self-center text-green font-weight-bold'>帰りの便</div>
                  <div className='col'>
                    <div className='row'>
                      <div className={`col mx-2 pl-3 pr-0 align-self-center form-control custom-radio ${constClass.FLAG.ON === this.state.booking.returntrip_type ? "bg-lightyellow border-green" : "border-gray"}`}>
                        <input type="radio" className="custom-control-input" id={`returntrip_type_${constClass.FLAG.ON}`} name="returntrip_type" onChange={e => this.changeReturntripType(constClass.FLAG.ON)} value={constClass.FLAG.ON} checked={this.state.booking.returntrip_type === constClass.FLAG.ON} />
                        <label className="custom-control-label w-100 text-nowrap" htmlFor={`returntrip_type_${constClass.FLAG.ON}`}>予約する</label>
                      </div>
                      <div className={`col mx-2 pl-3 pr-0 align-self-center form-control custom-radio ${constClass.FLAG.OFF === this.state.booking.returntrip_type ? "bg-lightyellow border-green" : "border-gray"}`}>
                        <input type="radio" className="custom-control-input" id={`returntrip_type_${constClass.FLAG.OFF}`} name="returntrip_type" onChange={e => this.changeReturntripType(constClass.FLAG.OFF)} value={constClass.FLAG.OFF} checked={this.state.booking.returntrip_type === constClass.FLAG.OFF} />
                        <label className="custom-control-label w-100 text-nowrap" htmlFor={`returntrip_type_${constClass.FLAG.OFF}`}>予約しない</label>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-3 mx-0 p-0 align-self-center text-green font-weight-bold">
                乗車駅
              </div>
              <div className="col">
                <select className={`custom-select w-100 text-center ${(this.state.booking_detail.find(x => x.round_type === this.state.round_type) && this.state.booking_detail.find(x => x.round_type === this.state.round_type).ride_station_cd) ? "bg-lightyellow" : ""}`} aria-label="乗車駅"
                  disabled={(this.props.page === constClass.INPUTBIN2 && this.state.booking.returntrip_type === constClass.FLAG.OFF) ? true : false}
                  value={(this.state.booking_detail.find(x => x.round_type === this.state.round_type) && this.state.booking_detail.find(x => x.round_type === this.state.round_type).ride_station_cd) ? this.state.booking_detail.find(x => x.round_type === this.state.round_type).ride_station_cd : ''}
                  onChange={(e) => this.changeStation("ride", e.target.value)} onBlur={this.scrollWindowTop}>
                  <option value="" >- 選択 -</option>
                  {this.state.stationMaster.map((item) =>
                    <option key={item.station_cd} value={item.station_cd}>{item.station_name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row mx-0 px-3-env">
              <div className="col-3 mx-0 p-0 text-green font-weight-bold">
              </div>
              <div className="col text-green font-weight-bold text-center">
                ↓
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1">
              <div className="col-3 mx-0 p-0 text-green font-weight-bold">
                降車駅
              </div>
              <div className="col">
                <select className={`custom-select w-100 text-center ${(this.state.booking_detail.find(x => x.round_type === this.state.round_type) && this.state.booking_detail.find(x => x.round_type === this.state.round_type).getoff_station_cd) ? "bg-lightyellow" : ""}`} aria-label="降車駅"
                  disabled={(this.props.page === constClass.INPUTBIN2 && this.state.booking.returntrip_type === constClass.FLAG.OFF) ? true : false}
                  value={(this.state.booking_detail.find(x => x.round_type === this.state.round_type) && this.state.booking_detail.find(x => x.round_type === this.state.round_type).getoff_station_cd) ? this.state.booking_detail.find(x => x.round_type === this.state.round_type).getoff_station_cd : ''}
                  onChange={(e) => this.changeStation("getoff", e.target.value)} onBlur={this.scrollWindowTop}>
                  <option value="" >- 選択 -</option>
                  {this.state.stationMaster.map((item) =>
                    <option key={item.station_cd} value={item.station_cd}>{item.station_name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row mx-0 px-3-env">
              <div className="col text-green font-weight-bold text-center">
                <button className='btn btn-active m-2 px-5'
                  disabled={(this.props.page === constClass.INPUTBIN2 && this.state.booking.returntrip_type === constClass.FLAG.OFF) ? true : false}
                  onClick={e => this.searchBin()}>検索</button>
              </div>
            </div>
            {(this.state.booking_detail.find(x => x.round_type === this.state.round_type).ride_station_cd && this.state.booking_detail.find(x => x.round_type === this.state.round_type).getoff_station_cd
              && this.state.bookBinList[this.state.round_type] && (this.props.page !== constClass.INPUTBIN2 || this.state.booking.returntrip_type === constClass.FLAG.ON)) &&
              <React.Fragment>
                <ul className='list-group'>
                  {this.state.bookBinListMessage[this.state.round_type] && <li className={`px-2 list-group-item align-items-center`}>{this.state.bookBinListMessage[this.state.round_type]}</li>}
                  {!this.state.bookBinListMessage[this.state.round_type] && this.state.bookBinList[this.state.round_type].map((item, idx) => (
                    <li key={idx}
                      className={`px-2 list-group-item ${item.book_status === constClass.FLAG.ON ? "list-group-item-link" : ""} d-flex justify-content-between align-items-center ${(this.state.booking_detail.find(x => x.round_type === this.state.round_type).train_no === item.train_no) ? 'bg-lightyellow' : ''}`}
                      onClick={e => this.changeTrainno(item)}
                    >
                      <div className='row w-100'>
                        <div className='col'>
                          <div className='row'>
                            <div className='col'>
                              <span className={`${(this.state.booking_detail.find(x => x.round_type === this.state.round_type).train_no === item.train_no) ? 'text-green' : ""}`}>
                                {CommonTrain.strTotime(item.ride_time)}発 → {CommonTrain.strTotime(item.getoff_time)}着</span><br />
                              <span className='border'>{constClass.TRAIN_OPERATE_NAME[item.operate_type]}</span>
                            </div>
                            <div className='col-auto px-2 text-center d-flex align-items-center'>
                              残数：{(item.book_status === constClass.FLAG.OFF || item.bookok_count < this.state.booking.count_bringin) ? "×" : item.bookok_count}
                            </div>
                          </div>
                          {(item.book_status === constClass.FLAG.OFF && item.book_stop_message) && <div className='row'><div className='col text-red small'>予約停止：{item.book_stop_message}</div></div>}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            }
          </div>
        </div>
        <div className='row mx-0 px-3-env pt-2 pb-1'>
          予約の変更は、駅改札以前であればお客様で対応可能です（変更に関して手数料等は発生しません）
        </div>
      </div >
    );

  }

  renderConfirm() {
    var total = null;
    total = CommonTrain.getTotal(this.state.booking_detail, (this.state.booking.refund_total ? this.state.booking.refund_total : 0));
    return (
      total >= 0 &&
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0 pb-2">
          <div id="bookinputcheck">
            {!this.state.change && <React.Fragment>
              {[constClass.STATUS.REG, constClass.STATUS.PRE].includes(this.state.booking.status) &&
                <h4 className='text-center px-3-env py-2'>
                  予約済
                </h4>
              }
              {this.state.booking.status === constClass.STATUS.FIN &&
                <h4 className='text-center px-3-env py-2'>
                  降車済
                </h4>
              }
              {/* {this.state.booking.status === constClass.STATUS.RDE &&
              <h4 className='text-center px-3-env py-2'>
                乗車済
              </h4>
            } */}
              {this.state.booking.status === constClass.STATUS.CCL &&
                <h4 className='text-center px-3-env py-2'>
                  キャンセル済
                </h4>
              }
              {this.state.booking.status === constClass.STATUS.CUPAY &&
                <h5 className='text-center px-3-env py-2 bg-white'>
                  決済期限切れです。<br />
                  お手数ですが、再度やり直してください。
                </h5>
              }
            </React.Fragment>}
            {(!this.state.change && this.state.booking.status === constClass.STATUS.UPAY
              && this.state.booking_detail.findIndex(x => (CommonTrain.checkChangeTime(x.book_date, x.ride_time, this.props.settings.TRAIN_CANCEL_LIMIT))) >= 0) &&
              <h4 className='text-center px-3-env py-2 bg-white'>
                決済が完了していません。
                <button className="btn btn-danger" onClick={e => this.submitPayment(this.state.booking.book_id)}>決済へ進む</button>
              </h4>
            }
            {/* {(this.props.page === constClass.CONFIRM || (this.props.page === constClass.CHANGECONFIRM && this.state.change)) &&
              <div className='px-3-env py-2'>
                予約内容をご確認の上、予約登録してください。<br />
                決済画面へ遷移します。
              </div>
            } */}
            <BookingDetailView
              state={this.state}
              cancel_view={false}
              settings={this.props.settings}
              onChangeClick={(state, page) => { this.changeClick(state, page); }}
              checkBookingValid={this.checkBookingValid}
            />
            {
              this.state.booking.refund_total > 0 &&
              <div className="row mx-0 mt-3 py-2 pb-0 bg-white">
                <div className="col py-2 font-weight-bold text-green text-right">
                  払い戻し額
                </div>
                <div className="col-auto py-2 font-weight-bold text-green text-right">
                  {parseInt(this.state.booking.refund_total).toLocaleString()}円<br />
                  {(this.state.booking.book_type === constClass.TRAIN_BOOK_TYPE.BRINGIN) && <span className="text-red w-100 text-center font-weight-bold"><br />※別途乗車券が必要です。</span>}
                </div>
              </div>
            }
            <div className="row mx-0 mt-3 py-2 pb-0 bg-white">
              <div className="col py-2 font-weight-bold text-green text-right">
                合計{/* {(this.state.booking.book_id && (this.state.booking_detail.filter(x => (!constClass.STATUS_CCL.includes(x.status)))).length > 1) && "（往復含む)"} */}
                {(this.state.booking.book_id && this.state.booking.status === constClass.STATUS.UPAY) && "※未決済"}
              </div>
              <div className="col-auto py-2 font-weight-bold text-green text-right">
                {total.toLocaleString()}円<br />
                {(this.state.booking.book_type === constClass.TRAIN_BOOK_TYPE.BRINGIN) && <span className="text-red w-100 text-center font-weight-bold"><br />※別途乗車券が必要です。</span>}
              </div>
            </div>
          </div>
          {(!this.state.change && constClass.STATUS_PRE.includes(this.state.booking.status) && this.state.booking.book_id) &&
            <React.Fragment>
              <div className="row mx-0 pt-2 pb-0">
                <div className="col">
                  {CommonTrain.checkChangeTime(this.state.booking.book_date, this.state.booking_detail[0].ride_time, this.props.settings.TRAIN_CANCEL_LIMIT) ?
                    `乗車時刻の${this.props.settings.TRAIN_CANCEL_LIMIT}分前を過ぎると変更ができなくなります`
                    :
                    `乗車時刻の${this.props.settings.TRAIN_CANCEL_LIMIT}分前を過ぎているため、変更はできません`}
                </div>
              </div>
              <div className="row mx-0 pt-2 pb-3">
                <div className="col font-weight-bold text-center">
                  <button
                    className={`btn btn-primary py-2 px-1 w-75`}
                    onClick={() => this.submitCurrentbooking(this.state.booking.book_id, null, constClass.CANCEL)}>
                    キャンセル
                    { }
                  </button>
                </div>
              </div>
            </React.Fragment>}
          {("PAY_CANCEL_DETAIL_MESSAGE" in this.props.settings) &&
            <div className='row mx-0 pt-2 pb-0'>
              <div className='col mx-2 text-red'><a href={Common.getExternalUrl(this.props.settings[`PAY_CANCEL_DETAIL_URL`])} target="_blank" rel="noreferrer"><u>※{this.props.settings.PAY_CANCEL_DETAIL_MESSAGE}</u></a></div>
            </div>
          }
        </div>
      </div>
    )
  }

  renderPayment() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col p-1">
          <Payment
            siteId={this.props.siteId}
            lineId={this.props.liff_access_token}
            id={this.state.payment_data.id}
            amount={this.state.payment_data.amount}
            fee={this.state.payment_data.fee}
            backUrl={this.state.payment_data.backUrl}
            returnUrl={this.state.payment_data.returnUrl}
            checkUrl={this.state.payment_data.checkUrl}
            redirectReturnUrl={this.state.payment_data.redirectReturnUrl}
            abortUrl={`${constClass.CYCLETRAIN_LINKURL}/`}
            backFunc={this.backPayment}
            returnFunc={this.returnPayment} />
        </div>
      </div>
    );
  }
  renderBookSelectMessage(item) {
    var message = null;
    var outbound = (item.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP) ? this.state.bookingDetailList.find(x => x.book_id === item.book_id && x.book_no !== item.book_no) : null;
    if (outbound && constClass.STATUS_PRE.includes(outbound.status) && !CommonTrain.checkChangeTime(outbound.book_date, outbound.ride_time, 0)) message = "※往路未乗車です。ご乗車できません";
    //if (item.status === constClass.STATUS.UPAY) message = "※決済を行ってください。";
    if (!CommonTrain.checkChangeTime(item.book_date, item.ride_time, 0)) message = "※乗車時間が過ぎています。";
    if (item.status === constClass.STATUS.RDE) message = constClass.STATUS_NAME_USER[item.status];

    return (
      message ?
        <div className='row'>
          <div className='col'>{message}</div>
        </div>
        : ""
    )
  }
  renderBookSelect() {

    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0">
          <div id="booklist">
            {this.state.bookingDetailList.filter(b => (
              moment(b.book_date).isAfter(moment().subtract(1, "month").format('YYYY-MM-DD'))//1ヶ月前まで表示
              && b.status !== constClass.STATUS.UPAY && b.status !== constClass.STATUS.CUPAY // 未決済は一覧に表示しない
            )).length === 0 &&
              <div>
                <div className="row mx-0 px-3-env pt-2 pb-1 bg-green text-white">
                  <div className='col-12 py-3 h5 text-center'>ご予約はありません。</div>
                </div>
              </div>
            }
            {this.state.bookingDetailList.filter(b => moment(b.book_date).isSameOrAfter(moment().format('YYYY-MM-DD')) && constClass.STATUS_NFIN.includes(b.status) && b.status !== constClass.STATUS.UPAY) // 未決済は表示しない
              .sort((a, b) => (moment(`${a.book_date} ${CommonTrain.strTotime(a.ride_time)}`, 'YYYY-MM-DD k:mm').format('YYYYMMDDkkmm') - moment(`${b.book_date} ${CommonTrain.strTotime(b.ride_time)}`, 'YYYY-MM-DD k:mm').format('YYYYMMDDkkmm'))) //予約済み・事前通知済みのみ 
              .map((item, idx) => (
                <div className="row mx-0 my-3 text-center" key={`${idx}`}>
                  <div className="col px-0">
                    <button
                      onClick={async (e) => { this.submitCurrentbooking(item.book_id, item.book_no, (item.status === constClass.STATUS.RDE) ? constClass.RIDEQR : constClass.CHANGECONFIRM); }}
                      className={`btn btn-${(item.status !== constClass.STATUS.RDE && !CommonTrain.checkChangeTime(item.book_date, item.ride_time, 0)) ? "secondary" : item.status === constClass.STATUS.UPAY ? "danger" : "primary"} w-100 h-100 py-2 px-2`}>
                      <div className='row'>
                        <div className='col'>No.{item.view_book_no}</div>
                      </div>
                      <div className='row'>
                        <div className='col'>{moment(item.book_date).format("M月D日")} </div>
                      </div>
                      <div className='row'>
                        <div className='col'>{item.ride_station_name}～{item.getoff_station_name}　{CommonTrain.strTotime(item.ride_time)}発</div>
                      </div>
                      {item.book_type === constClass.TRAIN_BOOK_TYPE.ALL ?
                        <div className='row'>
                          {item.count_adult1 > 0 && <div className='col-6'>大人{item.count_adult1}人</div>}
                          {item.count_child1 > 0 && <div className='col-6'>小児{item.count_child1}人</div>}
                          {item.count_adult2 > 0 && <div className='col-6'>大人（障がい者または介護者）{item.count_adult2}人</div>}
                          {item.count_child2 > 0 && <div className='col-6'>小児（障がい者または介護者）{item.count_child2}人</div>}
                        </div>
                        :
                        <div>台数:{item.count_bringin}</div>
                      }
                      {this.renderBookSelectMessage(item)}
                    </button>
                  </div>
                </div>
              ))}
            <hr />
            {/* 乗車済・キャンセル済みを直近5件まで表示*/}
            {this.state.bookingDetailList.filter(b => !(moment(b.book_date).isSameOrAfter(moment().format('YYYY-MM-DD')) && constClass.STATUS_NFIN.includes(b.status))
              && moment(b.book_date).isAfter(moment().subtract(1, "month").format('YYYY-MM-DD'))//1ヶ月前まで表示
              && (b.status !== constClass.STATUS.CUPAY) // キャンセル未決済は一覧に表示しない
            )
              .sort((b, a) => (moment(`${a.book_date} ${CommonTrain.strTotime(a.ride_time)}`, 'YYYY-MM-DD k:mm').format('YYYYMMDDkkmm') - moment(`${b.book_date} ${CommonTrain.strTotime(b.ride_time)}`, 'YYYY-MM-DD k:mm').format('YYYYMMDDkkmm'))) //予約済み・事前通知済み以外
              //.slice(0, 5)
              .map((item, idx) => (
                <div className="row mx-0 my-3 text-center" key={`${idx}`}>
                  <div className="col px-0">
                    <button
                      onClick={async (e) => { this.submitCurrentbooking(item.book_id, item.book_no, (moment(item.book_date).format('YYYYMMDD') === moment().format('YYYYMMDD') && item.status === constClass.STATUS.FIN) ? constClass.RIDEQR : constClass.CHANGECONFIRM); }}
                      className={`btn btn-secondary w-100 h-100 py-2 px-2`}>
                      <div className='row'>
                        <div className='col'>No.{item.view_book_no}</div>
                      </div>
                      <div className='row'>
                        <div className='col'>{moment(item.book_date).format("M月D日")} </div>
                      </div>
                      <div className='row'>
                        <div className='col'>{item.ride_station_name}～{item.getoff_station_name}　{CommonTrain.strTotime(item.ride_time)}発</div>
                      </div>
                      {item.book_type === constClass.TRAIN_BOOK_TYPE.ALL ?
                        <div className='row'>
                          {item.count_adult1 > 0 && <div className='col-6'>大人{item.count_adult1}人</div>}
                          {item.count_child1 > 0 && <div className='col-6'>小児{item.count_child1}人</div>}
                          {item.count_adult2 > 0 && <div className='col-6'>大人（障がい者または介護者）{item.count_adult2}人</div>}
                          {item.count_child2 > 0 && <div className='col-6'>小児（障がい者または介護者）{item.count_child2}人</div>}
                        </div>
                        :
                        <div>台数:{item.count_bringin}</div>
                      }
                      {(constClass.STATUS.CPRE === item.status || constClass.STATUS_PRE.includes(item.status)) &&
                        <div className='row'>
                          <div className='col'>未乗車</div>
                        </div>
                      }
                      {item.status === constClass.STATUS.FIN &&
                        <div className='row'>
                          <div className='col'>降車済</div>
                        </div>
                      }
                      {item.status === constClass.STATUS.RDE &&
                        <div className='row'>
                          <div className='col'>乗車済</div>
                        </div>
                      }
                      {constClass.STATUS_CCL.includes(item.status) &&
                        <div className='row'>
                          <div className='col'>キャンセル済</div>
                        </div>
                      }
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
  renderFinish() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col px-0">
          <div id="bookfinish">
            <div className="row mx-0 pt-5 pb-5 bg-white">
              <div className='col text-center'>
                <h5>ご予約ありがとうございました。</h5>
              </div>
            </div>
            <div className="row mx-0 px-3-env py-2 bg-green text-white">
              <div className='col font-weight-bold text-center'>ご予約情報の確認・変更はこちら</div>
            </div>
            <div className="row mx-0 pt-2 pb-1 bg-white">
              <div className='col text-center'>
                <div className="row mx-0 my-3 text-center">
                  <div className="col px-0">
                    <button
                      onClick={e => this.submitCurrentbooking(null, null, constClass.HISTORY)}
                      className={`btn btn-primary w-100 h-100 py-2 px-2`}>
                      <h4 className="mb-0 font-weight-bold">ご予約履歴・変更</h4>
                    </button>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRideQr() {
    return (
      <div className="row mx-3 my-3 px-0-env">
        <div className="col p-1">
          <BookingQRRead
            siteId={this.props.siteId}
            lineId={this.props.liff_access_token}
            book_id={this.props.current_bookId}
            book_no={this.props.current_bookNo}
            station_cd={this.props.current_staCd}
            settings={this.props.settings}
            page={this.props.page}
            ride={this.state.ride}
            getRidedata={this.getRidedata}
            submitCurrentbooking={this.submitCurrentbooking}
            submitRide={this.submitRide}
          />
        </div>
      </div>
    );
  }
  historyBack() {
    if (this.props.history.length <= 1 || [constClass.HISTORY, constClass.FINISH, constClass.RIDEQR].includes(this.props.page)) {
      this.submitCurrentbooking(null, null, null);
    } else
      if (this.props.page === constClass.CHANGECONFIRM && this.state.booking.book_id) {
        this.setState({ change: false });
        this.submitCurrentbooking(null, null, constClass.HISTORY);
      } else {
        //データ変更を取り消す
        this.setState({
          round_type: (this.props.page === constClass.BOOKMENU) ? constClass.TRAIN_ROUND_TYPE.RETURNTRIP : constClass.TRAIN_ROUND_TYPE.OUTBOUND,
          booking: { ...this.state.set_booking },
          booking_detail: this.state.set_booking_detail.concat(),
          bookBinList: { ...this.state.set_bookBinList },
          bookBinListMessage: { ...this.state.set_bookBinListMessage },
        });
        this.props.history.goBack();
      }
    this.scrollPageTop();
  }
  renderBottom() {
    const Historybackbutton = (h) => {
      return (
        <div className="col-4 text-center p-0">
          <div className='py-2 px-2 p-env-bottom'>
            <button
              className={`btn btn-active w-100 py-2`}
              onClick={e => this.historyBack()}>
              戻る
            </button>
          </div>
        </div>
      )
    }
    const HistorynextButton = () => {
      var disabled = false;
      var nextpage = null;
      var next_roundtype = this.state.round_type;
      if (this.checkInvalid()) disabled = true;
      switch (this.props.page) {
        case constClass.USER:
          next_roundtype = "0";
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.CONFIRM;
          break;
        case constClass.INPUTALERT:
          next_roundtype = "0";
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.INPUT;
          break;
        case constClass.INPUT:
          next_roundtype = "0";
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.INPUTBIN1;
          break;
        case constClass.INPUTBIN1:
          next_roundtype = this.state.change && this.state.booking.book_id ? this.state.round_type : constClass.TRAIN_ROUND_TYPE.RETURNTRIP;
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.INPUTBIN2;
          break;
        case constClass.INPUTBIN2:
          next_roundtype = "0";
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.BOOKMENU;
          break;
        case constClass.BOOKMENU:
          nextpage = this.state.change ? constClass.CHANGECONFIRM : constClass.USER;
          break;
        default:
          return '';
      }
      return (
        <div className="col-8 text-center p-0">
          <div className='py-2 px-2 p-env-bottom'>
            <button
              disabled={disabled}
              className={`btn btn-active w-100 py-2`}
              onClick={() => {
                this.setState({
                  set_round_type: this.state.round_type,
                  round_type: next_roundtype,
                  set_booking: { ...this.state.booking },
                  set_booking_detail: this.state.booking_detail.concat(),
                  set_bookBinList: { ...this.state.bookBinList },
                  set_bookBinListMessage: { ...this.state.bookBinListMessage }
                });
                if (nextpage === constClass.INPUTBIN2) this.setReturnStation();
                this.props.history.push(`${constClass.CYCLETRAIN_LINKURL}/?page=${nextpage}`);
                this.scrollPageTop();
              }}>
              次へ
            </button>
          </div>
        </div>
      )
    }
    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100 bg-white border-top-3 border-green">
          <div className="row mx-0 ">
            <div className="col text-center p-0">
              <div className="row mx-0">
                {![constClass.MENU, constClass.PAYMENT].includes(this.props.page) &&
                  <Historybackbutton />
                }
                <HistorynextButton />
                {(this.props.page === constClass.CONFIRM || (this.props.page === constClass.CHANGECONFIRM && this.state.change)) &&
                  <div className="col-8 text-center p-0">
                    <div className='py-2 px-2 p-env-bottom'>
                      <button
                        disabled={this.state.disabled || this.state.booking_detail.find(x => x.errcheck && x.errcheck !== null)}
                        className={`btn btn-active w-100 py-2`}
                        onClick={() => { this.submit() }}>
                        次へ
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </footer >
    );
  }
  render() {
    return (
      this.state.sleeping ?
        <div className="liff-top bg-green">
          <div className="full-noheader" ref={this.pageRef}>
            <div className="row mx-0">
              <div className="col py-4 text-center">
                <img className='w-50' src={`/${this.props.siteId}/ct__main.svg`} alt="cycletrain" />
              </div>
            </div>
            <div className='text-center py-3'>
              {(!(window.liff.isInClient()) && (this.props.settings && "API_LIFF_URL" in this.props.settings)) ?
                <div className="row mx-3 mt-3 mb-3 text-center">
                  <div className="col px-0">
                    <button
                      onClick={e => window.location.replace(this.props.settings.API_LIFF_URL)}
                      className={`btn btn-primary w-100 h-100 py-3 px-2`}>
                      <div className='row m-0 p-0'>
                        <div className='col m-0 p-0 text-center align-self-center'><span className="mb-0 h5 font-weight-bold">LINEで開く</span></div>
                      </div>
                    </button>
                  </div>
                </div>

                : <h5 className='text-white'>{constClass.STOP_MESSAGE[`SITE${this.state.siteId}`]}</h5>
              }
            </div>
          </div>
        </div>
        :
        this.state.ready ?
          <div className={`liff-top ${(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) ? "bg-green" : "bg-lightgreen"}`}>
            <ScrollToTop />
            <React.Fragment>
              {!(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) && this.renderHeader()}
              <div className={`page-${(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) ? 'full-noheader' : (this.props.page === constClass.PAYMENT || this.props.page === constClass.RIDETICKET || this.props.page === constClass.GETOFFTICKET) ? 'full' : 'btn'}`} ref={this.pageRef}>
                {/* {this.renderPagetitle()} */}
                {(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU) &&
                  this.renderMenu()
                }
                {(this.props.page === constClass.USER) &&
                  this.renderUser()
                }
                {(this.props.page === constClass.INPUTALERT) &&
                  this.renderInputAlert()
                }
                {(this.props.page === constClass.BOOKMENU) &&
                  this.renderBookMenu()
                }
                {(this.props.page === constClass.INPUT) &&
                  this.renderInputCount()
                }
                {(this.props.page === constClass.INPUTBIN1 || this.props.page === constClass.INPUTBIN2) &&
                  this.renderInputBin()
                }
                {(this.props.page === constClass.CONFIRM || this.props.page === constClass.CHANGECONFIRM) &&
                  this.renderConfirm()
                }
                {this.props.page === constClass.PAYMENT &&
                  this.renderPayment()
                }
                {this.props.page === constClass.FINISH &&
                  this.renderFinish()
                }
                {this.props.page === constClass.HISTORY &&
                  this.renderBookSelect()
                }
                {(this.props.page === constClass.RIDEQR || this.props.page === constClass.RIDETICKET || this.props.page === constClass.GETOFFTICKET) &&
                  this.renderRideQr()
                }
                {this.props.page === constClass.CANCEL &&
                  <BookingCancel {...this.props} {...{ refreshBookData: this.refreshBookData }} />
                }
              </div>
              {!(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.MENU || this.props.page === constClass.PAYMENT || this.props.page === constClass.RIDETICKET || this.props.page === constClass.GETOFFTICKET) &&
                this.renderBottom()
              }
              {this.state.disabled && <Loading />}
            </React.Fragment>
          </div>
          : <Loading />)
  }

}

export default withRouter(Booking);