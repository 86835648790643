import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import constClass from '../Constants/Constants';

// Stateの初期状態
const initialState = {
  start_date: moment(new Date()).format("YYYY/MM/DD"),
  end_date: moment(new Date()).format("YYYY/MM/DD"),
  logi_id: '',
  place_visit_id: '',
  cancel_flag: constClass.FLAG.OFF,
};

const BookingCondition = createSlice({
  name: "BookingCondition",
  initialState: initialState,
  reducers: {
    setSearchData: (state, action) => ({
      start_date: action.payload.start_date,
      end_date: action.payload.end_date,
      logi_id: action.payload.logi_id,
      place_visit_id: action.payload.place_visit_id,
      cancel_flag: state.cancel_flag,
    }),
    setStartDate: (state, action) => ({
      start_date: action.payload,
      end_date: state.end_date,
      logi_id: state.logi_id,
      place_visit_id: state.place_visit_id,
      cancel_flag: state.cancel_flag,
    }),
    setEndDate: (state, action) => ({
      start_date: state.start_date,
      end_date: action.payload,
      logi_id: state.logi_id,
      place_visit_id: state.place_visit_id,
      cancel_flag: state.cancel_flag,
    }),
    setLogi: (state, action) => ({
      start_date: state.start_date,
      end_date: state.end_date,
      logi_id: action.payload,
      place_visit_id: state.place_visit_id,
      cancel_flag: state.cancel_flag,
    }),
    setPlacesVisit: (state, action) => ({
      start_date: state.start_date,
      end_date: state.end_date,
      logi_id: state.logi_id,
      place_visit_id: action.payload,
      cancel_flag: state.cancel_flag,
    }),
    setCancelFlag: (state, action) => ({
      start_date: state.start_date,
      end_date: state.end_date,
      logi_id: state.logi_id,
      place_visit_id: state.place_visit_id,
      cancel_flag: action.payload,
    }),

    logout: (state) => ({
      ...initialState,
    })
  }
});

// 個別でも使えるようにActionCreatorsをエクスポートしておく
export const { setSearchData, setStartDate, setEndDate, setLogi, setPlacesVisit, setCancelFlag, init, logout } = BookingCondition.actions;

export default BookingCondition;