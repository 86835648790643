import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import UseConfirmModal from '../Common/UseConfirmModal';
import UseAlertModal from '../Common/UseAlertModal';  

const BackyardGuardPerson = (props) => {
  const { user } = props;
  const { addToast } = useToasts();
  const [searchLogiData, setSearchLogiData] = useState(null);
  const [searchGuardPersonData, setSearchGuardPersonData] = useState(null);
  const [logiData, setLogiData] = useState({
    logi_id: props.user.logiId,
  })
  const [guardPersonNew, setguardPersonNew] = useState({ guard_person_id: '', logi_id: '', name: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState(''); 

  const refreshGuardPerson = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    setSearchLogiData(logi);
    const guardPersonPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/guard_person/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const guardPerson = (await guardPersonPromise).data;
    setSearchGuardPersonData(guardPerson);
    setguardPersonNew({ guard_person_id: null, logi_id: '', name: '', upd_date: null });
  }, [props.match.params.siteId]);

  // 登録ボタン
  const renderRegistButton = (data, idx) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleGuardPersonRegistClick(data)}>
        {data.guard_person_id ? "更新" : "登録"}
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.guard_person_id === null}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleGuardPersonDeleteClick(data)}>
        削除
      </button>
    )
  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...guardPersonNew, [key]: event.target.value };
      setguardPersonNew(new_data);
    } else {
      const _searchData = [...searchGuardPersonData.filter(x => x.logi_id === logiData.logi_id)];
      _searchData[index] = { ..._searchData[index], [key]: event.target.value };
      setSearchGuardPersonData(_searchData);
    }
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  // 登録ボタン：処理
  const handleGuardPersonRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (!data.name) {
      setAlertModalMessage('氏名を入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (data.name.length > 20) {
      setAlertModalMessage('氏名は20字以内で入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    setConfirmModalMessage(`守衛マスタ[${data.name}]を登録します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      //(data.password && data.password.match(/\s+/g))
      const params = {
        site_id: props.match.params.siteId,
        guard_person_id: data.guard_person_id ? data.guard_person_id : '',
        logi_id: logiData.logi_id,
        name: data.name,
        logi_name: searchLogiData.find(x => x.logi_id === logiData.logi_id).logi_name,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/guard_person/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('登録しました。', { appearance: 'success', autoDismiss: true });
          await refreshGuardPerson();
        }
        setLockData(false);
      }
    });
  }

  // 削除ボタン：処理
  const handleGuardPersonDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    setConfirmModalMessage(`守衛[${data.guard_person_id}:${data.name}]を削除します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      const params = {
        site_id: props.match.params.siteId,
        guard_person_id: data.guard_person_id,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/guard_person/delete/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('削除しました。', { appearance: 'success', autoDismiss: true });
          await refreshGuardPerson();
        }
        setLockData(false);
      }
    });
  }

  const handleSetLogi = async (e) => {
    //トークンを取得
    const target = e.target;
    var tmp = { ...logiData, logi_id: Number(target.value) };
    setLogiData(tmp);
  }


  useEffect(() => {
    async function fetchData() {
      await refreshGuardPerson();
    }
    return fetchData();
  }, [refreshGuardPerson]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {searchGuardPersonData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      <div className="row mr-1 text-center align-self-center mt-1">
        <span className='mt-2'>施設</span>
        <div className="col-5 mr-2 text-center align-self-center mb-2">
          <select className="custom-select w-100" aria-label="施設" value={logiData.logi_id || ''} onChange={handleSetLogi}>
            <option value="">選択してください</option>
            {searchLogiData && searchLogiData.map((item) =>
              <option key={item.logi_id} value={item.logi_id}>{item.logi_name}</option>
            )}
          </select>
        </div>
      </div>
      {searchGuardPersonData !== null && logiData.logi_id && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center">
                    守衛ID
                  </td>
                  <td className="text-center">
                    氏名
                  </td>
                  <td className="text-center">
                    施設
                  </td>
                  <td className="text-center">
                    更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr key="new">
                  {/* 守衛ID */}
                  <td className="text-center align-middle col-2">
                    新規
                  </td>
                  {/* 氏名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"name_new"} name="name[]" value={guardPersonNew.name} onChange={handleChangeCell('new', 'name')} size="15"></input>
                  </td>
                  {/* 施設 */}
                  <td className="text-center align-middle">
                    {searchLogiData.find(x => x.logi_id === logiData.logi_id).logi_name}
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(guardPersonNew, 'new')}
                  </td>
                </tr>
                {searchGuardPersonData.filter(x => x.logi_id === logiData.logi_id).map((data, idx) => (
                  <tr key={idx}>
                    {/* 守衛ID */}
                    <td className="text-center align-middle">
                      {data.guard_person_id}
                    </td>
                    {/* 氏名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"name" + idx} name="name[]" value={data.name || ''} onChange={handleChangeCell(idx, 'name')} size="15"></input>
                    </td>
                    {/* 施設 */}
                    <td className="text-center align-middle">
                      {searchLogiData.find(x => x.logi_id === logiData.logi_id).logi_name}
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:SS') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data, idx)}
                      {data.guard_person_id && renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
      <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal} />
      <UseAlertModal isOpen={isAlertModalOpen} message={alertModalMessage} onClose={closeAlertModal} />
    </div>
  )
}

export default BackyardGuardPerson