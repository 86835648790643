import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
//import util from 'util';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import BookingList from "./BookingList";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";


registerLocale('ja', ja);
const BookingListTab = (props) => {
  const { condition, conditionActions } = props;
  const [logiList, setLogiMaster] = useState([]);
  const [placesVisitList, setPlacesVisitMaster] = useState([]);
  const [bookData, setBookData] = useState(null);
  const [searchFlag, setSearchFlag] = useState(true);

  const refreshMaster = useCallback(async () => {
    //トークンを取得
    if (searchFlag) {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.siteId,
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    const placesVisitPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const placesVisit = (await placesVisitPromise).data;

    var book_reg_params = {}
    book_reg_params = {
      "site_id": props.siteId,
      "operator": "and",
      "where": [{ "site_id": props.siteId }]
    }
    if (condition.start_date) {
      book_reg_params.where.push({
        "operator": "gte",
        "attr": "bookingdate",
        "val": new Date(condition.start_date)
      });
    }
    if (condition.end_date) {
      book_reg_params.where.push({
        "operator": "lte",
        "attr": "bookingdate",
        "val": new Date(condition.end_date)
      });
    }
    if (condition.logi_id) {
      book_reg_params.where.push({ "logi_id": condition.logi_id });
    }
    if (condition.place_visit_id) {
      book_reg_params.where.push({ "place_visit_id": condition.place_visit_id });
    }
    if (condition.cancel_flag === constClass.FLAG.ON) {
      book_reg_params.where.push({ "status": constClass.STATUS.CCL });
    } else {
      book_reg_params.where.push({
        "operator": "ne",
        "attr": "status",
        "val": constClass.STATUS.CCL
      });
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard_search/name`, book_reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setBookData(data);
    setPlacesVisitMaster(placesVisit);
    setLogiMaster(logi);
  }
  setSearchFlag(false);
  }, [searchFlag, props.siteId, props.user.logiId, condition, conditionActions]);

  useEffect(() => {
    refreshMaster();
  }, [refreshMaster]);

  const handleLogi = async (e) => {
    const target = e.target;
    conditionActions.setLogi(target.value);
  }

  const handlePlacesVisit = async (e) => {
    //トークンを取得
    const target = e.target;
    conditionActions.setPlacesVisit(target.value);
  }

  const handleCancelFlag = async (e) => {
    //トークンを取得
    const target = e.target;
    console.log(target);
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    conditionActions.setCancelFlag(value);
  }

  const renderHeader = () => {
    return (
      <div className="mx-4">
        <div className="row mb-3">
          <div className="text-center align-self-center">
            <span>開始日</span>
          </div>
          <div className="col-2 text-center">
            <DatePicker className="custom-select w-100 text-center border-textbox text-primary"
              aria_labal="開始日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="start_date" name="start_date"
              popperPlacement='bottom'
              popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                },
              }}
              isClearable={true}
              selected={condition.start_date ? new Date(condition.start_date) : null}
              onChange={date => conditionActions.setStartDate(date ? moment(date).format('YYYY/MM/DD') : null)}
              placeholderText="- 選択 -"
              disabledKeyboardNavigation
              // includeDates={calendarList.reduce((list, item) => list.concat(new Date(item.book_date)), [])}
              // highlightDates={calendarList.reduce((list, item) => list.concat(new Date(item.book_date)), [])}
              customInput={<button>{condition.start_date ? condition.start_date : "- 選択 -"}</button>}
            />
          </div>
          <div className="text-center align-self-center">
            <span>終了日</span>
          </div>
          <div className="col-2 text-center">
            <DatePicker className="custom-select w-100 text-center border-textbox text-primary"
              aria_labal="終了日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="end_date" name="end_date"
              popperPlacement='bottom'
              popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                },
              }}
              isClearable={true}
              selected={condition.end_date ? new Date(condition.end_date) : null}
              onChange={date => conditionActions.setEndDate(date ? moment(date).format('YYYY/MM/DD') : null)}
              placeholderText="- 選択 -"
              disabledKeyboardNavigation
              // includeDates={calendarList.reduce((list, item) => list.concat(new Date(item.book_date)), [])}
              // highlightDates={calendarList.reduce((list, item) => list.concat(new Date(item.book_date)), [])}
              customInput={<button>{condition.end_date ? condition.end_date : "- 選択 -"}</button>}
            />
          </div>
          <div className="text-center align-self-center">
            <span>施設</span>
          </div>
          <div className="col-2 text-center">
            <select className="custom-select w-100" aria-label="施設" value={condition.logi_id || ''} onChange={handleLogi}>
              <option value="">選択してください</option>
              {logiList.map((item) =>
                <option key={item.logi_id} value={item.logi_id}>{item.logi_name}</option>
              )}
            </select>
          </div>
          <div className="text-center align-self-center">
            <span>訪問先</span>
          </div>
          <div className="col-2 text-center">
            <select className="custom-select w-100" aria-label="訪問先" disabled={!condition.logi_id} value={condition.place_visit_id || ''} onChange={handlePlacesVisit}>
              <option value="">選択してください</option>
              {condition.logi_id && placesVisitList.filter((visit) => visit.logi_id === Number(condition.logi_id)).map((item) =>
                <option key={item.place_visit_id} value={item.place_visit_id}>{item.place_visit_name}</option>
              )}
            </select>
          </div>
          <div className="col-1 text-center align-self-center">
            <div className="custom-control custom-checkbox form-check-reverse d-flex align-items-center justify-content-center text-nowrap">
              <input type="checkbox" className="custom-control-input" id="cancel_flag" name="cancel_flag" value="true" checked={condition.cancel_flag === constClass.FLAG.ON} onChange={handleCancelFlag} />
              <label className="ml-3 custom-control-label m-1 text-right text-nowrap" htmlFor="cancel_flag">キャンセル済み</label>
            </div>
          </div>
          <div className="col-1 text-center">
            <div className="col text-green font-weight-bold text-center">
              <button className='btn btn-active px-3 text-nowrap text-center'
                onClick={() => setSearchFlag(true)}>検索</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      {(logiList.length > 0) && renderHeader()}
      <BookingList {...props} {...{ condition: { logiList, bookData } }} />
      {/* <TabPanel>
          <BookingList statusIdx={index} {...props} {...{ condition: { searchData, logiList } }} />
        </TabPanel>
        <TabPanel>
          <BookingList statusIdx={index} {...props} {...{ condition: { searchData, logiList } }} />
        </TabPanel> */}
      {/* <FullCalendar
        locale="ja" // 日本語
        defaultView="timeGridWeek" // 基本UI
        slotDuration="00:30:00" // 表示する時間軸の最小値
        selectable={true} // 日付選択可能
        allDaySlot={false} // alldayの表示設定
        titleFormat={{
          year: "numeric",
          month: "short",
          day: "numeric",
        }} // タイトルに年月日を表示
        header={{
          left: "prev,next,today",
          center: "title",
          right: "dayGridMonth,timeGridWeek",
        }}
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5],
          startTime: "0:00",
          endTime: "24:00",
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        ref={this.ref}
        weekends={true} // 週末表示
        events={this.myEvents} // 起動時に登録するイベント
        select={this.handleSelect} // カレンダー範囲選択時
        eventClick={this.handleClick} // イベントクリック時
      /> */}

    </div>
  )
}

export default BookingListTab;