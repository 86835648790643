import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import util from 'util';
import { generatePath } from 'react-router';
import constClass from '../../Constants/Constants'

const NavBar = (props) => {
  const { user, userActions, settings, conditionActions } = props;
  const history = useHistory();

  const handleTitleClick = () => {
    userActions.refresh();
    history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`);
  }

  const handleLogoutClick = () => {
    localStorage.removeItem('jwt');
    userActions.logout();
    conditionActions.logout();
    history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}/login`);
  }

  // const handlePlanCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}plancategory`);
  // }

  // const handleProductCategoryClick = () => {
  //   userActions.refresh();
  //   history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}productcategory`);
  // }

  // const handleOrderInputClick = () => {
  //   userActions.refresh();
  //   history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}order/input`);
  // }

  // const handlePlanClick = () => {
  //   userActions.refresh();
  //   history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}plan`);
  // }

  // const handleProductClick = () => {
  //   userActions.refresh();
  //   history.replace(`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}product`);
  // }

  return (
    <nav className="navbar navbar-light navbar-expand-lg  bg-light fixed-top">
      <Link className="navbar-brand" onClick={handleTitleClick} to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}`}>
        {settings && settings.TITLE_SHORT_NAME ? settings.TITLE_SHORT_NAME : '予約'}<div className='d-none'>{util.inspect(history)}</div>
      </Link>
      {
        user.isLoggedIn &&
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      }
      {
        user.isLoggedIn &&
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            {/* {user.userClass === constClass.CLASS.ADMIN &&
              <React.Fragment>
                <li className="nav-item">
                  <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}calendar`}>カレンダーマスタ</Link>
                </li>
                {props.siteId === constClass.CYCLETRAIN_SITE_ID &&
                  <li className="nav-item">
                    <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}traindaiya`}>ダイヤ改正マスタ</Link>
                  </li>
                }
              </React.Fragment>
            } */}
              {/* <li className="nav-item">
              <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}book/input`}>予約入力</Link>
            </li> */}
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}bookinglist`}>予約検索・一覧</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}qrread`}>QRコード読み取り</Link>
              </li>
              {!user.logiId &&
                <li className="nav-item">
                  <Link className="nav-link" to={`${generatePath(`${props.match.path}`, { siteId: props.match.params.siteId })}system`}>システム管理</Link>
                </li>
              }
              {/* {user.userClass === constClass.CLASS.CHECK && */}
              {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  設定
              </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="#" onClick={handleDispCloseClick}>発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleLineCloseClick}>LINE発券停止</a>
                  <a className="dropdown-item" href="#" onClick={handleDispOpenClick}>発券再開</a>
                  <a className="dropdown-item" href="#" onClick={handleLineOpenClick}>LINE発券再開</a>
                  <a className="dropdown-item" href="#" onClick={handleWaitOpenClick}>順番待ち開始</a>
                  <a className="dropdown-item" href="#" onClick={handleWaitCloseClick}>順番待ち停止</a>
                </div>
              </li> */}
            {/* } */}
          </ul>
        </div>
      }
      {
        user.isLoggedIn &&
        <div>
          {/* <label className="mr-2 text-primary">{user.userName}</label> */}
          <button className="btn btn-dark" onClick={handleLogoutClick}>ログアウト</button>
        </div>
      }
    </nav>
  );
}

export default NavBar;