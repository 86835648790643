import React from 'react';
import Common from '../Common/common';
import CommonTrain from '../Common/common_train';
import constClass from '../../Constants/Constants';
import moment from 'moment';
//import util from 'util';
import Loading from '../Loading/Loading';

//予約内容確認画面表示
const BookingDetailView = ({ state, cancel_view, settings, onChangeClick, checkBookingValid }) => {
  const checkChange = (detail = null) => {
    var chk = false;//false:変更不可
    if (cancel_view) {
      chk = false
    } else {
      if (!detail) chk = true;
      if (detail && !detail.book_no) chk = true;
      if (detail && detail.train_no && constClass.STATUS_PRE.includes(detail.status || null) && CommonTrain.checkChangeTime((detail.book_date ? detail.book_date : state.booking.book_date), detail.ride_time, settings.TRAIN_CANCEL_LIMIT)) chk = true;
      if (state.change && !checkBookingValid(state.booking, state.booking_detail, state.bookBinList)) chk = true;//変更内容がエラー
      if (detail && !detail.book_no && state.booking.returntrip_type === constClass.FLAG.OFF && detail.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP) chk = true;
    }
    return chk;
  }

  var viewchk1 = checkChange((state.booked ? state.booked.booking_detail : state.booking_detail).find(x => x.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND));
  var viewchk2 = checkChange((state.booked ? state.booked.booking_detail : state.booking_detail).find(x => x.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP));

  const renderChangeButton = (page, detail = null) => {
    if (!cancel_view && viewchk1 && viewchk2) {
      return (
        <div className="col-2 py-1 px-1 align-self-center">
          <button
            className={`btn w-100 bg-green text-white py-1 px-1`}
            onClick={() => {
              if (detail) {
                onChangeClick({ change: true, round_type: detail.round_type }, detail.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP ? constClass.INPUTBIN2 : constClass.INPUTBIN1);
              } else {
                onChangeClick({ change: true }, page);
              }
            }}>
            変更
          </button>
        </div>
      );
    }
  }
  return (
    state.booking && state.booking.book_date ? <React.Fragment>
      <div className="row mx-0 p-0 bg-white">
        <div className="col text-left m-0 p-0 border-right">
          {/* <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
            <div className="col-5 px-0 mx-0 text-green font-weight-bold">
              お名前
            </div>
            <div className="col px-1">
              {state.booking.customer_name}
            </div>
          </div> */}
          <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
            <div className="col-5 px-0 mx-0 text-green font-weight-bold">
              お名前(カナ)
            </div>
            <div className="col px-1">
              {state.booking.customer_kana}
            </div>
          </div>
          {!cancel_view && <React.Fragment>
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                居住地(市町村)
              </div>
              <div className="col px-1">
                {state.booking.customer_address}
              </div>
            </div>
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                性別
              </div>
              <div className="col px-1">
                {constClass.CUSTOMER_GENDER_NAME[state.booking.customer_gender]}
              </div>
            </div>
            <div className="row mx-0 px-2 pt-2 pb-1">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                年代
              </div>
              <div className="col px-1">
                {constClass.CUSTOMER_AGE_TYPE_NAME[state.booking.customer_age_type]}
              </div>
            </div>
          </React.Fragment>}
        </div>
        {renderChangeButton(constClass.USER)}
      </div>
      <div className="row mt-2 mx-0 p-0 bg-white">
        <div className="col text-left m-0 p-0 border-right">
          <div className="row mx-0 px-2 pt-2 pb-1 ">
            <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
              乗車日
            </div>
            <div className="col px-1">
              <div className='row'>
                <div className='col'>{moment(state.booking.book_date).format('YYYY/MM/DD')}</div>
              </div>
              {state.booking.book_type === constClass.TRAIN_BOOK_TYPE.BRINGIN &&
                <div className="row mx-0 pt-2 pb-1">
                  <div className="col px-0 mx-0">
                    台数
                  </div>
                  <div className="col-auto px-1">
                    {state.booking.count_bringin} 台
                  </div>
                </div>}
              {state.booking.book_type === constClass.TRAIN_BOOK_TYPE.ALL && <React.Fragment>
                {state.booking.count_adult1 > 0 &&
                  <div className="row mx-0 pt-2 pb-1">
                    <div className="col px-0 mx-0">
                      大人
                    </div>
                    <div className="col-auto px-1">
                      {state.booking.count_adult1} 人
                    </div>
                  </div>
                }
                {state.booking.count_child1 > 0 &&
                  <div className="row mx-0 pt-2 pb-1">
                    <div className="col px-0 mx-0">
                      小児
                    </div>
                    <div className="col-auto px-1">
                      {state.booking.count_child1} 人
                    </div>
                  </div>}
                {state.booking.count_adult2 > 0 &&
                  <div className="row mx-0 pt-2 pb-1">
                    <div className="col px-0 mx-0">
                      大人（障がい者または介護者）
                    </div>
                    <div className="col-auto px-1">
                      {state.booking.count_adult2} 人
                    </div>
                  </div>}
                {state.booking.count_child2 > 0 &&
                  <div className="row mx-0 pt-2 pb-1">
                    <div className="col px-0 mx-0">
                      小児（障がい者または介護者）
                    </div>
                    <div className="col-auto px-1">
                      {state.booking.count_child2} 人
                    </div>
                  </div>}
              </React.Fragment>}
            </div>
          </div>
          {((state.booking.count_adult2 > 0 || state.booking.count_child2 > 0) && "BOOKING_DISABILITY_CERTIFICATE_MESSAGE" in settings) &&
            <div className='row'>
              <div className='col mx-2 text-red'>※{settings.BOOKING_DISABILITY_CERTIFICATE_MESSAGE}</div>
            </div>
          }
          {("SERVICE_COUNT_MESSAGE" in settings) &&
            <div className='row'>
              <div className='col mx-2 '><a href={Common.getExternalUrl(settings[`SERVICE_COUNT_URL`])} target="_blank" rel="noreferrer"><u>※{settings.SERVICE_COUNT_MESSAGE}</u></a></div>
            </div>
          }
        </div>
        {renderChangeButton(constClass.INPUT)}
      </div>
      <div className="row mt-2 mx-0 p-0 bg-white">
        <div className="col text-left m-0 p-0 border-right">
          <div className="row mx-0 px-2 pt-2 pb-1 ">
            <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
              予約種類
            </div>
            <div className="col px-1">
              {constClass.TRAIN_BOOK_TYPE_NAME[state.booking.book_type]}
            </div>
          </div>
        </div>
        {renderChangeButton(constClass.BOOKMENU)}
      </div>

      {state.booking_detail//.filter(x => (!constClass.STATUS_CCL.includes(x.status) || cancel_view))
        .sort((a, b) => a.round_type - b.round_type).map((item, idx) => (
          <React.Fragment key={idx}>
            <div className="row mt-2 mx-0 p-0 bg-white">
              <div className="col text-left m-0 p-0 border-right">
                {(state.booking_detail.length > 1 && item.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP && !item.train_no) &&
                  <div className="row mx-0 px-2 pt-2 pb-1 ">
                    <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
                      帰り
                    </div>
                    <div className="col px-1">
                      予約なし
                    </div>
                  </div>}
                {item.train_no &&
                  <div className="col text-left m-0 p-0">
                    {(state.change && item.errcheck) &&
                      <div className="row mx-0 p-0">
                        <div className={`col mt-1 p-1 text-left text-white ${(item.errcheck) ? "bg-warning" : ""}`}>
                          {item.errcheck}
                        </div>
                      </div>
                    }
                    <div className="row mx-0 px-2 pt-2 pb-1 ">
                      <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
                        {state.booking_detail.length === 1 ? "乗車区間" : item.round_type === constClass.TRAIN_ROUND_TYPE.OUTBOUND ? "行き" : "帰り"}
                        {(constClass.STATUS_CCL.concat([constClass.STATUS.RDE, constClass.STATUS.FIN])).includes(item.status) && <div>[{constClass.STATUS_NAME_USER[item.status]}]</div>}
                      </div>
                      {item.train_no ?
                        <div className="col px-1">
                          {item.view_book_no &&
                            <div className="row m-0 p-0">
                              <div className="col px-0 font-weight-bold">
                                No.{item.view_book_no}
                              </div>
                            </div>
                          }
                          <div className="row m-0 p-0">
                            <div className="col px-0">
                              {item.ride_station_name}
                              ～{item.getoff_station_name}
                            </div>
                          </div>
                          <div className="row mx-0 pt-0">
                            <div className="col px-0">
                              {CommonTrain.strTotime(item.ride_time)}発
                              {constClass.TRAIN_OPERATE_NAME[item.operate_type]}
                            </div>
                          </div>
                          <div className="row mx-0 pt-0">
                            <div className="col px-0">
                              {CommonTrain.strTotime(item.getoff_time)}着
                            </div>
                          </div>
                          {!state.change && item.train_booking_detail_car && <>
                            <div className="row mx-0 pt-0">
                              <div className="col px-0">
                                {item.train_booking_detail_car.sort((a, b) => a.car_no - b.car_no).map((car, idx2) => <div key={idx2}>{car.car_no}号車 乗車{car.count}台</div>)}
                              </div>
                            </div>
                            {settings[`LP_STATIONMAP_${item.ride_station_cd}`] &&
                              <div className="row mx-0 pt-0">
                                <div className="col px-0">
                                  <a href={Common.getExternalUrl(settings[`LP_STATIONMAP_${item.ride_station_cd}`])} target="_blank" rel="noreferrer">乗車駅構内図</a>
                                </div>
                              </div>
                            }
                            {settings[`LP_TRAIN_RIDE_POSITION`] &&
                              <div className="row mx-0 pt-0">
                                <div className="col px-0">
                                  <a href={Common.getExternalUrl(settings[`LP_TRAIN_RIDE_POSITION`])} target="_blank" rel="noreferrer">車内の駐輪位置図</a>
                                </div>
                              </div>
                            }
                          </>}
                        </div>
                        : <div className="col px-0">なし</div>}
                    </div>
                  </div>
                }
              </div>
              {renderChangeButton(constClass.INPUTBIN1, item)}
            </div>
            <div className="row mt-0 mx-0 p-0 bg-white border-top">
              <div className="col text-left m-0 p-0">
                {item.train_no && <React.Fragment>
                  <div className="row mx-0 py-2 pb-0">
                    <div className="col font-weight-bold text-right">
                      {((state.booking.count_adult1 + state.booking.count_child1 + state.booking.count_adult2 + state.booking.count_child2) <= 0) ? constClass.TRAIN_BOOK_TYPE_NAME[constClass.TRAIN_BOOK_TYPE.BRINGIN] : constClass.TRAIN_BOOK_TYPE_NAME[state.booking.book_type]}
                    </div>
                    <div className="col-auto text-right font-weight-bold">
                      {item.total.toLocaleString()} 円
                    </div>
                  </div>
                </React.Fragment>}
                {(constClass.STATUS_PRE.includes(item.status) && !CommonTrain.checkChangeTime(state.booking.book_date, item.ride_time, settings.TRAIN_CANCEL_LIMIT)) && <div className='px-3 text-red'>※ご乗車時間が過ぎています</div>}
                {(item.train_no && (!item.status || constClass.STATUS_NFIN.includes(item.status)) && item.round_type === constClass.TRAIN_ROUND_TYPE.RETURNTRIP) && <div className='px-3 text-red'>※行きの便にご乗車されると、帰りを変更・キャンセルすることができません。</div>}
              </div>
            </div>
          </React.Fragment>
        ))
      }
    </React.Fragment > : <Loading />
  );
}

export default BookingDetailView;