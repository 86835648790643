import React, { useState } from 'react';
import Common from '../Common/common';
import CommonBooking from '../Common/common_booking';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import Loading from '../Loading/Loading';
import axios from 'axios';
import util from 'util';

//予約内容確認画面表示
const BookingDetailView = ({ state, cancel_view, settings, onChangeClick, page, person }) => {

  const [lockData, setLockData] = useState(false);

  const renderChangeButton = (page) => {
    if (!cancel_view) {
      return (
        <div className="col-2 py-1 px-1 align-self-center">
          <button
            className={`btn w-100 bg-green text-white py-1 px-1`}
            onClick={() => {
              onChangeClick({ change: true, back: true }, page);
            }}>
            変更
          </button>
        </div>
      );
    }
  }

  const checksubmit = async () => {
    try {
      if (!window.confirm('確認を行います。よろしいですか？')) {
        return;
      }
      setLockData(true);
      var result = null;
      const params = {
        site_id: state.siteId,
        book_id: state.book_id,
        status: constClass.STATUS.ACA,
        person: person
      }
      result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/booking/status/${state.book_id}`, params));
      setLockData(false);

      if (result.data.result) {
        alert('確認しました。');
        setLockData(true);
      } else {
        alert('確認失敗');
        setLockData(false);
      }
    } catch (error) {
      setLockData(false);
      console.log(util.inspect(error))
      alert('確認失敗');
    }
    setLockData(true);
  }

  return (
    state.booking && state.booking.bookingdate ? <React.Fragment>
      <div className="row mx-0 p-0 bg-white">
        <div className="col text-left m-0 p-0 border-right">
          <div className="row mx-0 px-2 pt-2 pb-1 ">
            <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
              施設
            </div>
            <div className="col px-1">
              <div className='row'>
                <div className='col'>{state.booking.logi_name}</div>
              </div>
            </div>
          </div>
          <div className="row mx-0 px-2 pt-2 pb-1 ">
            <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
              訪問先
            </div>
            <div className="col px-1">
              <div className='row'>
                <div className='col'>{state.booking.place_visit_name}</div>
              </div>
            </div>
          </div>
        </div>
        {renderChangeButton(constClass.INPUT)}
      </div>
      <div className="row mt-2 mx-0 p-0 bg-white">
        <div className="col text-left m-0 p-0 border-right">
          <div className="row mx-0 px-2 pt-2 pb-1 ">
            <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
              日付
            </div>
            <div className="col px-1">
              <div className='row'>
                <div className='col'>{moment(state.booking.bookingdate).format('YYYY/MM/DD')}</div>
              </div>
            </div>
          </div>
          <div className="row mx-0 px-2 pt-2 pb-1 ">
            <div className="col-5 px-0 mx-0 text-green align-self-center font-weight-bold">
              時間帯
            </div>
            <div className="col px-1">
              <div className='row'>
                <div className='col'>
                  {state.booking.start_time}
                  ～
                  {state.booking.end_time}
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderChangeButton(constClass.INPUTBIN1)}
      </div>
      <div className="row mt-2 mx-0 p-0 bg-white">
        <div className="col text-left m-0 p-0 border-right">
          {/* {!cancel_view && <React.Fragment> */}
          {<React.Fragment>
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                会社名
              </div>
              <div className="col px-1">
                {state.booking.company_name}
              </div>
            </div>
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                電話番号
              </div>
              <div className="col px-1">
                {state.booking.phone_number}
              </div>
            </div>
            {state.booking.names.map((name, index) => (
              <div key={index}>
                <div className='bg-logigreen border border-dark mb-1'>
                  <div className="row mx-0 px-2 pt-2 pb-1">
                    <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                      氏名
                    </div>
                    <div className="col px-1">
                      {Array.isArray(name) ? name[0] : name}
                    </div>
                  </div>
                  <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
                    <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                      本人確認書類
                    </div>
                    <div className="col px-1">
                      {constClass.LICENSE_NAME[state.booking.identity_document[index]]}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                訪問先担当者名
              </div>
              <div className="col px-1">
                {state.booking.person_charge}
              </div>
            </div>
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                用件
              </div>
              <div className="col px-1">
                {constClass.REQUIREMENT_NAME[state.booking.requirement]}
              </div>
            </div>
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                車両番号
              </div>
              <div className="col px-1">
                {state.booking.vehicle_number}
              </div>
            </div>
            <div className="row mx-0 px-2 pt-2 pb-1 border-bottom">
              <div className="col-5 px-0 mx-0 text-green font-weight-bold">
                備考
              </div>
              <div className="col px-1">
                {state.booking.remarks}
              </div>
            </div>
          </React.Fragment>}
        </div>
        {renderChangeButton(constClass.USER)}
      </div>
      {page === constClass.CONFIRMATION &&
        <div className="col-12 text-center">
          <div className='p-4 p-env-bottom m-4'>
            <button
              disabled={lockData || state.booking.status === constClass.STATUS.ACA}
              className={`btn btn-active w-100`}
              onClick={() => checksubmit()}
            >
              確認
            </button>
          </div>
        </div>
      }
    </React.Fragment > : <Loading />
  );
}

export default BookingDetailView;