import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import UseConfirmModal from '../Common/UseConfirmModal';
import UseAlertModal from '../Common/UseAlertModal';

const BackyardLogi = (props) => {
  const { user } = props;
  const { addToast } = useToasts();
  const [searchData, setSearchData] = useState(null);
  const [logiNew, setLogiNew] = useState({ logi_id: '', logi_name: '', upd_date: null });
  const [lockData, setLockData] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState(''); 

  const refreshLogi = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.match.params.siteId,
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    setSearchData(logi);
    setLogiNew({ logi_id: null, logi_name: '', upd_date: null });
  }, [props.match.params.siteId]);

  // 登録ボタン
  const renderRegistButton = (data, idx) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleLogiRegistClick(data)}>
        {data.logi_id ? "更新" : "登録"}
      </button>
    )
  }

  // 削除ボタン
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.logi_id === null}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleLogiDeleteClick(data)}>
        削除
      </button>
    )
  }

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = { ...logiNew, [key]: event.target.value };
      setLogiNew(new_data);
    } else {

      const _searchData = [...searchData];
      _searchData[index] = { ..._searchData[index], [key]: event.target.value };
      setSearchData(_searchData);
    }
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  // 登録ボタン：処理
  const handleLogiRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (!data.logi_name) {
      setAlertModalMessage('施設名を入力してください。')
      setIsAlertModalOpen(true);
      return;
    }
    if (data.logi_name.length > 20) {
      setAlertModalMessage('施設名は20字以内で入力してください。')
      setIsAlertModalOpen(true);
      return;
    }

    setConfirmModalMessage(`施設マスタ[${data.logi_name}]を登録します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      //(data.password && data.password.match(/\s+/g))
      const params = {
        site_id: props.match.params.siteId,
        logi_id: data.logi_id ? data.logi_id : '',
        logi_name: data.logi_name,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('登録しました。', { appearance: 'success', autoDismiss: true });
          await refreshLogi();
        }
        setLockData(false);
      }
    });
  }

  // 削除ボタン：処理
  const handleLogiDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    setConfirmModalMessage(`施設[${data.logi_id}:${data.logi_name}]を削除します。よろしいですか？`);
    setIsConfirmModalOpen(true);
    setOnConfirm(() => async () => {
      closeConfirmModal();
      const params = {
        site_id: props.match.params.siteId,
        logi_id: data.logi_id,
        upd_date: data.upd_date
      };
      let res;
      try {
        setLockData(true);
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/delete/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      } finally {
        if (res.data.error) {
          addToast(res.data.message, { appearance: 'error', autoDismiss: true });
        } else {
          addToast('削除しました。', { appearance: 'success', autoDismiss: true });
          await refreshLogi();
        }
        setLockData(false);
      }
    });
  }


  useEffect(() => {
    async function fetchData() {
      await refreshLogi();
    }
    return fetchData();
  }, [refreshLogi]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {searchData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {searchData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center w-auto">
                    施設ID
                  </td>
                  <td className="text-center">
                    施設名
                  </td>
                  <td className="text-center">
                    更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr key="new">
                  {/* 施設ID */}
                  <td className="text-center align-middle w-auto">
                    新規
                  </td>
                  {/* 施設名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"logi_name_new"} name="logi_name[]" value={logiNew.logi_name} onChange={handleChangeCell('new', 'logi_name')} size="15"></input>
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(logiNew, 'new')}
                    <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal}/>
                    <UseAlertModal isOpen={isAlertModalOpen} message={alertModalMessage} onClose={closeAlertModal}/>
                  </td>
                </tr>
                {searchData.map((data, idx) => (
                  <tr key={idx}>
                    {/* 施設ID */}
                    <td className="text-center align-middle w-auto">
                      {data.logi_id}
                    </td>
                    {/* 施設名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"logi_name" + idx} name="logi_name[]" value={data.logi_name || ''} onChange={handleChangeCell(idx, 'logi_name')} size="15"></input>
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:SS') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data, idx)}
                      {data.logi_id && renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardLogi