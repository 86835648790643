import { combineReducers, configureStore } from '@reduxjs/toolkit';

import User from '../Slices/User';
import BookingCondition from '../Slices/BookingCondition';

const rootReducer = combineReducers({
  User: User.reducer,
  BookingCondition: BookingCondition.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;